import { Box, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, MenuItem, Select, Typography, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { string } from 'yup';
import { toast } from 'react-hot-toast';
import { object } from '../utils/yup';
import useTranslate from '../hooks/useTranslate';
import MEETING_TYPE from '../constants/MeetingType';
import MEETING_MODE from '../constants/MeetingMode';
import useCoachesQuery from '../hooks/queries/useCoachesQuery';
import MEETING_SUBJECT from '../constants/MeetingSubject';
import ownerContext from '../contexts/ownerContext';
import useRequestMeetingMutation from '../hooks/mutations/useRequestMeetingMutation';
import FormatErrorMessage from '../ui/atoms/form/FormatErrorMessage';

const validationSchema = object().shape({
  type: string().required(),
  mode: string().required(),
  subject: string().required(),
  coachId: string().required(),
  message: string().required(),
});

function RequestMeetingDialog({ improvementAxisId, onClose }) {
  const { t } = useTranslate();
  const owner = useContext(ownerContext);

  const { data: coaches } = useCoachesQuery({
    select: (items) => items.filter((item) => !item.disabled),
  });
  const { mutateAsync, isLoading } = useRequestMeetingMutation(owner.userId);

  // Store a local open state to play exit animation
  const [open, setOpen] = useState(true);

  const form = useFormik({
    initialValues: {
      type: '',
      mode: '',
      subject: '',
      coachId: '',
      message: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      await mutateAsync({ improvementAxisId, ...values });
      setOpen(false);
      toast.success(t('features.RequestMeetingDialog.success'));
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} TransitionProps={{ onExited: onClose }} maxWidth="sm" fullWidth>
      <form onSubmit={form.handleSubmit}>
        <DialogTitle>
          <Typography sx={{ fontWeight: 600, fontSize: 24 }}>
            <FormattedMessage id="features.RequestMeetingDialog.title" />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
            <FormattedMessage id="features.RequestMeetingDialog.type" />
            <Box component="span" sx={{ color: 'danger.main', ml: 1, display: 'inline-block' }}>
              *
            </Box>
          </Typography>
          {MEETING_TYPE.map((type) => (
            <Chip
              key={type}
              onClick={() => form.setFieldValue('type', type)}
              label={type}
              variant={type === form.values.type ? 'contained' : 'outlined'}
              color="primary"
              sx={{
                mr: 1,
                mt: 1,
              }}
            />
          ))}
          {form.errors.type && form.touched.type && (
            <FormHelperText error>
              <FormatErrorMessage error={form.errors.type} />
            </FormHelperText>
          )}

          <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
            <FormattedMessage id="features.RequestMeetingDialog.type" />
          </Typography>
          <FormControl error={form.errors.mode && form.touched.mode} fullWidth>
            <Select name="mode" value={form.values.mode} onChange={form.handleChange} displayEmpty>
              <MenuItem disabled value="">
                <FormattedMessage id="common.verb.select" />
              </MenuItem>
              {Object.values(MEETING_MODE).map((mode) => (
                <MenuItem key={mode} value={mode}>
                  <FormattedMessage id="constants.MeetingMode" values={{ value: mode }} />
                </MenuItem>
              ))}
            </Select>
            {form.errors.mode && form.touched.mode && (
              <FormHelperText>
                <FormatErrorMessage error={form.errors.mode} />
              </FormHelperText>
            )}
          </FormControl>

          <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
            <FormattedMessage id="features.RequestMeetingDialog.subject" />
          </Typography>
          <FormControl error={form.errors.subject && form.touched.subject} fullWidth>
            <Select name="subject" value={form.values.subject} onChange={form.handleChange} displayEmpty>
              <MenuItem disabled value="">
                <FormattedMessage id="common.verb.select" />
              </MenuItem>
              {MEETING_SUBJECT.map((subject) => (
                <MenuItem key={subject} value={subject}>
                  {subject}
                </MenuItem>
              ))}
            </Select>
            {form.errors.subject && form.touched.subject && (
              <FormHelperText>
                <FormatErrorMessage error={form.errors.subject} />
              </FormHelperText>
            )}
          </FormControl>

          <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
            <FormattedMessage id="features.RequestMeetingDialog.coach" />
          </Typography>
          <FormControl error={form.errors.coachId && form.touched.coachId} fullWidth>
            <Select name="coachId" value={form.values.coachId} onChange={form.handleChange} displayEmpty>
              <MenuItem disabled value="">
                <FormattedMessage id="common.verb.select" />
              </MenuItem>
              {coaches?.map((coach) => (
                <MenuItem key={coach.id} value={coach.id}>
                  {coach.name}
                </MenuItem>
              ))}
            </Select>
            {form.errors.coachId && form.touched.coachId && (
              <FormHelperText>
                <FormatErrorMessage error={form.errors.coachId} />
              </FormHelperText>
            )}
          </FormControl>

          <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
            <FormattedMessage id="features.RequestMeetingDialog.message" />
          </Typography>

          <FormControl error={form.errors.message && form.touched.message} fullWidth>
            <TextField
              id="outlined-multiline-static"
              name="message"
              label={t('features.RequestMeetingDialog.messageDetails')}
              multiline
              rows={4}
              onChange={form.handleChange}
              value={form.values.message}
            />
            {form.errors.message && form.touched.message && (
              <FormHelperText>
                <FormatErrorMessage error={form.errors.message} />
              </FormHelperText>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            <FormattedMessage id="features.RequestMeetingDialog.submit" />
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

RequestMeetingDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  improvementAxisId: PropTypes.string,
};

RequestMeetingDialog.defaultProps = {
  improvementAxisId: undefined,
};

export default RequestMeetingDialog;
