import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';
import { listCoaches } from '../../graphql/queries';

function useCoachesQuery(options = {}) {
  return useQuery(
    QUERY_CACHE_KEY.coaches(),
    async () => {
      const res = await API.graphql({ query: listCoaches });
      return res.data.listCoaches.items.sort((a, b) => a.name.localeCompare(b.name));
    },
    options
  );
}

export default useCoachesQuery;
