import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';
import { listUsers } from '../../graphql/queries';

function useUsersQuery(options = {}) {
  return useQuery(
    QUERY_CACHE_KEY.users(),
    async () => {
      const res = await API.graphql({ query: listUsers });
      return res.data.listUsers.items.sort((a, b) => a.name.localeCompare(b.name));
    },
    options
  );
}

export default useUsersQuery;
