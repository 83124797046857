import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';
import { getUser } from '../../graphql/queries';

function useUserQuery(id, options = {}) {
  return useQuery(
    QUERY_CACHE_KEY.user(id),
    async () => {
      const res = await API.graphql({ query: getUser, variables: { id } });
      return res.data.getUser;
    },
    { enabled: !!id, ...options }
  );
}

export default useUserQuery;
