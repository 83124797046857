import PropTypes from 'prop-types';

function WomenAvatar({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 163 163" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M81.36 162.72C126.294 162.72 162.72 126.294 162.72 81.36C162.72 36.4261 126.294 0 81.36 0C36.4261 0 0 36.4261 0 81.36C0 126.294 36.4261 162.72 81.36 162.72Z"
        fill="#FEF2EB"
      />
      <mask id="mask0_33:756" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="163" height="163">
        <path
          d="M81.36 162.72C126.294 162.72 162.72 126.294 162.72 81.36C162.72 36.4261 126.294 0 81.36 0C36.4261 0 0 36.4261 0 81.36C0 126.294 36.4261 162.72 81.36 162.72Z"
          fill="#FEF2EB"
        />
      </mask>
      <g mask="url(#mask0_33:756)">
        <path
          d="M111.794 40.8988C114.839 47.948 117.161 54.7616 118.288 61.7603C119.416 68.7591 119.802 75.9092 121.552 82.7733C123.083 88.8467 125.657 106.781 128.416 112.4C128.669 112.922 128.921 113.443 128.921 114.015C128.921 115.126 127.962 115.984 127.054 116.64C113.359 126.65 91.7235 129.476 76.0605 122.965"
          fill="#70282B"
        />
        <path
          d="M115.445 88.1402C122.897 88.1402 128.938 80.6305 128.938 71.3668C128.938 62.1032 122.897 54.5935 115.445 54.5935C107.993 54.5935 101.952 62.1032 101.952 71.3668C101.952 80.6305 107.993 88.1402 115.445 88.1402Z"
          fill="#00B7D8"
        />
        <path
          d="M109.086 90.5121C116.538 90.5121 122.578 83.0025 122.578 73.7388C122.578 64.4751 116.538 56.9655 109.086 56.9655C101.634 56.9655 95.593 64.4751 95.593 73.7388C95.593 83.0025 101.634 90.5121 109.086 90.5121Z"
          fill="#04A2BC"
        />
        <path
          d="M78.7356 122.478L106.882 51.2623C109.994 61.6426 113.107 72.0229 116.051 82.4369C117.027 85.9026 118.002 89.4019 118.322 93.0022C118.608 96.1483 118.389 111.492 117.683 114.57C116.993 117.565 115.798 120.526 113.712 122.764C113.342 123.167 112.905 123.504 112.467 123.857C101.481 127.171 89.3514 126.885 78.7356 122.478Z"
          fill="#4E1919"
        />
        <path
          d="M84.8256 108.093C83.547 115.26 82.5039 123.403 86.9454 129.157C87.9212 130.418 89.183 131.647 89.2671 133.245C89.3512 135.331 87.366 136.879 85.4817 137.804C78.2475 141.421 69.4991 141.875 61.9284 139.015C61.3059 138.78 60.6498 138.494 60.2292 137.972C59.2702 136.795 59.8927 135.062 60.4647 133.665C65.8315 120.846 65.6801 105.805 60.0441 93.0864"
          fill="#E8A17E"
        />
        <path
          d="M85.2969 106.192C84.0519 111.222 83.2444 116.068 83.7827 121.232C83.7996 121.401 83.8164 121.603 83.6986 121.737C83.5304 121.905 83.2444 121.838 83.0257 121.754C81.3769 121.165 79.6946 120.543 78.2477 119.567C75.8083 117.901 72.9314 114.839 71.6696 112.148C70.3069 109.254 69.1797 103.534 69.3984 100.741"
          fill="#D88D6C"
        />
        <path
          d="M101.868 41.8745C106.983 47.5778 108.177 55.7037 108.85 63.3417C109.439 70.0712 109.809 76.8681 108.85 83.5471C107.891 90.2262 105.553 96.8379 101.229 102.02C99.412 104.207 97.2585 106.108 94.8696 107.656C93.2208 108.716 91.4375 109.624 89.5196 110.028C85.8688 110.802 82.1171 109.759 78.5673 108.615C71.5686 106.343 61.5752 101.633 57.1842 95.5762C50.6902 86.6259 46.4001 63.0557 44.7009 52.1202"
          fill="#E8A17E"
        />
        <path
          d="M112.047 48.8228C104.493 55.3504 95.1219 59.7414 85.2631 61.3733C79.5599 62.3155 73.7388 62.3323 67.9514 62.2145C65.7811 62.164 63.594 62.0968 61.4911 61.592C58.5637 60.8854 55.7878 59.2199 54.2064 56.6795C52.6081 54.1391 52.3894 50.6397 54.0886 48.1666"
          fill="#D88D6C"
        />
        <path
          d="M46.8543 18.9605C61.2555 11.9955 82.1002 13.4423 92.5814 19.4316C103.063 25.4209 107.403 30.7877 112.871 43.2878C113.191 44.028 115.092 48.6378 114.806 49.3948C114.52 50.1519 114.15 50.5725 113.409 50.8585C100.472 55.8047 89.0821 56.6964 75.2529 57.3693C70.6432 57.6049 65.9662 57.6049 61.491 56.5113C55.1484 77.7598 58.631 105.856 69.1963 125.354C62.3154 128.248 33.6139 130.031 20.4241 119.382C17.1939 116.774 13.4085 102.339 12.5337 98.268C10.1279 87.1475 11.5243 77.3897 14.2666 67.2113C16.3191 59.6405 18.6407 52.0866 22.3588 45.1889C28.0453 34.6235 35.801 23.7385 46.8543 18.9605Z"
          fill="#70282B"
        />
        <path
          d="M97.2921 88.6954C96.2491 91.9424 95.0377 94.9371 92.1609 96.7709C89.3176 98.5878 85.4986 98.386 82.4367 96.9391C79.3748 95.4923 76.4642 91.9088 74.3108 89.2843C81.7637 90.0077 89.9401 89.9909 97.2921 88.6954Z"
          fill="white"
        />
        <path d="M30.6531 124.277C30.8886 124.362 31.141 124.463 31.3765 124.547C31.1241 124.463 30.8886 124.378 30.6531 124.277Z" fill="#3E3244" />
        <path d="M29.7781 123.958C30.0641 124.076 30.3501 124.177 30.6529 124.278C30.3501 124.177 30.0641 124.059 29.7781 123.958Z" fill="#3E3244" />
        <path d="M29.593 123.891C29.6603 123.907 29.7108 123.941 29.7781 123.958C29.7108 123.924 29.6603 123.907 29.593 123.891Z" fill="#3E3244" />
        <path d="M21.9551 119.398C21.7869 119.247 21.6018 119.112 21.4167 118.961C21.5682 119.045 21.7532 119.213 21.9551 119.398Z" fill="#3E3244" />
        <path
          d="M39.149 96.8546C38.0891 93.3385 37.2816 89.6877 37.3994 86.0201C37.534 82.3525 38.678 78.6176 41.1343 75.8922C44.7009 71.9386 50.2864 70.6936 55.5018 69.6505C56.5953 69.4318 57.6889 69.2131 58.7824 68.9944C56.8309 84.2368 68.6748 110.633 77.0531 126.094C73.3519 127.659 55.9224 128.147 45.9795 127.373C46.2319 127.306 46.4338 127.222 46.5179 127.053C47.3422 125.051 39.7715 98.9408 39.149 96.8546Z"
          fill="#4E1919"
        />
        <path d="M88.6448 71.7537L92.5479 83.6818L86.4072 84.1865" fill="#D88D6C" />
        <path
          d="M76.4811 62.2652C76.2288 62.1137 75.9764 61.9792 75.7072 61.8614C75.5558 61.7941 75.3876 61.71 75.2362 61.6427C75.2866 61.6595 75.3539 61.6932 75.4044 61.71C75.0006 61.5417 74.5968 61.3903 74.1763 61.2557C73.6715 61.0875 73.15 60.9529 72.6117 60.852C72.2752 60.7847 71.9387 60.7174 71.5854 60.6837C71.6527 60.7005 71.72 60.7006 71.7873 60.7174C70.8115 60.5828 69.8021 60.5491 68.8095 60.6164C68.5066 60.6333 68.2038 60.6669 67.901 60.7174C67.9683 60.7006 68.0356 60.7005 68.1029 60.6837C67.0934 60.8183 66.1176 61.0539 65.1587 61.3903C64.8727 61.4913 64.5699 61.609 64.2839 61.7268C64.3343 61.71 64.4016 61.6763 64.4521 61.6595C63.3754 62.1138 62.3659 62.6858 61.407 63.3419C61.2556 63.4428 61.0032 63.4597 60.835 63.4092C60.6667 63.3587 60.4817 63.2241 60.3976 63.0727C60.3134 62.9045 60.263 62.7026 60.3303 62.5007C60.3976 62.3156 60.4985 62.181 60.6667 62.0633C62.1641 61.037 63.8296 60.2127 65.5961 59.7079C67.0261 59.2874 68.5235 59.0855 70.0208 59.1023C71.2489 59.1191 72.4771 59.2873 73.6547 59.5734C74.5296 59.7921 75.3876 60.0949 76.2119 60.465C76.5484 60.6164 76.8849 60.7678 77.2045 60.9697H77.2214C77.3896 61.0707 77.5074 61.2221 77.5578 61.4072C77.6083 61.5754 77.5915 61.8109 77.4905 61.9792C77.3896 62.1306 77.2382 62.282 77.0531 62.3156C76.8681 62.3829 76.6494 62.3661 76.4811 62.2652Z"
          fill="#3E3244"
        />
        <path
          d="M96.3333 62.3492C96.552 62.1473 96.7875 61.9791 97.023 61.8108C97.1576 61.7099 97.3091 61.6089 97.4605 61.508C97.41 61.5417 97.3595 61.5753 97.3091 61.6089C97.6792 61.3734 98.0493 61.1547 98.4363 60.936C98.9073 60.6836 99.3952 60.4481 99.8999 60.2462C100.22 60.1116 100.539 59.9939 100.876 59.8929C100.808 59.9097 100.758 59.9266 100.691 59.9602C101.633 59.6574 102.609 59.4387 103.601 59.3209C103.904 59.2873 104.207 59.2536 104.526 59.2536C104.459 59.2536 104.392 59.2536 104.325 59.2704C105.334 59.22 106.343 59.2704 107.353 59.4387C107.656 59.4891 107.959 59.5396 108.278 59.6069C108.211 59.5901 108.16 59.5733 108.093 59.5733C109.237 59.8256 107.723 60.5995 109.792 60.0443C109.994 59.9939 108.43 58.0423 106.613 57.8572C105.132 57.7058 103.618 57.7731 102.154 58.0759C100.96 58.3115 99.7822 58.6984 98.655 59.2031C97.8306 59.5733 97.0567 60.0275 96.2996 60.549C95.9968 60.7509 95.694 60.9865 95.408 61.222C95.408 61.222 95.408 61.222 95.3911 61.2388C95.2565 61.3734 95.1556 61.5416 95.1388 61.7267C95.122 61.9118 95.1893 62.1305 95.307 62.2651C95.4416 62.3997 95.6099 62.5174 95.7949 62.5174C95.98 62.5343 96.1819 62.4838 96.3333 62.3492Z"
          fill="#3E3244"
        />
        <path
          opacity="0.45"
          d="M66.7231 86.6258C69.6314 86.6258 71.989 84.2682 71.989 81.36C71.989 78.4517 69.6314 76.0941 66.7231 76.0941C63.8149 76.0941 61.4573 78.4517 61.4573 81.36C61.4573 84.2682 63.8149 86.6258 66.7231 86.6258Z"
          fill="#F27B53"
        />
        <path
          opacity="0.45"
          d="M104.644 83.7491C107.097 83.7491 109.086 81.7605 109.086 79.3076C109.086 76.8546 107.097 74.8661 104.644 74.8661C102.191 74.8661 100.203 76.8546 100.203 79.3076C100.203 81.7605 102.191 83.7491 104.644 83.7491Z"
          fill="#F27B53"
        />
        <path
          d="M89.1877 103.813C89.3556 102.075 87.6848 100.491 85.4558 100.275C83.2268 100.06 81.2838 101.295 81.116 103.034C80.9481 104.773 82.6189 106.357 84.8479 106.572C87.0769 106.787 89.0199 105.552 89.1877 103.813Z"
          fill="#161119"
        />
        <path
          d="M77.8101 104.055C79.5934 104.224 81.4272 104.291 83.2947 104.207L83.2274 102.457C62.1135 103.298 45.8617 86.2389 45.7102 86.0707L44.4316 87.2652C44.5831 87.4334 58.6309 102.205 77.8101 104.055Z"
          fill="#161119"
        />
        <path
          d="M114.368 55.7374C112.686 43.8934 106.478 30.8718 96.3164 24.1759C87.2148 18.1866 75.0343 17.7997 65.0073 21.6523C56.3599 24.9666 50.6398 32.3523 47.8302 40.9661C45.4244 48.3013 45.1047 55.8383 44.5495 63.4595C44.3645 65.9663 40.4277 65.9999 40.6128 63.4595C41.1511 56.2085 41.4371 48.9742 43.4224 41.925C46.0132 32.7392 51.2959 24.3105 59.876 19.7008C70.0881 14.2162 83.6145 13.6274 94.1462 18.4726C102.726 22.4094 108.985 30.0643 112.972 38.4425C115.378 43.5233 117.38 49.092 118.171 54.6943C118.524 57.1674 114.722 58.2442 114.368 55.7374Z"
          fill="#00B7D8"
        />
        <path
          d="M44.7007 92.8675C52.1525 92.8675 58.1934 85.3578 58.1934 76.0942C58.1934 66.8305 52.1525 59.3208 44.7007 59.3208C37.2489 59.3208 31.208 66.8305 31.208 76.0942C31.208 85.3578 37.2489 92.8675 44.7007 92.8675Z"
          fill="#00B7D8"
        />
        <path
          d="M42.581 92.0263C49.6611 92.0263 55.4007 84.8932 55.4007 76.0941C55.4007 67.2951 49.6611 60.162 42.581 60.162C35.5008 60.162 29.7612 67.2951 29.7612 76.0941C29.7612 84.8932 35.5008 92.0263 42.581 92.0263Z"
          fill="#04A2BC"
        />
        <path
          d="M61.6927 130.132C44.0277 130.889 24.899 136.223 11.9783 148.268C10.8848 149.295 9.80807 150.405 9.337 151.818C8.64722 153.888 9.35383 156.142 10.01 158.228C17.648 182.303 18.0686 208.615 11.1708 232.909C9.37065 239.268 7.06579 245.561 6.4433 252.139C5.82082 258.717 7.13308 265.85 11.6755 270.645C13.6271 272.714 16.0834 274.245 18.6238 275.524C32.7726 282.674 49.3777 282.405 65.2089 281.749C74.9667 281.345 84.8255 280.84 94.23 278.165C103.635 275.49 112.652 270.443 118.372 262.519C124.261 254.343 126.111 243.945 126.851 233.902C128.551 211.072 125.455 188.174 125.018 165.277C124.9 158.615 125.034 151.751 127.625 145.627C115.563 137.282 101.145 132.218 86.7602 129.358C83.6646 133.48 79.3409 135.348 74.1928 135.6C69.0784 135.852 65.1921 133.918 61.6927 130.132Z"
          fill="#F9CABE"
        />
        <path
          d="M120.29 141.085C114.839 165.345 119.533 193.979 125.59 218.087C127.39 225.254 127.003 233.111 124.446 236.745C121.788 240.513 117.195 242.768 113.005 244.702C103.264 249.194 96.5013 254.494 89.0652 262.216C86.0874 265.312 83.3451 268.811 82.0665 272.899C80.7879 276.987 81.158 281.816 83.9003 285.13C86.5248 288.293 90.8485 289.605 94.9535 289.605C99.0585 289.605 103.079 288.461 107.067 287.519C113.763 285.955 120.593 284.928 127.457 284.457C133.396 284.053 139.436 284.053 145.156 282.388C153.282 279.999 160.264 274.211 164.554 266.91C168.844 259.608 170.476 250.86 169.618 242.431C169.163 237.906 168.019 233.498 166.842 229.107C161.963 210.937 155.233 195.039 149.076 177.256C146.552 169.988 141.926 161.778 137.467 155.486C133.009 149.227 127.878 142.38 120.29 141.085Z"
          fill="#F9CABE"
        />
        <path
          d="M118.305 187.283C115.008 172.293 116.185 155.704 119.701 140.916C119.886 140.142 121.064 140.479 120.879 141.236C118.372 151.784 117.397 162.636 117.935 173.47C118.154 177.962 118.524 182.555 119.483 186.946C119.651 187.72 118.473 188.057 118.305 187.283Z"
          fill="white"
        />
        <path
          d="M25.0674 139.251C17.0929 140.176 8.37812 149.463 2.87673 157.488C-2.62466 165.513 -6.39319 174.598 -10.0944 183.598C-17.0427 200.456 -23.9909 217.313 -30.9223 234.171C-33.0758 239.403 -35.246 244.669 -36.2891 250.221C-37.164 254.83 -37.2313 259.592 -36.5247 264.218C-35.9358 268.02 -34.775 271.822 -32.4028 274.851C-29.0381 279.124 -23.7386 281.294 -18.6578 283.229C-7.94099 287.3 3.06179 291.119 14.4852 291.91C18.994 292.213 23.5196 292.062 28.0283 291.893C40.4948 291.439 53.0285 290.985 65.2931 288.697C66.168 288.529 67.0933 288.343 67.7999 287.788C68.5401 287.216 68.9439 286.341 69.3308 285.5C70.559 282.808 71.8039 280.1 73.0321 277.408C73.7218 275.894 74.4285 274.312 74.2939 272.664C74.1088 270.443 72.4937 268.643 70.8618 267.145C66.5381 263.141 61.5582 259.844 56.1746 257.438C45.1213 252.475 32.4867 251.23 22.0895 244.988C20.8278 244.231 19.5996 243.39 18.5902 242.33C14.7543 238.259 14.9899 231.714 17.1433 226.566C19.2968 221.418 20.7941 216.489 22.8971 211.324C27.5909 199.816 29.9294 192.313 30.1818 182.488C30.5856 168.474 29.5425 152.525 25.0674 139.251Z"
          fill="#FCD8C5"
        />
        <path
          d="M30.1652 187.939C31.1914 171.906 29.9464 154.577 24.4787 139.419C24.2095 138.679 25.404 138.359 25.6564 139.099C31.1578 154.359 32.4027 171.822 31.3765 187.956C31.3428 188.73 30.1147 188.73 30.1652 187.939Z"
          fill="white"
        />
        <path
          d="M102.44 69.7349C104.01 69.7349 105.283 68.462 105.283 66.8917C105.283 65.3214 104.01 64.0485 102.44 64.0485C100.87 64.0485 99.5967 65.3214 99.5967 66.8917C99.5967 68.462 100.87 69.7349 102.44 69.7349Z"
          fill="#3E3244"
        />
        <path
          d="M71.4843 71.8378C73.0546 71.8378 74.3276 70.5649 74.3276 68.9946C74.3276 67.4243 73.0546 66.1514 71.4843 66.1514C69.9141 66.1514 68.6411 67.4243 68.6411 68.9946C68.6411 70.5649 69.9141 71.8378 71.4843 71.8378Z"
          fill="#3E3244"
        />
      </g>
    </svg>
  );
}

WomenAvatar.propTypes = {
  size: PropTypes.number,
};

WomenAvatar.defaultProps = {
  size: 100,
};

export default WomenAvatar;
