/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSkill = /* GraphQL */ `
  mutation CreateSkill(
    $input: CreateSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    createSkill(input: $input, condition: $condition) {
      id
      name
      type
      icon
      userSkills {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSkill = /* GraphQL */ `
  mutation UpdateSkill(
    $input: UpdateSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    updateSkill(input: $input, condition: $condition) {
      id
      name
      type
      icon
      userSkills {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSkill = /* GraphQL */ `
  mutation DeleteSkill(
    $input: DeleteSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    deleteSkill(input: $input, condition: $condition) {
      id
      name
      type
      icon
      userSkills {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserSkill = /* GraphQL */ `
  mutation CreateUserSkill(
    $input: CreateUserSkillInput!
    $condition: ModelUserSkillConditionInput
  ) {
    createUserSkill(input: $input, condition: $condition) {
      id
      skillId
      skill {
        id
        name
        type
        icon
        createdAt
        updatedAt
      }
      owner
      level
      createdAt
      updatedAt
    }
  }
`;
export const updateUserSkill = /* GraphQL */ `
  mutation UpdateUserSkill(
    $input: UpdateUserSkillInput!
    $condition: ModelUserSkillConditionInput
  ) {
    updateUserSkill(input: $input, condition: $condition) {
      id
      skillId
      skill {
        id
        name
        type
        icon
        createdAt
        updatedAt
      }
      owner
      level
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserSkill = /* GraphQL */ `
  mutation DeleteUserSkill(
    $input: DeleteUserSkillInput!
    $condition: ModelUserSkillConditionInput
  ) {
    deleteUserSkill(input: $input, condition: $condition) {
      id
      skillId
      skill {
        id
        name
        type
        icon
        createdAt
        updatedAt
      }
      owner
      level
      createdAt
      updatedAt
    }
  }
`;
export const createCoach = /* GraphQL */ `
  mutation CreateCoach(
    $input: CreateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    createCoach(input: $input, condition: $condition) {
      id
      name
      meetings {
        nextToken
      }
      disabled
      createdAt
      updatedAt
    }
  }
`;
export const updateCoach = /* GraphQL */ `
  mutation UpdateCoach(
    $input: UpdateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    updateCoach(input: $input, condition: $condition) {
      id
      name
      meetings {
        nextToken
      }
      disabled
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoach = /* GraphQL */ `
  mutation DeleteCoach(
    $input: DeleteCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    deleteCoach(input: $input, condition: $condition) {
      id
      name
      meetings {
        nextToken
      }
      disabled
      createdAt
      updatedAt
    }
  }
`;
export const createMeeting = /* GraphQL */ `
  mutation CreateMeeting(
    $input: CreateMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    createMeeting(input: $input, condition: $condition) {
      id
      type
      mode
      status
      date
      subject
      message
      coachId
      coach {
        id
        name
        disabled
        createdAt
        updatedAt
      }
      improvementAxisId
      improvementAxis {
        id
        title
        userWhoSuggestId
        owner
        archivedAt
        createdAt
        updatedAt
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateMeeting = /* GraphQL */ `
  mutation UpdateMeeting(
    $input: UpdateMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    updateMeeting(input: $input, condition: $condition) {
      id
      type
      mode
      status
      date
      subject
      message
      coachId
      coach {
        id
        name
        disabled
        createdAt
        updatedAt
      }
      improvementAxisId
      improvementAxis {
        id
        title
        userWhoSuggestId
        owner
        archivedAt
        createdAt
        updatedAt
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteMeeting = /* GraphQL */ `
  mutation DeleteMeeting(
    $input: DeleteMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    deleteMeeting(input: $input, condition: $condition) {
      id
      type
      mode
      status
      date
      subject
      message
      coachId
      coach {
        id
        name
        disabled
        createdAt
        updatedAt
      }
      improvementAxisId
      improvementAxis {
        id
        title
        userWhoSuggestId
        owner
        archivedAt
        createdAt
        updatedAt
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createActionPlan = /* GraphQL */ `
  mutation CreateActionPlan(
    $input: CreateActionPlanInput!
    $condition: ModelActionPlanConditionInput
  ) {
    createActionPlan(input: $input, condition: $condition) {
      id
      title
      active
      improvementAxisId
      improvementAxis {
        id
        title
        userWhoSuggestId
        owner
        archivedAt
        createdAt
        updatedAt
      }
      owner
      finishedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateActionPlan = /* GraphQL */ `
  mutation UpdateActionPlan(
    $input: UpdateActionPlanInput!
    $condition: ModelActionPlanConditionInput
  ) {
    updateActionPlan(input: $input, condition: $condition) {
      id
      title
      active
      improvementAxisId
      improvementAxis {
        id
        title
        userWhoSuggestId
        owner
        archivedAt
        createdAt
        updatedAt
      }
      owner
      finishedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteActionPlan = /* GraphQL */ `
  mutation DeleteActionPlan(
    $input: DeleteActionPlanInput!
    $condition: ModelActionPlanConditionInput
  ) {
    deleteActionPlan(input: $input, condition: $condition) {
      id
      title
      active
      improvementAxisId
      improvementAxis {
        id
        title
        userWhoSuggestId
        owner
        archivedAt
        createdAt
        updatedAt
      }
      owner
      finishedAt
      createdAt
      updatedAt
    }
  }
`;
export const createImprovementAxis = /* GraphQL */ `
  mutation CreateImprovementAxis(
    $input: CreateImprovementAxisInput!
    $condition: ModelImprovementAxisConditionInput
  ) {
    createImprovementAxis(input: $input, condition: $condition) {
      id
      title
      meetings {
        nextToken
      }
      userWhoSuggestId
      userWhoSuggest {
        username
        name
        job
        gender
        email
        enabled
      }
      owner
      archivedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateImprovementAxis = /* GraphQL */ `
  mutation UpdateImprovementAxis(
    $input: UpdateImprovementAxisInput!
    $condition: ModelImprovementAxisConditionInput
  ) {
    updateImprovementAxis(input: $input, condition: $condition) {
      id
      title
      meetings {
        nextToken
      }
      userWhoSuggestId
      userWhoSuggest {
        username
        name
        job
        gender
        email
        enabled
      }
      owner
      archivedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteImprovementAxis = /* GraphQL */ `
  mutation DeleteImprovementAxis(
    $input: DeleteImprovementAxisInput!
    $condition: ModelImprovementAxisConditionInput
  ) {
    deleteImprovementAxis(input: $input, condition: $condition) {
      id
      title
      meetings {
        nextToken
      }
      userWhoSuggestId
      userWhoSuggest {
        username
        name
        job
        gender
        email
        enabled
      }
      owner
      archivedAt
      createdAt
      updatedAt
    }
  }
`;
