import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';

function useActionPlansByOwnerQuery(owner, options = {}) {
  return useQuery(
    QUERY_CACHE_KEY.userActionPlans(owner),
    async () => {
      const res = await API.graphql({
        // TODO Find better solution
        query: gql`
          query ListActionPlansByOwner($owner: String!, $sortDirection: ModelSortDirection, $filter: ModelActionPlanFilterInput, $limit: Int, $nextToken: String) {
            listActionPlansByOwner(owner: $owner, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                id
                title
                active
                improvementAxisId
                owner
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        `,
        variables: { owner },
      });
      return res.data.listActionPlansByOwner.items.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    },
    { enabled: !!owner, ...options }
  );
}

export default useActionPlansByOwnerQuery;
