import { useMutation, useQueryClient } from 'react-query';
import { API } from 'aws-amplify';
import { updateImprovementAxis } from '../../graphql/mutations';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';

function useUpdateImprovementAxisMutation(owner, options = {}) {
  const client = useQueryClient();

  return useMutation(
    (input) => {
      return API.graphql({
        query: updateImprovementAxis,
        variables: {
          input,
        },
      });
    },
    {
      ...options,
      onSuccess: (res) => {
        client.invalidateQueries(QUERY_CACHE_KEY.userImprovementAxes(owner));
        if ('function' === typeof options.onSuccess) {
          options.onSuccess(res);
        }
      },
    }
  );
}

export default useUpdateImprovementAxisMutation;
