import { Link } from 'react-router-dom';
import { Box, Paper, IconButton, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormattedMessage } from 'react-intl';
import CenteredCircularProgress from '../ui/atoms/CenteredCircularProgress';
import AdminLayout from '../ui/templates/AdminLayout';
import ADMIN_PAGE from '../constants/AdminPage';
import useUsersQuery from '../hooks/queries/useUsersQuery';
import useTranslate from '../hooks/useTranslate';
import EyeIcon from '../ui/atoms/icons/EyeIcon';
import useCurrentUser from '../hooks/useCurrentUser';

function Users() {
  const { t } = useTranslate();
  const user = useCurrentUser();

  const { data, isLoading } = useUsersQuery();

  return (
    <AdminLayout selected={ADMIN_PAGE.users}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
        <Typography variant="h2">
          <FormattedMessage id="pages.Users.title" />
        </Typography>
      </Box>
      {isLoading ? (
        <Box sx={{ height: '500px', width: '100%', position: 'relative' }}>
          <CenteredCircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="pages.Users.columns.name" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="pages.Users.columns.gender" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="pages.Users.columns.email" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="pages.Users.columns.job" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="pages.Users.columns.state" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="common.actions" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <TableRow key={item.username}>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell>{t('constants.Gender', { value: item.gender }) ?? t('common.null')}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{item.job ?? t('common.null')}</TableCell>
                  <TableCell>
                    <Typography variant="body2" sx={{ fontWeight: 500, color: item.enabled ? 'success.main' : 'danger.main' }}>
                      <FormattedMessage id={item.enabled ? 'common.enabled' : 'common.disabled'} />
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {user.username !== item.username && (
                      <IconButton color="primary" component={Link} to={`/admin/user/${item.username}`}>
                        <EyeIcon size={20} />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </AdminLayout>
  );
}

export default Users;
