import { Box, Button, Paper, Typography } from '@mui/material';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ownerContext from '../../contexts/ownerContext';
import useUserQuery from '../../hooks/queries/useUserQuery';
import useCurrentUser from '../../hooks/useCurrentUser';

function ImprovementAxis({ axis, onOpen }) {
  const user = useCurrentUser();
  const owner = useContext(ownerContext);
  const { data: ownerUser } = useUserQuery(axis.owner, { enabled: !owner.isMe });

  let name = ownerUser?.name;
  if ((owner.isMe && null === axis.userWhoSuggest) || axis.userWhoSuggest?.username === user.username) {
    name = 'me';
  } else if (owner.isMe) {
    name = axis.userWhoSuggest?.name || 'me';
  }

  return (
    <Paper sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 4, py: 2, mb: 1 }}>
      <Box>
        <Typography variant="h4">{axis.title}</Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
          <FormattedMessage id="features.ImprovementAxes.ImprovementAxis.date" values={{ createdAt: new Date(axis.createdAt), name }} />
        </Typography>
      </Box>
      {owner.isMe && (
        <Box>
          <Button variant="outlined" size="large" onClick={onOpen}>
            <FormattedMessage id="features.ImprovementAxes.ImprovementAxis.act" />
          </Button>
        </Box>
      )}
    </Paper>
  );
}

ImprovementAxis.propTypes = {
  axis: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    createdAt: PropTypes.string,
    owner: PropTypes.string,
    userWhoSuggest: PropTypes.shape({
      username: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  onOpen: PropTypes.func.isRequired,
};

export default ImprovementAxis;
