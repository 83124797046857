import { useMutation, useQueryClient } from 'react-query';
import { API } from 'aws-amplify';
import { createImprovementAxis } from '../../graphql/mutations';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';

function useCreateImprovementAxisMutation(owner, options = {}) {
  const client = useQueryClient();

  return useMutation(
    (input) => {
      return API.graphql({
        query: createImprovementAxis,
        variables: {
          input: {
            ...input,
            owner,
          },
        },
      });
    },
    {
      ...options,
      onSuccess: (res) => {
        client.setQueriesData(QUERY_CACHE_KEY.userImprovementAxes(owner), (prevState) => [...prevState, res.data.createImprovementAxis]);
        if ('function' === typeof options.onSuccess) {
          options.onSuccess(res);
        }
      },
    }
  );
}

export default useCreateImprovementAxisMutation;
