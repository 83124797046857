import { Box, Paper, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import AppLayout from '../ui/templates/AppLayout';
import UsernameWithAvatar from '../ui/atoms/UsernameWithAvatar';
import ChevronRightIcon from '../ui/atoms/icons/ChevronRightIcon';
import useCurrentUser from '../hooks/useCurrentUser';
import ownerContext from '../contexts/ownerContext';
import ImprovementAxes from '../features/ImprovementAxes/ImprovementAxes';
import NextMeetings from '../features/NextMeetings';
import useActionPlansByOwnerQuery from '../hooks/queries/useActionPlansByOwnerQuery';
import CenteredCircularProgress from '../ui/atoms/CenteredCircularProgress';
import useUserQuery from '../hooks/queries/useUserQuery';
import useTranslate from '../hooks/useTranslate';

function Dashboard() {
  const { formatDate } = useTranslate();
  const user = useCurrentUser();
  const params = useParams();

  const userId = params.userId || user?.username;
  const isMe = userId === user?.username;

  const { data: ownerUser, isLoading: loadingUser } = useUserQuery(userId, { enabled: !isMe });

  const { data: actionsPlans, isLoading } = useActionPlansByOwnerQuery(userId);

  const activePlan = actionsPlans?.find((item) => item.active);
  const pastPlans = actionsPlans?.filter((item) => !item.active);

  if (loadingUser) {
    return <CenteredCircularProgress />;
  }

  // TODO translate

  return (
    <ownerContext.Provider value={{ userId, isMe }}>
      <AppLayout user={ownerUser}>
        {isLoading && (
          <Box sx={{ mb: 10 }}>
            <Typography variant="h2">Mon plan d’action en cours</Typography>
            <Box
              sx={{
                height: '480px',
                position: 'relative',
              }}
            >
              <CenteredCircularProgress />
            </Box>
          </Box>
        )}
        {activePlan && (
          <Box sx={{ mb: 10 }}>
            <Typography variant="h2">Mon plan d’action en cours</Typography>
            <Box
              sx={{
                height: '480px',
                backgroundImage: 'linear-gradient(135deg, #F6D242 0%, #FF52E5 100%)',
                borderRadius: 3,
                display: 'flex',
                alignItems: 'center',
                color: 'common.white',
                position: 'relative',
                p: 4,
                mt: 2,
              }}
            >
              <Typography variant="h1">{activePlan.title}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: 32, left: 32 }}>
                <Typography variant="body2">Mis en place le {formatDate(activePlan.createdAt, { dateStyle: 'long' })}</Typography>
              </Box>
            </Box>
          </Box>
        )}
        <NextMeetings sx={{ mb: 10 }} />
        <ImprovementAxes sx={{ mb: 10 }} />
        {isLoading && (
          <Box sx={{ mb: 10 }}>
            <Typography variant="h2" sx={{ mb: 2 }}>
              Mes plans d’action terminés
            </Typography>
            <Box
              sx={{
                height: '242px',
                position: 'relative',
              }}
            >
              <CenteredCircularProgress />
            </Box>
          </Box>
        )}
        {0 < pastPlans?.length && (
          <Box sx={{ mb: 10 }}>
            <Typography variant="h2" sx={{ mb: 2 }}>
              Mes plans d’action terminés
            </Typography>
            {pastPlans?.map((item) => (
              <Paper key={item.id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 4, py: 2 }}>
                <Box>
                  <Typography variant="h4">{item.title}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Terminé le 15 septembre 2020
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary', mt: 2 }}>
                    <Typography variant="body2">Avec</Typography>
                    <UsernameWithAvatar avatar="https://thispersondoesnotexist.com/image" name="John doe" sx={{ ml: 1 }} />
                    <Typography variant="body2" sx={{ ml: 0.5 }}>
                      et
                    </Typography>
                    <UsernameWithAvatar avatar="https://thispersondoesnotexist.com/image" name="Jane doe" sx={{ ml: 1 }} />
                  </Box>
                </Box>
                <ChevronRightIcon size={18} color="grey.700" />
              </Paper>
            ))}
          </Box>
        )}
      </AppLayout>
    </ownerContext.Provider>
  );
}

export default Dashboard;
