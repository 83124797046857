import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Tab, Tabs } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { API } from 'aws-amplify';
import CenteredCircularProgress from '../../ui/atoms/CenteredCircularProgress';
import useUserSkillsByOwnerQuery from '../../hooks/queries/useUserSkillsByOwnerQuery';
import useSkillsQuery from '../../hooks/queries/useSkillsQuery';
import SKILL_TYPE from '../../constants/SkillType';
import { createUserSkill, deleteUserSkill, updateUserSkill } from '../../graphql/mutations';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';
import UserSkillItem from './UserSkillItem';
import useTranslate from '../../hooks/useTranslate';

function useSyncUserSkillsMutation(owner) {
  const queryClient = useQueryClient();
  return useMutation(
    (items) => {
      const promises = items.map((item) => {
        if (item.draft) {
          return API.graphql({
            query: createUserSkill,
            variables: {
              input: {
                skillId: item.skill.id,
                level: item.level,
                owner,
              },
            },
          });
        }

        if (item.delete) {
          return API.graphql({
            query: deleteUserSkill,
            variables: {
              input: {
                id: item.id,
              },
            },
          });
        }

        return API.graphql({
          query: updateUserSkill,
          variables: {
            input: {
              id: item.id,
              level: item.level,
            },
          },
        });
      });

      return Promise.all(promises);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_CACHE_KEY.userSkills(owner));
      },
    }
  );
}

function UserSkillsDialog({ userId, onClose }) {
  const { t } = useTranslate();

  const { data: skills } = useSkillsQuery();
  const { data: userSkills, isLoading } = useUserSkillsByOwnerQuery(userId, {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: sync, isLoading: syncing } = useSyncUserSkillsMutation(userId);

  // Store a local open state to play exit animation
  const [open, setOpen] = useState(true);

  const [localSkills, setLocalSkills] = useState(userSkills || []);
  useEffect(() => setLocalSkills(userSkills), [userSkills]);

  const [tab, setTab] = useState(SKILL_TYPE.skill);

  const items = localSkills?.filter((item) => item.skill?.type === tab && !item.delete) || [];

  const handleAdd = (e) => {
    const skill = skills.find((item) => item.id === e.target.value);

    setLocalSkills((prevState) => [
      ...prevState,
      {
        id: skill.id,
        draft: true,
        level: 1,
        skill,
      },
    ]);
  };

  const handleChangeLevel = (e) => {
    setLocalSkills((prevState) => {
      return prevState.map((item) => {
        if (item.id === e.target.name) {
          return {
            ...item,
            level: parseInt(e.target.value, 10),
          };
        }

        return item;
      });
    });
  };

  const handleDelete = (id) => {
    setLocalSkills((prevState) => {
      return prevState.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            delete: true,
          };
        }

        return item;
      });
    });
  };

  const handleSubmit = async () => {
    await sync(localSkills);
    setOpen(false);
  };

  const handleClose = () => setOpen(false);

  const isAddDisabled = tab === SKILL_TYPE.skill ? 5 === items.length : 5 === items.length;

  const skillsList =
    skills?.filter((skill) => {
      return skill.type === tab && !items.some((userSkill) => userSkill.skill.id === skill.id);
    }) || [];

  return (
    <Dialog open={open} onClose={handleClose} TransitionProps={{ onExited: onClose }} maxWidth="sm" fullWidth>
      <DialogTitle>
        <FormattedMessage id="features.UserSkillsDialog.skill" />
      </DialogTitle>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(_, type) => setTab(type)}>
          <Tab label={t('constants.SkillType', { value: SKILL_TYPE.skill })} value={SKILL_TYPE.skill} sx={{ width: '50%' }} />
          <Tab label={t('constants.SkillType', { value: SKILL_TYPE.strongPoint })} value={SKILL_TYPE.strongPoint} sx={{ width: '50%', display: 'none' }} />
        </Tabs>
      </Box>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" shrink={false}>
            <FormattedMessage id="features.UserSkillsDialog.add" values={{ value: tab }} />
          </InputLabel>
          <Select disabled={isAddDisabled} value="" onChange={handleAdd}>
            {skillsList?.map((skill) => (
              <MenuItem key={skill.id} value={skill.id}>
                {skill.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ position: 'relative', minHeight: '300px' }}>
          {isLoading ? (
            <CenteredCircularProgress />
          ) : (
            <>
              {items.map((item) => (
                <UserSkillItem key={item.id} item={item} onChange={handleChangeLevel} onDelete={() => handleDelete(item.id)} />
              ))}
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="grey" onClick={handleClose}>
          <FormattedMessage id="common.verb.cancel" />
        </Button>
        <LoadingButton onClick={handleSubmit} variant="contained" loading={syncing}>
          <FormattedMessage id="common.verb.save" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

UserSkillsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default UserSkillsDialog;
