import { Box, Paper, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import DoubleListeningIllustration from '../atoms/illustrations/DoubleListeningIllustration';
import MEETING_STATUS from '../../constants/MeetingStatus';

function Meeting({ meeting, sx }) {
  let statusColor = 'success.main';
  if (meeting.status === MEETING_STATUS.pending) {
    statusColor = 'warning.main';
  } else if (meeting.status === MEETING_STATUS.canceled) {
    statusColor = 'danger.main';
  }

  return (
    <Paper sx={{ width: 450, height: 242, borderRadius: 2, position: 'relative', overflow: 'hidden', py: 3, px: 4, ...sx }}>
      <Typography variant="overline" color="textSecondary" sx={{ lineHeight: '1.4em', mb: 1, display: 'block' }}>
        <FormattedMessage id="constants.MeetingMode" values={{ value: meeting.mode }} />
      </Typography>
      <Typography variant="h3">
        {meeting.type} - {meeting.subject}
      </Typography>
      <Typography variant="body2" sx={{ mt: 2 }}>
        {meeting.coach.name}
      </Typography>
      {/* <Box
        sx={{
          position: 'absolute',
          top: 16,
          right: 24,
          color: 'common.white',
          bgcolor: 'grey.500',
          borderRadius: '3px',
          display: 'inline-flex',
          alignItems: 'center',
          px: 1,
          py: '3px',
        }}
      >
        <ClockIcon size={12} />
        <Typography sx={{fontSize: 12, fontWeight: 700, ml: 0.5}}>1h30</Typography>
      </Box> */}
      <Typography variant="subtitle2" sx={{ color: statusColor, position: 'absolute', left: 32, bottom: 24, width: '150px' }}>
        <FormattedMessage id="ui.Meeting.status" values={{ value: meeting.status, date: meeting.date ? new Date(meeting.date) : undefined }} />
      </Typography>
      <Box sx={{ position: 'absolute', bottom: -20, right: -40 }}>
        <DoubleListeningIllustration />
      </Box>
    </Paper>
  );
}

Meeting.propTypes = {
  meeting: PropTypes.shape({
    type: PropTypes.string,
    mode: PropTypes.string,
    subject: PropTypes.string,
    status: PropTypes.string,
    date: PropTypes.string,
    coach: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

Meeting.defaultProps = {
  sx: undefined,
};

export default Meeting;
