import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';
import { getImprovementAxis } from '../../graphql/queries';

function useImprovementAxisQuery(id, options = {}) {
  return useQuery(
    QUERY_CACHE_KEY.userImprovementAxes(id),
    async () => {
      const res = await API.graphql({
        // TODO Find better solutions
        query: getImprovementAxis,
        variables: { id },
      });

      return res.data.getImprovementAxis;
    },
    {
      enabled: !!id,
      ...options,
    }
  );
}

export default useImprovementAxisQuery;
