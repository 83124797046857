import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';

function useMeetingsByOwnerQuery(owner, options = {}) {
  return useQuery(
    QUERY_CACHE_KEY.userMeetings(owner),
    async () => {
      const res = await API.graphql({
        query: gql`
          query ListMeetingsByOwner($owner: String!, $sortDirection: ModelSortDirection, $filter: ModelMeetingFilterInput, $limit: Int, $nextToken: String) {
            listMeetingsByOwner(owner: $owner, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                id
                type
                mode
                status
                date
                subject
                message
                coach {
                  id
                  name
                }
                improvementAxisId
                owner
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        `,
        variables: {
          owner,
        },
      });
      return res.data.listMeetingsByOwner.items.sort((a, b) => (a.date ? a.date.localeCompare(b.date) : 1));
    },
    { enabled: !!owner, ...options }
  );
}

export default useMeetingsByOwnerQuery;
