import { useMutation, useQueryClient } from 'react-query';
import { API } from 'aws-amplify';
import { createCoach } from '../../graphql/mutations';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';

function useCreateCoachMutation(options = {}) {
  const client = useQueryClient();

  return useMutation(
    (input) => {
      return API.graphql({ query: createCoach, variables: { input } });
    },
    {
      ...options,
      onSuccess: (res) => {
        client.invalidateQueries(QUERY_CACHE_KEY.coaches());
        if ('function' === typeof options.onSuccess) {
          options.onSuccess(res);
        }
      },
    }
  );
}

export default useCreateCoachMutation;
