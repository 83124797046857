import { useMutation, useQueryClient } from 'react-query';
import { API } from 'aws-amplify';
import { deleteSkill } from '../../graphql/mutations';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';

function useDeleteSkillMutation(options = {}) {
  const client = useQueryClient();

  return useMutation(
    (id) => {
      return API.graphql({ query: deleteSkill, variables: { input: { id } } });
    },
    {
      ...options,
      onSuccess: (res) => {
        client.invalidateQueries(QUERY_CACHE_KEY.skills());
        if ('function' === typeof options.onSuccess) {
          options.onSuccess(res);
        }
      },
    }
  );
}

export default useDeleteSkillMutation;
