import PropTypes from 'prop-types';

function Logo(props) {
  const { width } = props;

  // eslint-disable-next-line react/destructuring-assignment
  const height = props.height || (props.width * 75) / 447;

  return (
    <svg width={width} height={height} viewBox="0 0 447 75" fill="none">
      <path
        d="M120.59 40.2632C117.282 46.7806 111.784 53.0796 100.102 53.0796C85.4628 53.0796 77.6418 42.1781 77.6418 27.5309C77.6418 11.607 86.9335 1.98218 100.169 1.98218C112.285 1.98218 117.064 7.22294 120.44 16.5622L108.04 21.4503C106.302 16.8478 104.614 13.9419 100.102 13.9419C94.4035 13.9419 91.9302 19.6697 91.9302 27.5309C91.9302 35.1065 94.32 41.1199 100.236 41.1199C104.531 41.1199 106.369 38.7851 109.043 34.1154L120.59 40.2632Z"
        fill="white"
      />
      <path
        d="M159.111 33.6786C159.111 46.495 151.724 53.0627 141.513 53.0627C131.303 53.0627 123.983 46.4782 123.983 33.6786C123.983 20.7279 131.303 14.3617 141.513 14.3617C151.724 14.3617 159.111 20.7279 159.111 33.6786ZM146.51 33.6786C146.51 27.3796 144.822 24.692 141.513 24.692C138.204 24.692 136.517 27.3796 136.517 33.6786C136.517 39.8432 138.204 42.7324 141.513 42.7324C144.822 42.7324 146.51 39.8432 146.51 33.6786Z"
        fill="white"
      />
      <path
        d="M196.845 52.2901H184.88V49.8209C182.49 51.6686 179.248 53.0795 175.872 53.0795C168.686 53.0795 163.907 48.6283 163.907 40.2632C163.907 32.2677 169.405 28.1691 177.209 28.1691C180.585 28.1691 182.841 28.8074 184.88 29.7313V27.8836C184.88 24.6249 183.125 23.4995 179.666 23.4995C175.872 23.4995 172.981 24.205 169.388 26.187L166.079 17.7716C170.591 15.5711 175.588 14.3785 181.989 14.3785C192.333 14.3785 196.845 19.5521 196.845 28.1859V52.2901ZM184.88 42.2453V37.1557C183.476 36.5174 181.855 36.0975 179.465 36.0975C176.858 36.0975 175.521 37.5084 175.521 40.0616C175.521 42.3965 176.724 44.093 179.465 44.093C181.571 44.093 183.676 43.2363 184.88 42.2453Z"
        fill="white"
      />
      <path
        d="M235.148 44.2273C230.853 50.5935 226.842 53.0795 220.291 53.0795C209.446 53.0795 202.978 46.1422 202.978 33.6114C202.978 22.2228 208.744 14.3617 220.576 14.3617C227.544 14.3617 231.555 17.6204 234.797 22.8611L226.074 29.5129C224.319 26.2542 223.116 24.7088 220.375 24.7088C217.133 24.7088 215.512 27.9675 215.512 33.8466C215.512 39.7256 217.267 42.7659 220.726 42.7659C223.116 42.7659 224.804 41.4222 227.126 37.9619L235.148 44.2273Z"
        fill="white"
      />
      <path
        d="M275.005 52.2901H262.254V31.2095C262.254 26.4055 260.633 25.1289 258.026 25.1289C255.352 25.1289 253.731 26.4055 253.731 31.0751V52.3069H241.064V5.79515L253.731 0.705566V17.6205C256.054 15.8567 258.661 14.3618 263.023 14.3618C271.111 14.3618 274.988 19.8041 274.988 29.5801V52.2901H275.005Z"
        fill="white"
      />
      <path
        d="M329.635 52.2901H314.21L310.684 48.2588C306.256 51.4503 301.326 53.0628 295.343 53.0628C286.97 53.0628 280.486 49.1658 280.486 39.6921C280.486 33.2588 285.483 28.4379 290.764 25.4648L290.346 24.9777C287.89 22.139 285.918 19.0986 285.918 15.2856C285.918 6.36628 293.104 1.98218 302.88 1.98218C309.848 1.98218 314.712 3.7459 319.357 6.51745L313.792 16.7134C310.133 14.6641 306.957 13.3876 302.746 13.3876C300.557 13.3876 299.304 14.3114 299.304 15.504C299.304 16.8478 299.788 17.7045 300.925 18.9643L311.486 31.2767C312.823 29.0762 314.227 26.5398 315.848 23.4995L326.125 28.8747C323.669 33.0572 321.329 36.7358 318.94 39.9105L329.635 52.2901ZM303.933 40.5488L297.031 32.6877C294.491 34.4514 293.371 36.2319 293.371 37.9956C293.371 40.4648 294.641 42.1781 298.719 42.1781C300.624 42.1781 302.245 41.6742 303.933 40.5488Z"
        fill="#00639E"
      />
      <path
        d="M383.914 52.29H371.096V40.4815C371.096 35.5935 371.163 31.1422 371.381 27.3124C369.91 30.991 367.37 36.7189 365.682 40.2632L359.198 53.9866L352.714 40.2632C351.026 36.7189 348.486 30.991 347.015 27.3124C347.232 31.1254 347.299 35.5935 347.299 40.4815V52.3068H334.481V2.75476H347.015L353.065 15.991C355.956 22.3572 358.479 28.1691 359.265 30.9239C360.117 28.0851 362.356 22.71 365.465 15.991L371.514 2.75476H383.914V52.29Z"
        fill="#00639E"
      />
      <path
        d="M426.512 33.6786C426.512 46.495 419.125 53.0627 408.914 53.0627C398.704 53.0627 391.384 46.4782 391.384 33.6786C391.384 20.7279 398.704 14.3617 408.914 14.3617C419.125 14.3617 426.512 20.7279 426.512 33.6786ZM413.911 33.6786C413.911 27.3796 412.223 24.692 408.914 24.692C405.606 24.692 403.918 27.3796 403.918 33.6786C403.918 39.8432 405.606 42.7324 408.914 42.7324C412.223 42.7324 413.911 39.8432 413.911 33.6786Z"
        fill="#00639E"
      />
      <path
        d="M447 6.16461C447 10.0616 443.975 12.4636 439.614 12.4636C435.452 12.4636 432.361 10.0616 432.361 6.16461C432.361 2.55319 435.452 0 439.614 0C443.975 0 447 2.55319 447 6.16461ZM445.947 52.29H433.28V15.1512H445.947V52.29Z"
        fill="#00639E"
      />
      <path d="M48.5805 46.4277V39.1377L43.2495 44.496V71.1534C46.174 70.3471 48.9147 69.1545 51.4716 67.6427V46.4277H48.5805Z" fill="#00639E" />
      <path d="M28.8609 48.8634L34.1418 43.5554V3.76257C31.3008 3.76257 28.5601 4.14891 25.9197 4.80401V46.5789L28.8609 48.8634Z" fill="#00639E" />
      <path d="M8.60645 57.3964V60.7895C10.9628 63.4771 13.7369 65.7783 16.8285 67.6092V49.3673L8.60645 57.3964Z" fill="#00639E" />
      <path d="M42.8148 34.8376V34.0818H37.8181V26.9429H42.8148V4.92164C40.1744 4.23295 37.4337 3.82981 34.5928 3.77942V43.1019L42.8148 34.8376Z" fill="#00639E" />
      <path d="M51.4716 26.9429V8.54982C48.9147 7.03806 46.174 5.84546 43.2495 5.03918V26.9597H51.4716V26.9429Z" fill="#00639E" />
      <path d="M43.567 34.0818H43.2495V34.4009L43.567 34.0818Z" fill="#00639E" />
      <path d="M68.3 38.0795C68.3 29.832 65.4089 22.2733 60.5959 16.3606V59.7984C65.4089 53.8858 68.3 46.327 68.3 38.0795Z" fill="#00639E" />
      <path d="M29.4458 58.3706L25.9197 55.6159V71.3718C28.5601 72.0269 31.3008 72.4132 34.1418 72.4132V53.6338L29.4458 58.3706Z" fill="#00639E" />
      <path d="M34.5928 53.197V72.3963C37.4337 72.3627 40.1744 71.9596 42.8148 71.2541V44.9327L34.5928 53.197Z" fill="#00639E" />
      <path d="M8.15523 15.8566C3.07492 21.8532 0 29.5968 0 38.0794C0 43.3034 1.18652 48.225 3.27546 52.6595L8.15523 47.889V15.8566Z" fill="#00639E" />
      <path d="M17.3131 48.8801L17.2629 48.9305V67.8947C19.8198 69.3561 22.5772 70.4815 25.485 71.2541V55.2631L17.3131 48.8801Z" fill="#00639E" />
      <path
        d="M51.9226 8.8186V26.9597H55.6827V46.4278H51.9226V67.3572C55.0142 65.4591 57.8051 63.0907 60.1447 60.3192V15.8567C57.8051 13.0851 55.0142 10.7167 51.9226 8.8186Z"
        fill="#00639E"
      />
      <path d="M7.05225 58.9082C7.4199 59.3785 7.77084 59.8488 8.15521 60.3023V57.8331L7.05225 58.9082Z" fill="#00639E" />
      <path d="M16.7952 39.4401L16.8119 39.4569V8.5498C13.7203 10.3807 10.9629 12.6819 8.58984 15.3695V47.4524L16.7952 39.4401Z" fill="#00639E" />
      <path d="M25.485 46.2261V4.92151C22.5772 5.69419 19.8198 6.8196 17.2629 8.28097V39.8095L25.485 46.2261Z" fill="#00639E" />
      <path d="M8.60649 47.4524V15.3695C8.45608 15.5375 8.30568 15.6887 8.15527 15.8566V47.8891L8.60649 47.4524Z" fill="#1F3A6F" />
      <path d="M8.15527 57.8332V60.3024C8.30568 60.4704 8.45608 60.6216 8.60649 60.7895V57.3965L8.15527 57.8332Z" fill="#1F3A6F" />
      <path d="M16.8118 49.3673V67.6259C16.9622 67.7099 17.1126 67.8107 17.263 67.8947V48.9305L16.8118 49.3673Z" fill="#1F3A6F" />
      <path d="M17.263 39.7928V8.28101C17.1126 8.36499 16.9622 8.46578 16.8118 8.54976V39.4568L17.263 39.7928Z" fill="#1F3A6F" />
      <path d="M25.4851 55.2632V71.2542C25.6355 71.2878 25.7859 71.3382 25.9363 71.3718V55.6159L25.4851 55.2632Z" fill="#1F3A6F" />
      <path d="M25.9195 46.5789V4.80396C25.7691 4.83755 25.6187 4.88794 25.4683 4.92154V46.2261L25.9195 46.5789Z" fill="#1F3A6F" />
      <path d="M34.5928 43.1019V3.77937C34.4424 3.77937 34.292 3.76257 34.1416 3.76257V43.5554L34.5928 43.1019Z" fill="#1F3A6F" />
      <path d="M34.1416 53.6339V72.3965C34.292 72.3965 34.4424 72.3797 34.5928 72.3797V53.1971L34.1416 53.6339Z" fill="#1F3A6F" />
      <path d="M42.8149 44.9328V71.2542C42.9653 71.2206 43.1157 71.187 43.2662 71.1366V44.4792L42.8149 44.9328Z" fill="#1F3A6F" />
      <path d="M43.2494 34.0818H42.8149V34.8377L43.2494 34.4009V34.0818Z" fill="#1F3A6F" />
      <path d="M43.2493 26.9428V5.03909C43.0989 5.0055 42.9485 4.9551 42.7981 4.92151V26.9596H43.2493V26.9428Z" fill="#1F3A6F" />
      <path d="M51.4714 46.4277V67.6259C51.6218 67.542 51.7722 67.4412 51.9226 67.3572V46.4277H51.4714Z" fill="#1F3A6F" />
      <path d="M51.9226 26.9429V8.81856C51.7722 8.73457 51.6218 8.63379 51.4714 8.5498V26.9597H51.9226V26.9429Z" fill="#1F3A6F" />
      <path d="M60.5794 16.3605C60.429 16.1925 60.2786 16.0245 60.1282 15.8566V60.3191C60.2786 60.1511 60.429 59.9831 60.5794 59.8151V16.3605Z" fill="#1F3A6F" />
      <path d="M55.6827 26.9429H51.9226V46.411H55.6827V26.9429Z" fill="white" />
      <path d="M16.7951 39.4401L8.60645 47.4524V57.3964L16.8118 49.3673V39.4569L16.7951 39.4401Z" fill="white" />
      <path d="M43.2495 26.9429V34.0817H43.567L43.2495 34.4009V44.4793L48.5805 39.1209V46.4278H51.4716V26.9429H43.2495Z" fill="white" />
      <path d="M28.8609 48.8634L25.9197 46.5789V55.6159L29.4458 58.3707L34.1418 53.6338V43.5554L28.8609 48.8634Z" fill="white" />
      <path d="M42.8149 26.9429H37.8181V34.0817H42.8149V26.9429Z" fill="white" />
      <path d="M8.15516 47.8892L3.27539 52.6596C4.31151 54.8936 5.58158 56.9765 7.0522 58.9082L8.15516 57.8332V47.8892Z" fill="white" />
      <path d="M34.5928 43.1019V53.1971L42.8148 44.9328V34.8376L34.5928 43.1019Z" fill="white" />
      <path d="M17.2629 39.7927V48.9305L17.3131 48.8801L25.485 55.263V46.2261L17.2629 39.7927Z" fill="white" />
      <path d="M8.15527 47.8891V57.8331L8.60649 57.3964V47.4524L8.15527 47.8891Z" fill="white" />
      <path d="M16.8118 39.4569V49.3673L17.263 48.9306V39.7929L16.8118 39.4569Z" fill="white" />
      <path d="M25.4851 46.2262V55.2632L25.9196 55.6159V46.5789L25.4851 46.2262Z" fill="white" />
      <path d="M34.1416 43.5555V53.6338L34.5928 53.1971V43.1019L34.1416 43.5555Z" fill="white" />
      <path d="M42.8149 34.8376V44.9328L43.2494 44.4793V34.4009L42.8149 34.8376Z" fill="white" />
      <path d="M43.2662 26.9429H42.8149V34.0817H43.2662V26.9429Z" fill="white" />
      <path d="M51.9226 26.9429H51.4714V46.411H51.9226V26.9429Z" fill="white" />
      <path
        d="M85.3625 65.8117C85.3625 67.6762 84.059 68.7177 82.1873 68.7177H79.9145V72.4131H78.8784V63.0066H82.3711C84.0757 63.0066 85.3625 63.9136 85.3625 65.8117ZM84.3097 65.8621C84.3097 64.6527 83.6078 63.964 82.3377 63.964H79.9145V67.7602H82.204C83.6245 67.7602 84.3097 67.1051 84.3097 65.8621Z"
        fill="white"
      />
      <path
        d="M90.8438 65.3919L90.5931 66.4166C90.309 66.2486 90.0082 66.1478 89.5403 66.1478C88.571 66.1478 88.0195 66.9373 88.0195 68.2811V72.4132H87.0168V65.3583H88.0195V66.2318C88.3371 65.6607 89.0055 65.2072 89.7575 65.2072C90.2756 65.2072 90.6098 65.2743 90.8438 65.3919Z"
        fill="white"
      />
      <path
        d="M97.846 68.8354C97.846 71.2206 96.4757 72.5476 94.7711 72.5476C93.0665 72.5476 91.7463 71.271 91.7463 68.8858C91.7463 66.5005 93.1167 65.2072 94.8213 65.2072C96.5258 65.2072 97.846 66.4502 97.846 68.8354ZM96.8434 68.869C96.8434 67.0213 96.0078 66.0806 94.8045 66.0806C93.6013 66.0806 92.7657 67.0045 92.7657 68.869C92.7657 70.7335 93.5846 71.6909 94.8045 71.6909C96.0245 71.6909 96.8434 70.7167 96.8434 68.869Z"
        fill="white"
      />
      <path
        d="M105.316 68.7682C105.316 71.271 104.146 72.5476 102.676 72.5476C101.79 72.5476 101.188 72.178 100.704 71.6069V74.4289L99.7009 75V65.3583H100.704V66.215C101.205 65.6775 101.873 65.2072 102.809 65.2072C104.213 65.2072 105.316 66.2822 105.316 68.7682ZM104.33 68.785C104.33 67.0045 103.662 66.0806 102.559 66.0806C101.807 66.0806 101.188 66.4838 100.704 67.1053V70.6159C101.088 71.2038 101.773 71.6741 102.609 71.6741C103.745 71.6909 104.33 70.6831 104.33 68.785Z"
        fill="white"
      />
      <path
        d="M112.519 72.4132H111.516V71.5565C111.115 72.1444 110.463 72.5643 109.577 72.5643C108.09 72.5643 107.121 71.6909 107.121 69.6584V65.3583H108.124V69.4736C108.124 70.9854 108.658 71.6741 109.778 71.6741C110.747 71.6741 111.516 71.019 111.516 69.4736V65.3583H112.519V72.4132Z"
        fill="white"
      />
      <path d="M115.811 72.4131H114.808V63.1913L115.811 62.6202V72.4131Z" fill="white" />
      <path
        d="M122.445 70.5654C122.445 71.9428 121.259 72.5475 120.039 72.5475C119.07 72.5475 118.234 72.1779 117.566 71.6404L118.151 70.9013C118.719 71.4053 119.371 71.674 120.056 71.674C121.025 71.674 121.476 71.2709 121.476 70.5822C121.476 70.0111 121.058 69.6751 119.822 69.1376C118.418 68.5329 117.833 68.0962 117.833 66.9708C117.833 65.8957 118.752 65.1735 120.022 65.1735C120.992 65.1735 121.727 65.4758 122.262 65.9293L121.693 66.6684C121.142 66.2317 120.691 66.0301 119.939 66.0301C119.203 66.0301 118.769 66.3997 118.769 66.9036C118.769 67.4915 119.137 67.7435 120.323 68.281C121.66 68.9193 122.445 69.3056 122.445 70.5654Z"
        fill="white"
      />
      <path
        d="M129.682 68.6674C129.682 68.9025 129.665 69.0537 129.648 69.2217H124.969C125.069 70.7334 125.771 71.6741 126.924 71.6741C127.743 71.6741 128.228 71.4389 128.863 71.0022L129.331 71.7245C128.495 72.262 127.877 72.5475 126.824 72.5475C125.537 72.5475 123.983 71.6069 123.983 68.8353C123.983 66.5845 125.22 65.1903 126.874 65.1903C128.696 65.2071 129.682 66.6685 129.682 68.6674ZM128.746 68.3986C128.662 67.0212 128.077 66.0806 126.841 66.0806C125.804 66.0806 125.069 66.8365 124.952 68.3986H128.746ZM129.08 62.2676L127.058 64.4176H126.272L127.643 62.2676H129.08Z"
        fill="white"
      />
      <path
        d="M140.661 68.7682C140.661 71.271 139.491 72.5476 138.021 72.5476C137.135 72.5476 136.533 72.178 136.049 71.6069V74.4289L135.046 75V65.3583H136.049V66.215C136.55 65.6775 137.218 65.2072 138.154 65.2072C139.558 65.2072 140.661 66.2822 140.661 68.7682ZM139.675 68.785C139.675 67.0045 139.007 66.0806 137.904 66.0806C137.152 66.0806 136.533 66.4838 136.049 67.1053V70.6159C136.433 71.2038 137.118 71.6741 137.954 71.6741C139.107 71.6909 139.675 70.6831 139.675 68.785Z"
        fill="white"
      />
      <path
        d="M147.429 72.4132H146.426V71.5565C145.925 72.094 145.24 72.5644 144.237 72.5644C143.134 72.5644 141.998 72.0101 141.998 70.3135C141.998 68.7346 143.301 68.0627 144.688 68.0627C145.39 68.0627 146.025 68.2307 146.443 68.4658V67.626C146.443 66.5845 145.942 66.0638 144.922 66.0638C144.187 66.0638 143.585 66.2654 143.017 66.6013L142.65 65.7783C143.268 65.4423 144.087 65.2072 145.006 65.2072C146.51 65.2072 147.462 65.8455 147.462 67.4916V72.4132H147.429ZM146.426 70.6831V69.2553C145.975 69.0033 145.474 68.8522 144.622 68.8522C143.585 68.8522 142.984 69.3561 142.984 70.2296C142.984 71.271 143.552 71.7413 144.421 71.7413C145.323 71.7413 145.992 71.271 146.426 70.6831Z"
        fill="white"
      />
      <path
        d="M153.445 65.3919L153.195 66.4166C152.911 66.2486 152.61 66.1478 152.142 66.1478C151.173 66.1478 150.621 66.9373 150.621 68.2811V72.4132H149.618V65.3583H150.621V66.2318C150.939 65.6607 151.607 65.2072 152.359 65.2072C152.877 65.2072 153.211 65.2743 153.445 65.3919Z"
        fill="white"
      />
      <path d="M164.508 72.4131H158.659V63.0066H164.308V63.964H159.695V66.9204H162.369V67.8778H159.695V71.4556H164.508V72.4131Z" fill="white" />
      <path
        d="M173.232 67.6426C173.232 71.0525 171.277 72.4131 168.787 72.4131H166.497V63.0066H168.97C171.427 63.0066 173.232 64.4176 173.232 67.6426ZM172.179 67.6762C172.179 65.2742 171.026 63.964 168.887 63.964H167.533V71.4556H168.904C170.926 71.4556 172.179 70.3806 172.179 67.6762Z"
        fill="white"
      />
      <path d="M180.785 63.964H176.34V67.0715H178.914V68.029H176.34V72.4131H175.304V63.0066H180.802V63.964H180.785Z" fill="white" />
      <path
        d="M192.935 68.6674C192.935 71.2542 191.447 72.5644 189.475 72.5644C187.186 72.5644 185.698 70.4815 185.698 67.7267C185.698 64.9384 187.269 62.8723 189.525 62.8723C190.812 62.8723 191.765 63.3594 192.5 64.3841L191.665 65.0224C191.063 64.1993 190.495 63.8466 189.492 63.8466C187.888 63.8466 186.735 65.4423 186.735 67.7267C186.735 70.028 187.938 71.6405 189.492 71.6405C191.046 71.6405 191.915 70.6663 191.915 68.8354V68.7514H190.227V67.7771H192.935V68.6674Z"
        fill="white"
      />
      <path
        d="M198.617 65.3919L198.366 66.4166C198.082 66.2486 197.781 66.1478 197.313 66.1478C196.344 66.1478 195.792 66.9373 195.792 68.2811V72.4132H194.79V65.3583H195.792V66.2318C196.11 65.6607 196.778 65.2072 197.53 65.2072C198.048 65.2072 198.383 65.2743 198.617 65.3919Z"
        fill="white"
      />
      <path
        d="M204.783 72.4132H203.78V71.5565C203.279 72.094 202.594 72.5644 201.591 72.5644C200.488 72.5644 199.352 72.0101 199.352 70.3135C199.352 68.7346 200.655 68.0627 202.042 68.0627C202.744 68.0627 203.379 68.2307 203.797 68.4658V67.626C203.797 66.5845 203.296 66.0638 202.276 66.0638C201.541 66.0638 200.939 66.2654 200.371 66.6013L200.004 65.7783C200.622 65.4423 201.441 65.2072 202.36 65.2072C203.864 65.2072 204.816 65.8455 204.816 67.4916V72.4132H204.783ZM203.78 70.6831V69.2553C203.329 69.0033 202.828 68.8522 201.976 68.8522C200.939 68.8522 200.338 69.3561 200.338 70.2296C200.338 71.271 200.906 71.7413 201.775 71.7413C202.677 71.7413 203.346 71.271 203.78 70.6831Z"
        fill="white"
      />
      <path
        d="M212.37 72.4132H211.367V68.2979C211.367 66.7861 210.833 66.0974 209.713 66.0974C208.744 66.0974 207.975 66.7525 207.975 68.2979V72.4132H206.972V65.3583H207.975V66.215C208.376 65.6271 209.028 65.2072 209.913 65.2072C211.401 65.2072 212.37 66.0806 212.37 68.1131V72.4132Z"
        fill="white"
      />
      <path
        d="M219.79 72.4131H218.787V71.5564C218.286 72.0939 217.618 72.5643 216.682 72.5643C215.278 72.5643 214.175 71.4892 214.175 69.0032C214.175 66.5004 215.345 65.2238 216.815 65.2238C217.701 65.2238 218.303 65.5934 218.787 66.1645V63.1913L219.79 62.6202V72.4131ZM218.787 70.6662V67.1555C218.403 66.5676 217.718 66.0973 216.882 66.0973C215.712 66.0973 215.144 67.1051 215.144 69.0032C215.144 70.7837 215.813 71.7076 216.916 71.7076C217.684 71.6908 218.303 71.2877 218.787 70.6662Z"
        fill="white"
      />
      <path
        d="M232.056 71.0022C231.371 71.9261 230.352 72.5644 228.998 72.5644C226.709 72.5644 225.221 70.4815 225.221 67.7267C225.221 64.9384 226.792 62.8723 229.048 62.8723C230.335 62.8723 231.287 63.3594 232.023 64.3841L231.187 65.0224C230.586 64.1993 230.017 63.8466 229.015 63.8466C227.41 63.8466 226.257 65.4423 226.257 67.7267C226.257 70.028 227.444 71.6405 229.015 71.6405C230.034 71.6405 230.719 71.187 231.321 70.3975L232.056 71.0022Z"
        fill="white"
      />
      <path
        d="M239.008 68.6674C239.008 68.9025 238.992 69.0537 238.975 69.2217H234.296C234.396 70.7334 235.098 71.6741 236.251 71.6741C237.07 71.6741 237.554 71.4389 238.189 71.0022L238.657 71.7245C237.822 72.262 237.203 72.5475 236.151 72.5475C234.864 72.5475 233.31 71.6069 233.31 68.8353C233.31 66.5845 234.546 65.1903 236.201 65.1903C238.022 65.2071 239.008 66.6685 239.008 68.6674ZM238.072 68.3986C237.989 67.0212 237.404 66.0806 236.167 66.0806C235.131 66.0806 234.396 66.8364 234.279 68.3986H238.072Z"
        fill="white"
      />
      <path
        d="M246.278 72.4132H245.275V68.2979C245.275 66.7861 244.74 66.0974 243.621 66.0974C242.651 66.0974 241.883 66.7525 241.883 68.2979V72.4132H240.88V65.3583H241.883V66.215C242.284 65.6271 242.935 65.2072 243.821 65.2072C245.308 65.2072 246.278 66.0806 246.278 68.1131V72.4132Z"
        fill="white"
      />
      <path
        d="M251.408 66.1981H249.67V70.7838C249.67 71.4221 249.837 71.6236 250.372 71.6236C250.773 71.6236 251.04 71.5564 251.358 71.4221L251.291 72.2955C250.907 72.4635 250.522 72.5307 249.988 72.5307C249.119 72.5307 248.651 72.094 248.651 70.9349V66.1813H247.782V65.3414H248.651V63.1578L249.653 62.5867V65.3246H251.391V66.1981H251.408Z"
        fill="white"
      />
      <path
        d="M257.207 65.3919L256.956 66.4166C256.672 66.2486 256.372 66.1478 255.904 66.1478C254.934 66.1478 254.383 66.9373 254.383 68.2811V72.4132H253.38V65.3583H254.383V66.2318C254.7 65.6607 255.369 65.2072 256.121 65.2072C256.622 65.2072 256.956 65.2743 257.207 65.3919Z"
        fill="white"
      />
      <path
        d="M263.825 68.6674C263.825 68.9025 263.808 69.0537 263.791 69.2217H259.112C259.212 70.7334 259.914 71.6741 261.067 71.6741C261.886 71.6741 262.371 71.4389 263.006 71.0022L263.474 71.7245C262.638 72.262 262.02 72.5475 260.967 72.5475C259.68 72.5475 258.126 71.6069 258.126 68.8353C258.126 66.5845 259.363 65.1903 261.017 65.1903C262.822 65.2071 263.825 66.6685 263.825 68.6674ZM262.889 68.3986C262.805 67.0212 262.221 66.0806 260.984 66.0806C259.948 66.0806 259.212 66.8364 259.095 68.3986H262.889Z"
        fill="white"
      />
    </svg>
  );
}

Logo.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
};

Logo.defaultProps = {
  height: undefined,
};

export default Logo;
