import { Box, CircularProgress } from '@mui/material';

function CenteredCircularProgress() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        left: '0',
        right: '0',
        top: '0',
        bottom: '0',
        position: 'absolute',
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export default CenteredCircularProgress;
