import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import WomenAvatar from '../atoms/WomenAvatar';
import Skill from '../atoms/molecules/Skill';
import LogoutIcon from '../atoms/icons/LogoutIcon';
import UserSkillsDialog from '../../features/UserSkillsDialog/UserSkillsDialog';
import useCurrentUser from '../../hooks/useCurrentUser';
import useUserSkillsByOwnerQuery from '../../hooks/queries/useUserSkillsByOwnerQuery';
import SKILL_TYPE from '../../constants/SkillType';
import useTranslate from '../../hooks/useTranslate';
import ComputerIcon from '../atoms/icons/ComputerIcon';

const DRAWER_WIDTH = 380;

function AppLayout({ children, user: passedUser }) {
  const { t } = useTranslate();
  const currentUser = useCurrentUser();

  const user = passedUser || currentUser;

  const [userSkillsOpen, setUserSkillsOpen] = useState(false);

  const { data: userSkills } = useUserSkillsByOwnerQuery(user.username, {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    select: (items) => {
      return {
        skills: items?.filter((item) => item.skill?.type === SKILL_TYPE.skill),
        strongPoints: items?.filter((item) => item.skill?.type === SKILL_TYPE.strongPoint),
      };
    },
  });

  const { skills, strongPoints } = userSkills || {};

  const handleLogout = () => Auth.signOut();

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Drawer
          sx={{
            width: DRAWER_WIDTH,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: DRAWER_WIDTH,
              boxSizing: 'border-box',
              bgcolor: 'primary.dark',
              border: 'none',
              color: 'common.white',
              px: 4,
              pt: 6,
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Box sx={{ height: '100%' }}>
            <Box sx={{ textAlign: 'center' }}>
              <WomenAvatar />
              <Typography variant="h4" sx={{ mt: 1 }}>
                {user.name}
              </Typography>
              {user.job && (
                <Typography variant="body2" color="textSecondary">
                  {user.job}
                </Typography>
              )}
            </Box>
            {0 < skills?.length && (
              <Box sx={{ mt: 6 }}>
                <Typography variant="subtitle2" color="textSecondary" sx={{ textTransform: 'uppercase' }}>
                  <FormattedMessage id="ui.AppLayout.skills" />
                </Typography>
                {skills?.map((item) => (
                  <Skill
                    key={item.id}
                    icon={item.skill.icon || <ComputerIcon size={18} />}
                    title={item.skill.name}
                    subtitle={t('constants.SkillLevel', { value: item.level })}
                    value={item.level * 25}
                    color="purple.main"
                    sx={{ mt: 3 }}
                  />
                ))}
              </Box>
            )}
            {0 < strongPoints?.length && (
              <Box sx={{ mt: 6 }}>
                <Typography variant="subtitle2" color="textSecondary" sx={{ textTransform: 'uppercase' }}>
                  <FormattedMessage id="ui.AppLayout.strongPoints" />
                </Typography>
                {strongPoints?.map((item) => (
                  <Skill
                    key={item.id}
                    icon={item.skill.icon || <ComputerIcon size={18} />}
                    title={item.skill.name}
                    subtitle={t('constants.SkillLevel', { value: item.level })}
                    value={item.level * 25}
                    color="primary.light"
                    sx={{ mt: 3 }}
                  />
                ))}
              </Box>
            )}
            <Button variant="outlined" color="primary" sx={{ mt: 4 }} onClick={() => setUserSkillsOpen(true)} fullWidth>
              <FormattedMessage id="ui.AppLayout.updateProfile" />
            </Button>
            {user.groups?.includes('Managers') && (
              <Button component={Link} to="/admin" variant="contained" color="primary" sx={{ mt: 1 }} fullWidth>
                <FormattedMessage id="ui.AppLayout.admin" />
              </Button>
            )}
          </Box>
          <Box sx={{ textAlign: 'center', mt: 4, pb: 1 }}>
            <Button onClick={handleLogout} startIcon={<LogoutIcon size={22} />} variant="text" color="white" sx={{ mt: 4, px: 3, py: '12px' }}>
              <FormattedMessage id="common.verb.logout" />
            </Button>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, pt: 6, position: 'relative', width: `calc(100% - ${DRAWER_WIDTH}px)` }}>
          {passedUser && (
            <Box sx={{ height: 55 }}>
              <Box
                sx={{
                  backgroundColor: 'warning.main',
                  color: 'common.white',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  px: 2,
                  py: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <FormattedMessage id="ui.AppLayout.seeAs" values={{ name: passedUser.name }} />
                <Button variant="contained" component={Link} to="/admin/users">
                  <FormattedMessage id="common.verb.back" />
                </Button>
              </Box>
            </Box>
          )}
          {children}
        </Box>
      </Box>
      {userSkillsOpen && <UserSkillsDialog userId={user?.username} onClose={() => setUserSkillsOpen(false)} />}
    </>
  );
}

AppLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    name: PropTypes.string,
    job: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
  }),
};

AppLayout.defaultProps = {
  user: undefined,
};

export default AppLayout;
