import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-hot-toast';
import EditIcon from '../ui/atoms/icons/EditIcon';
import ConfirmDialog from '../ui/organisms/ConfirmDialog';
import useTranslate from '../hooks/useTranslate';
import CenteredCircularProgress from '../ui/atoms/CenteredCircularProgress';
import useCoachesQuery from '../hooks/queries/useCoachesQuery';
import SwitchIcon from '../ui/atoms/icons/SwitchIcon';
import useUpdateCoachMutation from '../hooks/mutations/useUpdateCoachMutation';
import CoachDialog from '../features/CoachDialog';
import AdminLayout from '../ui/templates/AdminLayout';
import ADMIN_PAGE from '../constants/AdminPage';

function Coaches() {
  const { t } = useTranslate();

  // Can contain a coach ID
  const [open, setOpen] = useState(false);

  const [switchId, setSwitchId] = useState(null);

  const { data, isLoading } = useCoachesQuery();
  const { mutate, isLoading: deleting } = useUpdateCoachMutation({
    onSuccess: () => toast.success(t('pages.Coaches.success')),
    onError: () => toast.error(t('errors.api.default')),
  });

  const switchItem = data?.find((item) => item.id === switchId);

  const handleSwitch = (item) => {
    mutate({
      id: item.id,
      disabled: !item.disabled,
    });
  };

  return (
    <AdminLayout selected={ADMIN_PAGE.coaches}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
        <Typography variant="h2">
          <FormattedMessage id="pages.Coaches.title" />
        </Typography>
        <Button variant="contained" onClick={() => setOpen(true)}>
          <FormattedMessage id="common.verb.add" />
        </Button>
      </Box>
      {isLoading ? (
        <Box sx={{ height: '500px', width: '100%', position: 'relative' }}>
          <CenteredCircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="pages.Coaches.columns.name" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="pages.Coaches.columns.state" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="common.actions" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" sx={{ fontWeight: 500, color: item.disabled ? 'danger.main' : 'success.main' }}>
                        <FormattedMessage id={item.disabled ? 'common.disabled' : 'common.enabled'} />
                      </Typography>
                      <IconButton size="small" onClick={() => setSwitchId(item.id)} sx={{ ml: 1 }}>
                        {deleting ? <CircularProgress color="inherit" size={20} /> : <SwitchIcon size={20} />}
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton color="primary" onClick={() => setOpen(item.id)}>
                      <EditIcon size={20} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {open && <CoachDialog id={true === open ? undefined : open} onClose={() => setOpen(false)} />}
      {switchId && (
        <ConfirmDialog
          title={t('pages.Coaches.confirmSwitch.title', { name: switchItem?.name, disabled: switchItem?.disabled })}
          confirmLabel={t('common.verb.validate')}
          onConfirm={() => handleSwitch(switchItem)}
          onExited={() => setSwitchId(null)}
        />
      )}
    </AdminLayout>
  );
}

export default Coaches;
