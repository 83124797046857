import Box from '@mui/material/Box';
import { LinearProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function Skill({ icon, title, subtitle, value, color, sx }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
      <Box
        sx={{ bgcolor: color, height: 48, width: 48, borderRadius: 1, flexShrink: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        dangerouslySetInnerHTML={'string' === typeof icon ? { __html: icon } : undefined}
      >
        {'string' === typeof icon ? null : icon}
      </Box>
      <Box sx={{ mx: 2, width: '100%' }}>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="body2" color="textSecondary">
          {subtitle}
        </Typography>
      </Box>
      <Box sx={{ width: '100px', flexShrink: 0, color }}>
        <LinearProgress variant="determinate" color="inherit" value={value} />
      </Box>
    </Box>
  );
}

Skill.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

Skill.defaultProps = {
  sx: undefined,
};

export default Skill;
