import { Navigate, Route, Routes as ReactRouterRoutes, useMatch } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Skills from './pages/Skills';
import Meetings from './pages/Meetings';
import Coaches from './pages/Coaches';
import useCurrentUser from './hooks/useCurrentUser';
import Users from './pages/Users';

function Routes() {
  const user = useCurrentUser();
  const isLoginPage = useMatch('/login');
  const isAdminPage = useMatch('/admin/*');

  if (!user && !isLoginPage) {
    return <Navigate to="/login" />;
  }

  if (user && isLoginPage) {
    return <Navigate to="/" />;
  }

  if (isAdminPage && !user?.groups?.includes('Managers')) {
    return <Navigate to="/" />;
  }

  return (
    <ReactRouterRoutes>
      <Route path="/*" element={<Dashboard />} />
      <Route path="/" element={<Dashboard />} />
      <Route path="/login" element={<Login />} />
      <Route path="/admin/*" element={<Users />} />
      <Route path="/admin/users" element={<Users />} />
      <Route path="/admin/user/:userId" element={<Dashboard />} />
      <Route path="/admin/skills" element={<Skills />} />
      <Route path="/admin/coaches" element={<Coaches />} />
      <Route path="/admin/meetings" element={<Meetings />} />
    </ReactRouterRoutes>
  );
}

export default Routes;
