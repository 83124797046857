import { alpha, Box, FormControl, GlobalStyles, InputBase, InputLabel, Paper, styled, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { toast } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import Logo from '../ui/Logo';
import LogoEdf from '../ui/LogoEdf';
import useTranslate from '../hooks/useTranslate';
import ChangePasswordDialog from '../features/ChangePasswordDialog';

const globalStyle = <GlobalStyles styles={(theme) => ({ body: { background: theme.palette.primary.dark } })} />;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.text.secondary}`,
    fontSize: 14,
    lineHeight: '1em',
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

function Login() {
  const { t } = useTranslate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState(null);
  const [changePassword, setChangePassword] = useState(false);

  const handleChange = (e) => {
    if ('email' === e.target.name) {
      setEmail(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    Auth.signIn(email, password)
      .then((session) => {
        setUser(session);
        if ('NEW_PASSWORD_REQUIRED' === session.challengeName) {
          setChangePassword(true);
        }
      })
      .catch(() => toast.error(t('pages.Login.invalidLogin')))
      .finally(() => setLoading(false));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      {globalStyle}
      <Logo width={447} />
      <Paper sx={{ width: 560, padding: 5, mt: 4 }}>
        <form onSubmit={handleSubmit}>
          <Box sx={{ textAlign: 'center', mb: 1 }}>
            <LogoEdf width={152} />
          </Box>
          <Typography variant="h2">
            <FormattedMessage id="pages.Login.welcome" />
          </Typography>
          <FormControl variant="standard" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 3 }}>
            <InputLabel shrink htmlFor="email" sx={{ position: 'static', width: '120px', flexShrink: 0, transformOrigin: 'center left' }}>
              <FormattedMessage id="pages.Login.email" />
            </InputLabel>
            <BootstrapInput value={email} onChange={handleChange} id="email" name="email" type="email" fullWidth />
          </FormControl>
          <FormControl variant="standard" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} margin="normal">
            <InputLabel shrink htmlFor="password" sx={{ position: 'static', width: '120px', flexShrink: 0, transformOrigin: 'center left' }}>
              <FormattedMessage id="pages.Login.password" />
            </InputLabel>
            <BootstrapInput value={password} onChange={handleChange} id="password" name="password" type="password" fullWidth />
          </FormControl>
          <Box sx={{ textAlign: 'right', mt: 4 }}>
            <LoadingButton loading={loading} type="submit" color="primary" variant="contained">
              <FormattedMessage id="common.verb.validate" />
            </LoadingButton>
          </Box>
        </form>
      </Paper>
      {changePassword && <ChangePasswordDialog user={user} />}
    </Box>
  );
}

export default Login;
