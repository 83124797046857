import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';

function useMeetingsQuery(options = {}) {
  return useQuery(
    QUERY_CACHE_KEY.meetings(),
    async () => {
      const res = await API.graphql({
        query: gql`
          query ListMeetings($filter: ModelMeetingFilterInput, $limit: Int, $nextToken: String) {
            listMeetings(filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                id
                type
                mode
                status
                date
                subject
                message
                coach {
                  id
                  name
                }
                improvementAxisId
                owner
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        `,
      });
      return res.data.listMeetings.items.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    },
    options
  );
}

export default useMeetingsQuery;
