import { Box, Dialog, DialogContent, DialogTitle, Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import PropTypes from 'prop-types';
import CenteredCircularProgress from '../ui/atoms/CenteredCircularProgress';
import useImprovementAxisQuery from '../hooks/queries/useImprovementAxisQuery';

function ActImprovementAxisDialog({ id, onCreateActionPlan, onRequestMeeting, onClose }) {
  // Store a local open state to play exit animation
  const [open, setOpen] = useState(true);

  const { data: axis, isLoading } = useImprovementAxisQuery(id);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} TransitionProps={{ onExited: onClose }} maxWidth="sm" fullWidth>
      <DialogTitle>
        <FormattedMessage id="features.ActImprovementAxisDialog.title" />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ position: 'relative', minHeight: '150px' }}>
          {isLoading ? (
            <CenteredCircularProgress />
          ) : (
            <>
              <Typography variant="body2">
                <FormattedMessage id="features.ActImprovementAxisDialog.content" values={{ title: axis.title, name: axis.userWhoSuggest?.name || 'me' }} />
              </Typography>
              <Box sx={{ mx: 12, mt: 6 }}>
                <Button variant="outlined" fullWidth onClick={onCreateActionPlan}>
                  <FormattedMessage id="features.ActImprovementAxisDialog.createPlan" />
                </Button>
                <Button variant="contained" fullWidth sx={{ mt: 2 }} onClick={onRequestMeeting}>
                  <FormattedMessage id="features.ActImprovementAxisDialog.getCoaching" />
                </Button>
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

ActImprovementAxisDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onRequestMeeting: PropTypes.func.isRequired,
  onCreateActionPlan: PropTypes.func.isRequired,
};

export default ActImprovementAxisDialog;
