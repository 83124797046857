import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';
import { getMeeting } from '../../graphql/queries';

function useMeetingQuery(id, options = {}) {
  return useQuery(
    QUERY_CACHE_KEY.meeting(id),
    async () => {
      const res = await API.graphql({ query: getMeeting, variables: { id } });
      return res.data.getMeeting;
    },
    {
      enabled: !!id,
      ...options,
    }
  );
}

export default useMeetingQuery;
