import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Button, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import LogoutIcon from '../atoms/icons/LogoutIcon';
import useTranslate from '../../hooks/useTranslate';
import UsersIcon from '../atoms/icons/UsersIcon';
import CalendarIcon from '../atoms/icons/CalendarIcon';
import GraduateIcon from '../atoms/icons/GraduateIcon';
import BriefcaseIcon from '../atoms/icons/BriefcaseIcon';
import ADMIN_PAGE from '../../constants/AdminPage';

const DRAWER_WIDTH = 380;

function AdminLayout({ children, selected }) {
  const { t } = useTranslate();

  const handleLogout = () => Auth.signOut();

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Drawer
          sx={{
            width: DRAWER_WIDTH,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: DRAWER_WIDTH,
              boxSizing: 'border-box',
              bgcolor: 'primary.dark',
              border: 'none',
              color: 'common.white',
              px: 4,
              pt: 6,
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Box sx={{ height: '100%' }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h4" sx={{ mt: 1 }}>
                <FormattedMessage id="ui.AdminLayout.title" />
              </Typography>
            </Box>
            <List sx={{ mt: 3 }}>
              <ListItem button component={Link} to="/admin/users" selected={selected === ADMIN_PAGE.users}>
                <ListItemIcon>
                  <UsersIcon color="#ffffff" />
                </ListItemIcon>
                <ListItemText primary={t('ui.AdminLayout.users')} />
              </ListItem>
              <ListItem button component={Link} to="/admin/meetings" selected={selected === ADMIN_PAGE.meetings}>
                <ListItemIcon>
                  <CalendarIcon color="#ffffff" />
                </ListItemIcon>
                <ListItemText primary={t('ui.AdminLayout.meetings')} />
              </ListItem>
              <ListItem button component={Link} to="/admin/coaches" selected={selected === ADMIN_PAGE.coaches}>
                <ListItemIcon>
                  <GraduateIcon color="#ffffff" />
                </ListItemIcon>
                <ListItemText primary={t('ui.AdminLayout.coaches')} />
              </ListItem>
              <ListItem button component={Link} to="/admin/skills" selected={selected === ADMIN_PAGE.skills}>
                <ListItemIcon>
                  <BriefcaseIcon color="#ffffff" />
                </ListItemIcon>
                <ListItemText primary={t('ui.AdminLayout.skills')} />
              </ListItem>
            </List>
          </Box>
          <Box sx={{ textAlign: 'center', mt: 4, pb: 1 }}>
            <Button component={Link} to="/" variant="contained" color="primary" sx={{ mt: 1 }} fullWidth>
              <FormattedMessage id="ui.AdminLayout.backToDashboard" />
            </Button>
            <Button onClick={handleLogout} startIcon={<LogoutIcon size={22} />} variant="text" color="white" sx={{ mt: 4, px: 3, py: '12px' }}>
              <FormattedMessage id="common.verb.logout" />
            </Button>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, pt: 6, width: `calc(100% - ${DRAWER_WIDTH}px)` }}>
          {children}
        </Box>
      </Box>
    </>
  );
}

AdminLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  selected: PropTypes.string.isRequired,
};

export default AdminLayout;
