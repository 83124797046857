import SkillDialog from './SkillDialog.json';
import UserSkillsDialog from './UserSkillsDialog.json';
import ImprovementAxes from './ImprovementAxes.json';
import PlanMeetingDialog from './PlanMeetingDialog.json';
import RequestMeetingDialog from './RequestMeetingDialog.json';
import CoachDialog from './CoachDialog.json';
import ActImprovementAxisDialog from './ActImprovementAxisDialog.json';
import CreateActionPlanDialog from './CreateActionPlanDialog.json';
import ChangePasswordDialog from './ChangePasswordDialog.json';

export default {
  SkillDialog,
  UserSkillsDialog,
  ImprovementAxes,
  PlanMeetingDialog,
  RequestMeetingDialog,
  CoachDialog,
  ActImprovementAxisDialog,
  CreateActionPlanDialog,
  ChangePasswordDialog,
};
