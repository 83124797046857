import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { string } from 'yup';
import { toast } from 'react-hot-toast';
import { object } from '../utils/yup';
import useTranslate from '../hooks/useTranslate';
import ownerContext from '../contexts/ownerContext';
import TextField from '../ui/atoms/form/TextField';
import useCreateActionPlanMutation from '../hooks/mutations/useCreateActionPlanMutation';
import useUpdateImprovementAxisMutation from '../hooks/mutations/useUpdateImprovementAxisMutation';

const validationSchema = object().shape({
  title: string().required(),
});

function CreateActionPlanDialog({ improvementAxisId, onClose }) {
  const { t } = useTranslate();
  const owner = useContext(ownerContext);

  const { mutateAsync: update, isLoading: updating } = useUpdateImprovementAxisMutation(owner.userId);
  const { mutateAsync: create, isLoading: creating } = useCreateActionPlanMutation(owner.userId);

  const isLoading = updating || creating;

  // Store a local open state to play exit animation
  const [open, setOpen] = useState(true);

  const form = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      await update({ id: improvementAxisId, archivedAt: new Date().toISOString() });
      await create({ improvementAxisId, active: true, ...values });
      setOpen(false);
      toast.success(t('features.CreateActionPlanDialog.success'));
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} TransitionProps={{ onExited: onClose }} maxWidth="sm" fullWidth>
      <form onSubmit={form.handleSubmit}>
        <DialogTitle>
          <Typography sx={{ fontWeight: 600, fontSize: 24 }}>
            <FormattedMessage id="features.CreateActionPlanDialog.title" />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
            <FormattedMessage id="features.CreateActionPlanDialog.fields.title" />
          </Typography>
          <TextField value={form.values.title} id="title" name="title" fullWidth size="small" variant="outlined" onChange={form.handleChange} multiline sx={{ mr: 2 }} />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            <FormattedMessage id="features.CreateActionPlanDialog.submit" />
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

CreateActionPlanDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  improvementAxisId: PropTypes.string,
};

CreateActionPlanDialog.defaultProps = {
  improvementAxisId: undefined,
};

export default CreateActionPlanDialog;
