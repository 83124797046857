import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { object, string } from '../utils/yup';
import TextField from '../ui/atoms/form/TextField';
import useTranslate from '../hooks/useTranslate';
import CenteredCircularProgress from '../ui/atoms/CenteredCircularProgress';
import useCreateCoachMutation from '../hooks/mutations/useCreateCoachMutation';
import useUpdateCoachMutation from '../hooks/mutations/useUpdateCoachMutation';
import useCoachQuery from '../hooks/queries/useCoachQuery';

const validationSchema = object().shape({
  name: string().required(),
});

function CoachDialog({ id, onClose }) {
  const { t } = useTranslate();

  // Store a local open state to play exit animation
  const [open, setOpen] = useState(true);

  const { data: coach, isLoading } = useCoachQuery(id);
  const { mutateAsync: create, isLoading: creating } = useCreateCoachMutation();
  const { mutateAsync: update, isLoading: updating } = useUpdateCoachMutation(id);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: coach?.name || '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (id) {
        await update({
          id,
          ...values,
        });
      } else {
        await create(values);
      }

      setOpen(false);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} TransitionProps={{ onExited: onClose }} maxWidth="sm" fullWidth>
      <form onSubmit={form.handleSubmit}>
        <DialogTitle>
          <FormattedMessage id="features.CoachDialog.title" values={{ edit: !!id }} />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ position: 'relative', minHeight: '300px' }}>
            {isLoading ? (
              <CenteredCircularProgress />
            ) : (
              <>
                <TextField
                  error={form.touched.name && form.errors.name}
                  id="name"
                  name="name"
                  label={t('features.CoachDialog.fields.name')}
                  fullWidth
                  variant="outlined"
                  value={form.values.name}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  sx={{ mb: 3, mt: 1 }}
                />
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="grey" onClick={handleClose}>
            <FormattedMessage id="common.verb.cancel" />
          </Button>
          <LoadingButton type="submit" variant="contained" loading={creating || updating}>
            <FormattedMessage id="common.verb.validate" />
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

CoachDialog.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

CoachDialog.defaultProps = {
  id: undefined,
};

export default CoachDialog;
