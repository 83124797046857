/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

function EditIcon({ size, color }) {
  return (
    <SvgIcon size={size} color={color} viewBox="0 0 25 25">
      <path
        d="M4.16675 14.5834C4.17066 14.3087 4.28298 14.0466 4.47925 13.8542L15.9376 2.39591C16.1323 2.20505 16.3941 2.09814 16.6667 2.09814C16.9394 2.09814 17.2012 2.20505 17.3959 2.39591L20.5209 5.52091C20.7118 5.71563 20.8187 5.97742 20.8187 6.25008C20.8187 6.52274 20.7118 6.78453 20.5209 6.97925L9.06258 18.4376C8.87026 18.6338 8.60817 18.7462 8.33342 18.7501H5.20841C4.93215 18.7501 4.6672 18.6403 4.47185 18.445C4.27649 18.2496 4.16675 17.9847 4.16675 17.7084V14.5834V14.5834Z"
        fill="currentColor"
      />
      <path
        d="M21.8749 20.8333H3.12492C2.54962 20.8333 2.08325 21.2996 2.08325 21.8749C2.08325 22.4502 2.54962 22.9166 3.12492 22.9166H21.8749C22.4502 22.9166 22.9166 22.4502 22.9166 21.8749C22.9166 21.2996 22.4502 20.8333 21.8749 20.8333Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

EditIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

EditIcon.defaultProps = {
  size: 24,
  color: undefined,
};

export default EditIcon;
