/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

function CalendarIcon({ size, color }) {
  return (
    <SvgIcon size={size} color={color} viewBox="0 0 24 24">
      <path
        strokeLinecap="round"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </SvgIcon>
  );
}

CalendarIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

CalendarIcon.defaultProps = {
  size: 24,
  color: undefined,
};

export default CalendarIcon;
