import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';
import * as queries from '../../graphql/queries';

function useSkillsQuery(options = {}) {
  return useQuery(
    QUERY_CACHE_KEY.skills(),
    async () => {
      const res = await API.graphql({ query: queries.listSkills });
      return res.data.listSkills.items.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
    },
    options
  );
}

export default useSkillsQuery;
