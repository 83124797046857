import { mixed, string, number, bool, boolean, date, object, array, ref, lazy, reach, isSchema, addMethod, setLocale, ValidationError } from 'yup';

setLocale({
  mixed: {
    required: { key: 'errors.validation.required' },
    oneOf: { key: 'errors.validation.invalid' },
  },
  number: {
    positive: { key: 'errors.validation.number.positive' },
    min: ({ min }) => ({ key: 'errors.validation.number.min', values: { min } }),
  },
  array: {
    min: ({ min }) => ({ key: 'errors.validation.array.min', values: { min } }),
  },
  string: {
    min: ({ min }) => ({ key: 'errors.validation.string.min', values: { min } }),
    max: ({ max }) => ({ key: 'errors.validation.string.min', values: { max } }),
    email: { key: 'errors.validation.string.email' },
  },
});

export { mixed, string, number, bool, boolean, date, object, array, ref, lazy, reach, isSchema, addMethod, setLocale, ValidationError };
