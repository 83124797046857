/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

function TrashIcon({ size, color }) {
  return (
    <SvgIcon size={size} color={color} viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        fill="none"
        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
      />
    </SvgIcon>
  );
}

TrashIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

TrashIcon.defaultProps = {
  size: 24,
  color: undefined,
};

export default TrashIcon;
