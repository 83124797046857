/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

function ChevronRightIcon({ size, color }) {
  return (
    <SvgIcon size={size} color={color} viewBox="0 0 10 18">
      <g clipPath="url(#clip0_33_705)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.60273 7.86471L2.42474 1.10778C1.87074 0.526246 0.97274 0.526246 0.419406 1.10778C-0.134594 1.6824 -0.134594 2.61009 0.419406 3.18471L6.59008 9.00011L0.419406 14.8155C-0.134594 15.3901 -0.134594 16.3178 0.419406 16.8924C0.97274 17.474 1.87074 17.474 2.42474 16.8924L9.60273 10.1355C9.90273 9.82397 10.0347 9.40857 10.0101 9.00011C10.0347 8.59165 9.90273 8.17625 9.60273 7.86471Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_33_705">
          <rect width="10" height="18" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

ChevronRightIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

ChevronRightIcon.defaultProps = {
  size: 24,
  color: undefined,
};

export default ChevronRightIcon;
