const MEETING_SUBJECT = [
  'ANO',
  'FACTU',
  'RAF',
  'Inversions PDL',
  'ANOFACT',
  'Transflux',
  'Traitement Courrier',
  'Traitement Mail',
  'Réclamations',
  'Discours FO',
  'Validations',
  'DAT',
];

export default MEETING_SUBJECT;
