import { Box, CircularProgress, IconButton, Tab, Tabs, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-hot-toast';
import AdminLayout from '../ui/templates/AdminLayout';
import ConfirmDialog from '../ui/organisms/ConfirmDialog';
import useTranslate from '../hooks/useTranslate';
import CenteredCircularProgress from '../ui/atoms/CenteredCircularProgress';
import MEETING_STATUS from '../constants/MeetingStatus';
import useMeetingsQuery from '../hooks/queries/useMeetingsQuery';
import CrossIcon from '../ui/atoms/icons/CrossIcon';
import ClockIcon from '../ui/atoms/icons/ClockIcon';
import useUpdateMeetingMutation from '../hooks/mutations/useUpdateMeetingMutation';
import useUsersQuery from '../hooks/queries/useUsersQuery';
import PlanMeetingDialog from '../features/PlanMeetingDialog';
import ADMIN_PAGE from '../constants/AdminPage';

function Meetings() {
  const { t, formatDate } = useTranslate();

  const [tab, setTab] = useState(MEETING_STATUS.pending);

  const [planId, setPlanId] = useState(null);
  const [cancelId, setCancelId] = useState(null);

  const { data: meetings, isLoading: meetingsLoading } = useMeetingsQuery();
  const { data: users, isLoading: usersLoading } = useUsersQuery();

  const { mutate, isLoading: deleting } = useUpdateMeetingMutation({
    onSuccess: () => toast.success(t('pages.Meetings.success')),
    onError: () => toast.error(t('errors.api.default')),
  });

  const pendingCount = meetings?.filter((item) => item.status === MEETING_STATUS.pending)?.length ?? 0;
  const items = meetings?.filter((item) => item.status === tab);

  const handleCancel = () => {
    mutate({
      id: cancelId,
      status: MEETING_STATUS.canceled,
      date: null,
    });
  };

  const isLoading = meetingsLoading || usersLoading;

  return (
    <AdminLayout selected={ADMIN_PAGE.meetings}>
      <Typography variant="h2" sx={{ mb: 4 }}>
        <FormattedMessage id="pages.Meetings.title" />
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', backgroundColor: 'common.white' }}>
        <Tabs value={tab} onChange={(_, type) => setTab(type)}>
          <Tab
            label={`${t('constants.MeetingStatus', { value: MEETING_STATUS.pending })} (${pendingCount})`}
            value={MEETING_STATUS.pending}
            sx={{ width: 'calc(100% / 3)', maxWidth: 'none' }}
          />
          <Tab label={t('constants.MeetingStatus', { value: MEETING_STATUS.planned })} value={MEETING_STATUS.planned} sx={{ width: 'calc(100% / 3)', maxWidth: 'none' }} />
          <Tab label={t('constants.MeetingStatus', { value: MEETING_STATUS.canceled })} value={MEETING_STATUS.canceled} sx={{ width: 'calc(100% / 3)', maxWidth: 'none' }} />
        </Tabs>
      </Box>
      {isLoading ? (
        <Box sx={{ height: '500px', width: '100%', position: 'relative' }}>
          <CenteredCircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="pages.Meetings.columns.owner" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="pages.Meetings.columns.type" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="pages.Meetings.columns.mode" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="pages.Meetings.columns.subject" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="pages.Meetings.columns.coach" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="pages.Meetings.columns.createdAt" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="pages.Meetings.columns.date" />
                </TableCell>
                {tab !== MEETING_STATUS.canceled && (
                  <TableCell align="right">
                    <FormattedMessage id="common.actions" />
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{users?.find((user) => user.username === item.owner)?.name ?? t('common.null')}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>
                    <FormattedMessage id="constants.MeetingMode" values={{ value: item.mode }} />
                  </TableCell>
                  <TableCell>{item.subject}</TableCell>
                  <TableCell>{item.coach?.name || t('common.null')}</TableCell>
                  <TableCell>{formatDate(item.createdAt, { day: '2-digit', month: 'short', year: '2-digit', hour: '2-digit', minute: '2-digit' })}</TableCell>
                  <TableCell>
                    {item.date ? formatDate(item.date, { day: '2-digit', month: 'short', year: '2-digit', hour: '2-digit', minute: '2-digit' }) : t('common.null')}
                  </TableCell>
                  {item.status !== MEETING_STATUS.canceled && (
                    <TableCell align="right">
                      <>
                        <IconButton color="success" onClick={() => setPlanId(item.id)}>
                          <ClockIcon size={20} />
                        </IconButton>
                        <IconButton color="error" onClick={() => setCancelId(item.id)}>
                          {deleting ? <CircularProgress color="inherit" size={20} /> : <CrossIcon size={20} />}
                        </IconButton>
                      </>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {planId && <PlanMeetingDialog id={planId} onClose={() => setPlanId(null)} />}
      {cancelId && (
        <ConfirmDialog
          title={t('pages.Meetings.confirmCancel.title')}
          content={t('pages.Meetings.confirmCancel.content')}
          confirmLabel={t('common.verb.delete')}
          onConfirm={handleCancel}
          onExited={() => setCancelId(null)}
        />
      )}
    </AdminLayout>
  );
}

export default Meetings;
