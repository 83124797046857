/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

function SmileIcon({ size, color }) {
  return (
    <SvgIcon size={size} color={color} viewBox="0 0 22 23">
      <path
        d="M1.5 21H12V22.5H0V0H12V1.5H1.5V21ZM21.1758 11.25L15.4688 16.9688L14.5312 16.0312L18.5742 12H6V10.5H18.5742L14.5312 6.46875L15.4688 5.53125L21.1758 11.25Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

SmileIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

SmileIcon.defaultProps = {
  size: 24,
  color: undefined,
};

export default SmileIcon;
