import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';

function ConfirmDialog({ title, content, onConfirm, onCancel, onExited, confirmLabel, color }) {
  // Store a local open state to play exit animation
  const [open, setOpen] = useState(true);

  const handleConfirm = (e) => {
    setOpen(false);
    if ('function' === typeof onConfirm) {
      onConfirm(e);
    }
  };

  const handleCancel = (e) => {
    setOpen(false);
    if ('function' === typeof onCancel) {
      onCancel(e);
    }
  };

  return (
    <Dialog open={open} TransitionProps={{ onExited }}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {content && (
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button color="grey" onClick={handleCancel}>
          <FormattedMessage id="common.verb.cancel" />
        </Button>
        <Button color={color} onClick={handleConfirm} autoFocus>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  content: PropTypes.string,
  onConfirm: PropTypes.func,
  onExited: PropTypes.func,
  onCancel: PropTypes.func,
  color: PropTypes.oneOf(['success', 'danger', 'primary']),
};

ConfirmDialog.defaultProps = {
  content: undefined,
  onConfirm: undefined,
  onExited: undefined,
  onCancel: undefined,
  color: 'danger',
};

export default ConfirmDialog;
