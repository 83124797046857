import { Avatar, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function UsernameWithAvatar({ avatar, name, sx }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
      <Avatar src={avatar} sx={{ width: 23, height: 23, mr: '4px' }} />
      <Typography variant="body2">{name}</Typography>
    </Box>
  );
}

UsernameWithAvatar.propTypes = {
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

UsernameWithAvatar.defaultProps = {
  sx: undefined,
};

export default UsernameWithAvatar;
