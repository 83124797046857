import { Box, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import AddImprovementAxis from './AddImprovementAxis';
import ownerContext from '../../contexts/ownerContext';
import useImprovementAxesQueryByOwner from '../../hooks/queries/useImprovementAxesQueryByOwner';
import ActImprovementAxisDialog from '../ActImprovementAxisDialog';
import RequestMeetingDialog from '../RequestMeetingDialog';
import CreateActionPlanDialog from '../CreateActionPlanDialog';
import CenteredCircularProgress from '../../ui/atoms/CenteredCircularProgress';
import ImprovementAxis from './ImprovementAxis';

function ImprovementAxes({ sx }) {
  const owner = useContext(ownerContext);
  const { data: improvementAxes, isLoading } = useImprovementAxesQueryByOwner(owner.userId, {
    select: (items) => items.filter((item) => !item.archivedAt),
  });

  const [openedAxisId, setOpenedAxisId] = useState(null);
  const [requestMeetingId, setRequestMeetingId] = useState(null);
  const [createActionPlanId, setCreateActionPlanId] = useState(null);

  const handleRequestMeeting = () => {
    setRequestMeetingId(openedAxisId);
    setOpenedAxisId(null);
  };

  const handleCreateActionPlan = () => {
    setCreateActionPlanId(openedAxisId);
    setOpenedAxisId(null);
  };

  return (
    <Box sx={sx}>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Mes axes d’amélioration
      </Typography>
      <Box>
        {isLoading ? (
          <Box sx={{ position: 'relative', minHeight: 242 }}>
            <CenteredCircularProgress />
          </Box>
        ) : (
          improvementAxes?.map((axis) => <ImprovementAxis key={axis.id} axis={axis} onOpen={() => setOpenedAxisId(axis.id)} />)
        )}
      </Box>
      <AddImprovementAxis />
      {openedAxisId && (
        <ActImprovementAxisDialog onCreateActionPlan={handleCreateActionPlan} onRequestMeeting={handleRequestMeeting} onClose={() => setOpenedAxisId(null)} id={openedAxisId} />
      )}
      {requestMeetingId && <RequestMeetingDialog improvementAxisId={requestMeetingId} onClose={() => setRequestMeetingId(null)} />}
      {createActionPlanId && <CreateActionPlanDialog improvementAxisId={createActionPlanId} onClose={() => setCreateActionPlanId(null)} />}
    </Box>
  );
}

ImprovementAxes.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

ImprovementAxes.defaultProps = {
  sx: undefined,
};

export default ImprovementAxes;
