function DoubleListeningIllustration() {
  return (
    <svg width="270" height="157" viewBox="0 0 270 157" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.31">
        <path
          opacity="0.31"
          d="M44.3501 154C29.0112 153.404 13.9512 147.897 6.59066 138.428C-0.218539 129.673 -1.48073 116.107 1.56182 106.369C4.11942 98.1866 11.4268 91.1115 19.7573 85.1155C36.591 73.0053 58.2807 64.222 72.5103 50.5608C80.4156 42.9688 85.6769 34.1686 93.2833 26.4248C108.064 11.37 132.551 0.687246 158.579 0.0297583C184.606 -0.62773 211.258 9.65606 222.073 26.2899C231.38 40.603 228.949 57.9449 240.408 71.4656C245.676 77.6808 253.522 82.6148 259.308 88.5996C269.598 99.2487 272.707 113.163 267.559 125.514C262.41 137.866 249.091 148.403 232.244 153.466"
          fill="#0089FF"
        />
      </g>
      <g opacity="0.31">
        <path
          opacity="0.31"
          d="M208.535 152.851C220.062 152.35 231.382 147.721 236.916 139.767C242.035 132.408 242.988 121.008 240.696 112.825C238.778 105.953 233.279 99.9986 227.021 94.9613C214.37 84.7863 198.06 77.3987 187.371 65.9196C181.428 59.5423 174.319 44.8028 168.598 38.2966C157.486 25.6423 144.291 17.3375 124.823 15.2595C99.7285 12.5868 83.0678 31.5395 74.9415 45.5266C67.9465 57.5503 69.7722 72.132 61.1591 83.4893C57.1997 88.713 51.3001 92.8618 46.9542 97.892C39.2217 106.842 36.8804 118.536 40.7538 128.911C44.6273 139.287 54.6366 148.144 67.3021 152.4"
          fill="#C5E1F9"
        />
      </g>
      <g opacity="0.38">
        <g opacity="0.38">
          <path
            opacity="0.38"
            d="M28.7901 153.654C28.4035 151.117 27.3582 148.753 26.1697 146.495C25.5396 145.299 24.8738 144.131 24.2151 142.949C23.4991 141.659 22.7688 140.376 22.0313 139.101C20.6209 136.679 19.1388 134.293 17.6066 131.95C16.0601 129.592 14.4564 127.285 12.7953 125.013C11.1342 122.742 9.40875 120.513 7.62598 118.335C7.41118 118.07 7.19639 117.805 6.97444 117.547C6.81693 117.361 6.51622 117.583 6.67373 117.769C8.45651 119.919 10.182 122.118 11.8431 124.361C13.5113 126.604 15.1151 128.89 16.6687 131.211C18.2152 133.54 19.7044 135.905 21.1292 138.305C22.5826 140.763 23.986 143.25 25.3535 145.757C26.5635 147.979 27.7162 150.293 28.2532 152.787C28.3176 153.088 28.3749 153.396 28.4178 153.697C28.4536 153.94 28.8259 153.897 28.7901 153.654Z"
            fill="#00B7D8"
          />
        </g>
        <path
          opacity="0.38"
          d="M7.5184 118.701C5.94326 118.328 4.57575 117.146 3.99581 115.627C3.7667 115.018 3.70226 114.222 4.2106 113.814C4.58291 113.513 5.14853 113.556 5.56379 113.807C5.97905 114.05 6.26544 114.459 6.51604 114.867C7.25349 116.085 7.75467 117.439 7.98378 118.844"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M9.19379 120.506C8.65681 121.115 7.79048 121.294 6.98143 121.323C6.40866 121.345 5.82872 121.309 5.30606 121.087C4.7834 120.864 4.32517 120.42 4.20346 119.861C4.18198 119.775 4.17482 119.689 4.20346 119.603C4.2321 119.525 4.28938 119.46 4.35381 119.403C4.66884 119.123 5.10558 119.023 5.52801 119.052C5.94327 119.08 6.34422 119.224 6.73084 119.381C7.61865 119.747 8.4635 120.212 9.24391 120.757"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M11.9787 124.462C11.8427 124.727 11.5993 124.913 11.3558 125.085C10.7043 125.558 9.98833 126.052 9.17928 126.038C8.77117 126.031 8.37739 125.895 8.00508 125.73C7.42514 125.465 6.8452 125.071 6.63757 124.469C6.56597 124.268 6.5445 124.046 6.62326 123.845C6.72349 123.602 6.95976 123.444 7.21035 123.387C7.46094 123.322 7.72585 123.351 7.9836 123.38C9.55158 123.595 10.5325 123.845 11.9787 124.462Z"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M15.3799 128.904C15.43 128.983 15.3584 129.09 15.294 129.155C14.6567 129.778 13.905 130.287 13.053 130.559C12.2081 130.825 11.263 130.839 10.4397 130.495C10.0674 130.337 9.70221 130.086 9.56618 129.707C9.39434 129.219 9.65925 128.653 10.0888 128.36C10.5113 128.066 11.0554 127.994 11.5709 127.994C12.9599 128.008 14.2988 128.524 15.5947 129.033"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M18.745 133.812C18.5589 134.185 18.1937 134.436 17.8429 134.658C16.9766 135.203 16.0387 135.668 15.022 135.797C14.0053 135.919 12.9027 135.647 12.2154 134.894C11.9361 134.594 11.7357 134.171 11.8502 133.777C11.9361 133.476 12.1939 133.261 12.4588 133.103C13.5542 132.458 14.9361 132.637 16.1675 132.953C16.9408 133.146 17.6997 133.383 18.4372 133.662"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M22.3533 140.025C20.7853 141.516 18.5371 142.254 16.3892 141.996C15.5658 141.895 14.6208 141.523 14.4346 140.713C14.3129 140.19 14.5706 139.624 14.9787 139.273C15.3868 138.922 15.9238 138.75 16.4536 138.664C18.3295 138.363 20.2125 139.001 22.0096 139.631"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M12.2221 124.326C11.8641 123.007 12.2221 121.617 12.6159 120.313C12.709 119.998 12.8522 119.632 13.1743 119.539C13.4607 119.453 13.7614 119.639 13.9476 119.861C14.2841 120.256 14.413 120.8 14.37 121.316C14.3342 121.832 14.1409 122.326 13.8903 122.785C13.4965 123.502 12.9452 124.139 12.2436 124.562"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M9.08636 120.413C8.72838 119.095 9.08636 117.705 9.48015 116.401C9.57322 116.085 9.71642 115.72 10.0386 115.627C10.325 115.541 10.6257 115.727 10.8119 115.949C11.1484 116.343 11.2772 116.888 11.2343 117.404C11.1985 117.92 11.0052 118.414 10.7546 118.873C10.3608 119.589 9.80949 120.227 9.10784 120.65"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M15.3224 128.847C14.8069 127.192 15.1005 125.293 16.1029 123.874C16.3248 123.559 16.6184 123.243 16.9978 123.208C17.3272 123.172 17.6565 123.365 17.857 123.638C18.0503 123.91 18.1291 124.247 18.1505 124.583C18.2007 125.35 17.9572 126.124 17.5706 126.79C17.184 127.457 16.6542 128.03 16.0814 128.553C15.8523 128.761 15.6017 128.976 15.2938 128.99"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M18.4941 133.727C18.1934 132.15 18.7447 130.538 19.3748 129.055C19.6182 128.481 19.9332 127.865 20.5132 127.643C21.2363 127.371 22.0883 127.915 22.3532 128.639C22.6181 129.37 22.4177 130.194 22.0597 130.882C21.3795 132.179 20.1623 133.132 18.8163 133.712"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M22.0385 139.703C21.8309 138.492 22.1674 137.238 22.7473 136.156C23.3273 135.074 24.1291 134.135 24.931 133.204C25.3177 132.752 25.7974 132.258 26.3916 132.301C26.7997 132.329 27.1505 132.616 27.3439 132.981C27.5372 133.347 27.5873 133.762 27.5801 134.164C27.5587 136.27 26.1482 138.205 24.3153 139.237C23.5349 139.674 22.6113 139.982 21.7378 139.76"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M26.4631 147.076C26.5562 147.155 26.4631 147.305 26.3629 147.384C24.666 148.724 22.289 149.168 20.2198 148.516C19.826 148.394 19.4323 148.23 19.1172 147.957C18.6375 147.542 18.3869 146.911 18.2294 146.295C18.1936 146.152 18.1578 145.994 18.2008 145.851C18.2581 145.664 18.4371 145.543 18.6089 145.449C19.8189 144.783 21.3081 144.726 22.6398 145.091C23.9715 145.457 25.1744 146.209 26.2412 147.09"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M26.3845 147.456C26.1267 146.503 26.2198 145.485 26.4991 144.532C26.7783 143.586 27.2293 142.705 27.7019 141.831C28.0456 141.193 28.4179 140.534 29.0193 140.118C29.6135 139.703 30.5013 139.617 31.0312 140.111C31.5467 140.598 31.5467 141.415 31.425 142.11C31.1887 143.479 30.6374 144.826 29.6851 145.836C28.7257 146.847 27.3296 147.477 25.9478 147.334"
          fill="#00B7D8"
        />
      </g>
      <path
        d="M183.805 152.106C192.189 148 197.988 140.183 202.463 132.143C210.582 117.554 215.479 100.679 212.759 84.2917C212.229 81.0744 211.062 77.4845 208.005 76.1661C206.566 75.5427 204.883 75.557 203.372 75.9941C200.387 76.8611 198.074 79.2687 196.685 81.9916C195.296 84.7073 194.695 87.7383 194.144 90.7335C190.198 111.922 187.413 133.483 179.86 153.718"
        fill="#00B7D8"
      />
      <path
        opacity="0.11"
        d="M182.352 152.443C188.838 149.426 196.256 140.175 199.864 133.999C206.401 122.792 211.248 111.162 209.573 98.3074C209.244 95.7851 208.442 92.9547 206.151 91.8584C205.069 91.3425 203.788 91.3138 202.628 91.622C200.33 92.231 198.511 94.0654 197.387 96.1505C196.263 98.2357 195.733 100.593 195.239 102.915C191.724 119.367 189.082 136.141 182.838 151.762"
        fill="#345D95"
      />
      <path
        d="M205.141 84.9867C204.446 90.0599 203.723 95.1259 202.85 100.17C201.983 105.172 200.967 110.152 199.692 115.067C198.511 119.61 197.101 124.074 195.303 128.424C193.528 132.716 191.43 136.886 189.053 140.899C186.726 144.819 184.12 148.595 181.185 152.113C180.827 152.543 180.469 152.966 180.103 153.381C180.025 153.467 180.161 153.596 180.232 153.51C183.218 150.078 185.881 146.388 188.273 142.54C190.736 138.563 192.912 134.414 194.774 130.144C196.642 125.866 198.139 121.466 199.377 116.981C200.738 112.036 201.812 107.028 202.721 101.99C203.63 96.9459 204.382 91.8728 205.084 86.7924C205.17 86.1905 205.248 85.5958 205.327 84.9939C205.349 84.8721 205.155 84.8721 205.141 84.9867Z"
        fill="white"
      />
      <path
        d="M187.077 151.196C192.89 152.629 200.916 153.74 206.616 154.076C217.348 154.7 230.493 154.313 239.021 147.749C241.784 145.621 244.197 142.139 243.066 138.835C242.35 136.736 240.316 135.303 238.176 134.737C236.035 134.171 233.772 134.35 231.574 134.601C215.694 136.428 200.279 142.174 187.077 151.196Z"
        fill="#00B7D8"
      />
      <path
        opacity="0.11"
        d="M189.489 150.673C194 151.712 200.229 152.522 204.646 152.758C212.973 153.202 223.183 152.894 229.806 148.065C231.954 146.502 233.829 143.937 232.956 141.515C232.405 139.975 230.83 138.921 229.168 138.513C227.507 138.105 225.753 138.234 224.042 138.42C211.727 139.788 199.749 144.03 189.489 150.673Z"
        fill="#345D95"
      />
      <path
        d="M234.259 141.83C231.274 142.504 228.295 143.178 225.31 143.844C222.26 144.525 219.202 145.191 216.145 145.843C213.066 146.495 209.981 147.133 206.888 147.742C203.816 148.344 200.737 148.91 197.652 149.44C194.609 149.963 191.559 150.436 188.501 150.859C188.129 150.909 187.764 150.959 187.392 151.009C187.263 151.024 187.263 151.239 187.399 151.217C190.442 150.816 193.477 150.35 196.506 149.849C199.585 149.333 202.649 148.774 205.713 148.186C208.806 147.591 211.892 146.961 214.971 146.309C218.05 145.657 221.121 144.99 224.193 144.31C227.2 143.643 230.214 142.963 233.221 142.282C233.586 142.203 233.951 142.117 234.317 142.038C234.453 142.002 234.388 141.802 234.259 141.83Z"
        fill="white"
      />
      <path
        d="M184.506 150.314C196.685 149.677 210.274 142.44 220.033 135.116C226.455 130.294 232.799 125.178 237.538 118.7C241.426 113.391 244.147 107.236 245.471 100.794C246.338 96.5661 246.187 91.321 242.55 89.0137C240.581 87.7669 238.018 87.7669 235.82 88.5408C233.629 89.3218 231.753 90.7979 230.057 92.3887C225.209 96.9244 221.494 102.506 217.72 107.966C207.897 122.19 195.697 137.137 184.506 150.314Z"
        fill="#00B7D8"
      />
      <path
        opacity="0.11"
        d="M187.792 148.595C197.2 148.129 207.697 142.798 215.229 137.402C220.19 133.855 225.088 130.086 228.753 125.314C231.753 121.402 233.858 116.873 234.882 112.122C235.548 109.013 235.433 105.143 232.627 103.445C231.109 102.528 229.126 102.528 227.429 103.101C225.732 103.674 224.286 104.763 222.975 105.931C219.231 109.27 216.367 113.383 213.446 117.403C205.856 127.886 196.434 138.893 187.792 148.595Z"
        fill="#345D95"
      />
      <path
        d="M237.818 99.2102C234.252 103.574 230.679 107.938 227.114 112.302C223.949 116.178 220.799 120.069 217.57 123.888C214.592 127.406 211.527 130.853 208.27 134.106C205.04 137.331 201.611 140.362 197.902 143.027C194.222 145.671 190.263 147.943 186.053 149.619C185.537 149.827 185.015 150.021 184.492 150.214C184.363 150.257 184.42 150.465 184.549 150.415C188.802 148.881 192.812 146.76 196.549 144.231C200.308 141.68 203.788 138.735 207.06 135.589C210.396 132.372 213.518 128.947 216.532 125.429C219.718 121.724 222.789 117.919 225.882 114.136C229.462 109.758 233.042 105.38 236.622 101.002C237.073 100.45 237.517 99.9052 237.968 99.3535C238.047 99.2603 237.896 99.1099 237.818 99.2102Z"
        fill="white"
      />
      <g opacity="0.38">
        <g opacity="0.38">
          <path
            opacity="0.38"
            d="M223.799 138.069C226.248 137.316 228.438 135.933 230.493 134.429C231.582 133.633 232.641 132.802 233.715 131.978C234.882 131.075 236.049 130.172 237.195 129.248C239.386 127.492 241.526 125.687 243.617 123.824C245.722 121.953 247.77 120.026 249.774 118.048C251.779 116.071 253.727 114.036 255.624 111.958C255.853 111.707 256.082 111.449 256.311 111.198C256.476 111.019 256.211 110.747 256.046 110.933C254.178 113.011 252.259 115.039 250.283 117.016C248.307 118.994 246.288 120.922 244.211 122.792C242.135 124.662 240.016 126.482 237.846 128.245C235.634 130.043 233.379 131.792 231.095 133.511C229.076 135.038 226.956 136.507 224.572 137.409C224.286 137.517 223.992 137.617 223.692 137.71C223.47 137.775 223.57 138.14 223.799 138.069Z"
            fill="#00B7D8"
          />
        </g>
        <path
          opacity="0.38"
          d="M255.252 111.908C255.395 110.295 256.361 108.762 257.772 107.974C258.337 107.658 259.118 107.479 259.59 107.924C259.941 108.253 259.977 108.819 259.798 109.263C259.619 109.708 259.261 110.052 258.889 110.36C257.793 111.27 256.526 111.965 255.173 112.395"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M253.712 113.828C253.032 113.384 252.731 112.552 252.581 111.757C252.473 111.198 252.423 110.611 252.574 110.059C252.717 109.507 253.089 108.991 253.619 108.783C253.698 108.755 253.784 108.726 253.87 108.74C253.956 108.755 254.027 108.805 254.092 108.855C254.414 109.12 254.578 109.543 254.614 109.966C254.65 110.388 254.564 110.804 254.464 111.205C254.235 112.137 253.898 113.04 253.469 113.9"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M250.204 117.16C249.925 117.067 249.703 116.844 249.495 116.637C248.929 116.056 248.342 115.426 248.235 114.623C248.178 114.222 248.256 113.806 248.371 113.419C248.55 112.803 248.851 112.173 249.416 111.879C249.603 111.778 249.824 111.728 250.025 111.778C250.276 111.843 250.469 112.058 250.569 112.294C250.669 112.538 250.684 112.796 250.684 113.061C250.705 114.63 250.605 115.641 250.204 117.16Z"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M246.309 121.18C246.237 121.244 246.123 121.187 246.044 121.129C245.335 120.592 244.719 119.918 244.333 119.116C243.946 118.313 243.796 117.382 244.018 116.522C244.118 116.128 244.311 115.734 244.669 115.54C245.127 115.297 245.722 115.483 246.08 115.863C246.431 116.243 246.588 116.766 246.652 117.282C246.839 118.664 246.531 120.055 246.216 121.416"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M241.949 125.228C241.555 125.099 241.254 124.777 240.982 124.454C240.323 123.673 239.715 122.813 239.443 121.824C239.171 120.836 239.278 119.703 239.93 118.908C240.187 118.593 240.574 118.328 240.982 118.385C241.29 118.428 241.541 118.65 241.741 118.887C242.536 119.883 242.557 121.273 242.435 122.534C242.357 123.322 242.235 124.11 242.063 124.884"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M236.328 129.714C234.624 128.374 233.565 126.26 233.515 124.096C233.493 123.265 233.722 122.276 234.503 121.968C235.004 121.767 235.598 121.939 236.006 122.298C236.414 122.649 236.658 123.157 236.822 123.673C237.395 125.486 237.037 127.442 236.679 129.313"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M250.369 117.382C251.615 116.837 253.047 116.988 254.393 117.189C254.722 117.239 255.094 117.325 255.237 117.626C255.359 117.898 255.223 118.22 255.03 118.443C254.686 118.829 254.171 119.037 253.655 119.073C253.14 119.109 252.617 118.994 252.137 118.808C251.371 118.521 250.655 118.07 250.14 117.439"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M253.784 113.713C255.03 113.169 256.462 113.319 257.808 113.52C258.137 113.57 258.509 113.656 258.652 113.957C258.774 114.229 258.638 114.551 258.445 114.774C258.101 115.161 257.586 115.368 257.07 115.404C256.555 115.44 256.032 115.325 255.552 115.139C254.786 114.852 254.07 114.401 253.555 113.77"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M246.352 121.108C247.913 120.356 249.832 120.37 251.378 121.158C251.722 121.33 252.073 121.574 252.173 121.946C252.259 122.269 252.116 122.627 251.879 122.856C251.636 123.093 251.314 123.215 250.985 123.286C250.233 123.444 249.438 123.322 248.722 123.036C248.006 122.749 247.362 122.305 246.76 121.825C246.524 121.631 246.273 121.409 246.209 121.108"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M241.999 124.97C243.517 124.44 245.192 124.748 246.746 125.164C247.347 125.321 248.006 125.543 248.307 126.088C248.679 126.769 248.271 127.686 247.584 128.051C246.903 128.417 246.059 128.345 245.328 128.087C243.946 127.607 242.822 126.539 242.056 125.293"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M236.608 129.348C237.775 128.969 239.064 129.119 240.216 129.527C241.369 129.943 242.421 130.602 243.452 131.254C243.954 131.57 244.512 131.978 244.562 132.566C244.591 132.974 244.362 133.368 244.032 133.605C243.703 133.848 243.295 133.956 242.894 134.013C240.811 134.3 238.691 133.189 237.403 131.519C236.851 130.81 236.422 129.943 236.508 129.047"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M229.963 134.801C229.899 134.909 229.734 134.83 229.648 134.751C228.073 133.268 227.293 130.975 227.629 128.839C227.694 128.431 227.801 128.015 228.023 127.671C228.367 127.134 228.947 126.797 229.534 126.547C229.67 126.489 229.82 126.432 229.971 126.453C230.164 126.482 230.307 126.64 230.429 126.797C231.267 127.901 231.539 129.363 231.374 130.738C231.209 132.114 230.637 133.411 229.921 134.593"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M229.577 134.78C230.479 134.386 231.503 134.328 232.476 134.465C233.45 134.601 234.395 134.923 235.319 135.26C235.999 135.511 236.708 135.783 237.202 136.313C237.696 136.844 237.918 137.711 237.502 138.305C237.102 138.886 236.292 139 235.584 138.986C234.195 138.957 232.784 138.606 231.646 137.804C230.507 137.001 229.677 135.711 229.62 134.321"
          fill="#00B7D8"
        />
      </g>
      <g opacity="0.38">
        <g opacity="0.38">
          <path
            opacity="0.38"
            d="M211.993 118.027C213.554 115.992 214.542 113.599 215.344 111.184C215.773 109.901 216.16 108.611 216.554 107.322C216.983 105.91 217.413 104.498 217.814 103.08C218.587 100.385 219.281 97.6625 219.911 94.9324C220.542 92.188 221.1 89.4293 221.587 86.6563C222.074 83.8832 222.489 81.0958 222.833 78.3013C222.876 77.9645 222.911 77.6206 222.954 77.2838C222.983 77.0402 222.611 76.9757 222.582 77.2193C222.26 79.9924 221.866 82.7654 221.401 85.517C220.935 88.2757 220.398 91.0129 219.79 93.7429C219.181 96.473 218.508 99.1815 217.756 101.876C216.99 104.627 216.167 107.357 215.301 110.08C214.535 112.495 213.661 114.924 212.236 117.038C212.064 117.296 211.885 117.547 211.692 117.79C211.549 117.977 211.842 118.22 211.993 118.027Z"
            fill="#00B7D8"
          />
        </g>
        <path
          opacity="0.38"
          d="M222.496 78.4733C221.68 77.0761 221.579 75.2704 222.274 73.8014C222.553 73.2139 223.083 72.6191 223.727 72.7051C224.2 72.7696 224.558 73.2067 224.672 73.6724C224.78 74.1382 224.687 74.6326 224.565 75.0912C224.2 76.467 223.563 77.7711 222.711 78.9033"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M222.353 80.931C221.544 80.9668 220.813 80.4581 220.226 79.8992C219.818 79.5051 219.439 79.0536 219.238 78.5234C219.038 77.986 219.038 77.3554 219.353 76.8825C219.403 76.8108 219.46 76.7392 219.539 76.7034C219.618 76.6675 219.703 76.6604 219.789 76.6675C220.205 76.7034 220.584 76.947 220.856 77.2694C221.128 77.5919 221.3 77.9788 221.45 78.3658C221.801 79.2614 222.052 80.1858 222.195 81.1388"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M221.422 85.6819C221.136 85.7679 220.835 85.7178 220.541 85.6604C219.747 85.5171 218.902 85.338 218.35 84.7504C218.071 84.4566 217.899 84.0768 217.763 83.6899C217.549 83.088 217.434 82.4001 217.728 81.834C217.821 81.6477 217.971 81.4758 218.164 81.3969C218.408 81.3038 218.687 81.3611 218.902 81.5044C219.124 81.6406 219.288 81.8484 219.439 82.0562C220.37 83.3245 220.864 84.2058 221.422 85.6819Z"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M220.577 91.2135C220.556 91.3067 220.434 91.3282 220.334 91.321C219.446 91.2924 218.551 91.0989 217.77 90.669C216.99 90.239 216.331 89.5655 216.009 88.7414C215.866 88.3617 215.794 87.9246 215.973 87.5663C216.202 87.1005 216.797 86.9071 217.305 87.0146C217.813 87.1149 218.243 87.4588 218.594 87.8386C219.546 88.8561 220.097 90.1745 220.627 91.4643"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M219.36 97.0391C218.959 97.1609 218.53 97.0749 218.129 96.9674C217.141 96.7166 216.145 96.3655 215.358 95.7135C214.57 95.0614 213.997 94.0797 214.069 93.0551C214.098 92.6466 214.255 92.2024 214.62 92.0161C214.892 91.8728 215.229 91.9014 215.53 91.9874C216.754 92.3385 217.577 93.4564 218.207 94.5598C218.601 95.2477 218.952 95.9643 219.267 96.6951"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M217.37 103.947C215.207 103.839 213.117 102.729 211.821 100.995C211.32 100.328 210.94 99.3894 211.391 98.6944C211.685 98.243 212.272 98.0423 212.809 98.0925C213.346 98.1426 213.84 98.4149 214.269 98.7374C215.787 99.8839 216.632 101.69 217.412 103.416"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M221.687 85.7607C222.389 84.5927 223.642 83.8833 224.852 83.2671C225.145 83.1166 225.503 82.9733 225.79 83.1381C226.047 83.2886 226.119 83.6254 226.09 83.9263C226.033 84.4423 225.732 84.908 225.331 85.2448C224.93 85.5744 224.444 85.775 223.942 85.9112C223.155 86.119 222.31 86.1691 221.522 85.9542"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M222.346 80.7878C223.047 79.6198 224.3 78.9104 225.51 78.2942C225.804 78.1437 226.162 78.0004 226.448 78.1652C226.706 78.3157 226.778 78.6525 226.749 78.9534C226.692 79.4693 226.391 79.9351 225.99 80.2719C225.589 80.6015 225.102 80.8021 224.601 80.9383C223.814 81.1461 222.969 81.1962 222.181 80.9813"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M220.57 91.1274C221.408 89.6084 222.976 88.5121 224.694 88.2541C225.074 88.1968 225.503 88.1896 225.797 88.4404C226.055 88.6554 226.14 89.028 226.083 89.3576C226.026 89.6872 225.833 89.981 225.603 90.2246C225.081 90.7907 224.358 91.1489 223.613 91.3281C222.868 91.5072 222.081 91.5215 221.315 91.4714C221.007 91.4499 220.678 91.4141 220.456 91.2063"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M219.252 96.7955C220.183 95.4843 221.723 94.7677 223.233 94.2016C223.813 93.9795 224.479 93.7789 225.038 94.0512C225.739 94.3879 225.932 95.3768 225.589 96.0718C225.245 96.7669 224.515 97.1968 223.77 97.4118C222.367 97.8202 220.835 97.5981 219.489 97.0248"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M217.384 103.495C218.114 102.506 219.253 101.883 220.427 101.553C221.608 101.224 222.84 101.152 224.064 101.088C224.658 101.059 225.346 101.059 225.725 101.518C225.983 101.833 226.026 102.284 225.897 102.678C225.768 103.065 225.503 103.388 225.202 103.667C223.663 105.108 221.293 105.43 219.281 104.814C218.422 104.556 217.563 104.097 217.119 103.316"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M215.121 111.793C215.129 111.915 214.957 111.951 214.835 111.936C212.687 111.635 210.733 110.224 209.773 108.282C209.587 107.909 209.437 107.515 209.415 107.1C209.386 106.462 209.666 105.853 209.995 105.308C210.074 105.179 210.167 105.05 210.296 104.979C210.475 104.893 210.682 104.936 210.869 104.993C212.186 105.408 213.26 106.44 213.919 107.658C214.577 108.877 214.864 110.267 214.964 111.642"
          fill="#00B7D8"
        />
        <path
          opacity="0.38"
          d="M214.799 111.994C215.308 111.148 216.11 110.51 216.983 110.052C217.857 109.593 218.809 109.314 219.761 109.056C220.463 108.862 221.193 108.676 221.909 108.819C222.618 108.963 223.298 109.543 223.305 110.267C223.312 110.976 222.718 111.535 222.131 111.936C220.978 112.717 219.632 113.248 218.236 113.255C216.84 113.262 215.422 112.689 214.57 111.592"
          fill="#00B7D8"
        />
      </g>
      <path
        d="M61.4667 61.9426C61.9607 63.7412 61.4094 65.8192 62.3688 67.4242C62.8485 68.2268 63.6432 68.7785 64.4165 69.3016C66.9653 71.0428 69.9223 72.8772 72.9294 72.1749C73.9389 71.9385 74.8697 71.4226 75.8792 71.1718C77.5617 70.749 79.4519 71.05 80.9411 70.1614C81.7932 69.6527 82.4375 68.7857 83.3898 68.4847C83.8838 68.3271 84.4208 68.3343 84.9363 68.2841C86.3611 68.1336 87.7286 67.4601 88.7166 66.4211C89.0818 66.0341 89.4039 65.597 89.8407 65.2961C90.3848 64.9235 91.0578 64.7945 91.6879 64.5795C93.5423 63.949 94.7379 65.0739 95.4682 63.2611C96.4563 63.0389 97.208 62.0501 97.1723 61.0326C97.1579 60.6528 97.0434 60.2802 97.0505 59.9005C97.072 59.2484 97.4443 58.6752 97.7164 58.0876C98.5899 56.1959 98.4109 54.0033 98.2104 51.9324C98.1317 51.1371 98.0314 50.2701 97.473 49.6968C97.3083 49.5248 97.1078 49.3815 97.0291 49.1594C96.936 48.8871 97.0649 48.6005 97.1723 48.3354C97.7951 46.8306 97.9169 44.9604 96.9145 43.685C96.2988 42.9039 95.3608 42.4525 94.7021 41.7145C94.0793 41.0122 93.7284 40.0664 92.991 39.486C92.3251 38.9701 91.4301 38.8268 90.7786 38.2965C90.1271 37.7663 89.812 36.9351 89.2822 36.283C88.1939 34.9574 86.3539 34.5633 84.6499 34.3555C82.9459 34.1477 81.1273 34.0044 79.7598 32.9726C79.4448 32.7361 79.1512 32.4495 78.7861 32.2847C77.5975 31.7544 76.3303 32.7074 75.1203 33.209C73.223 33.9972 70.9676 33.6605 69.1347 34.592C66.1849 36.0967 65.5692 40.1237 63.1206 42.3522C62.2041 43.1906 60.987 43.8211 60.5359 44.9748C59.9345 46.4938 60.9225 48.2136 60.6719 49.8258C60.5287 50.7286 60.0132 51.524 59.6553 52.3695C58.8534 54.2612 58.8749 56.4825 59.7054 58.3527C60.2424 59.5709 61.1158 60.6528 61.4667 61.9426Z"
        fill="#161119"
      />
      <path
        d="M99.807 64.7946C102.978 64.7946 105.549 61.5961 105.549 57.6506C105.549 53.7051 102.978 50.5066 99.807 50.5066C96.6358 50.5066 94.0649 53.7051 94.0649 57.6506C94.0649 61.5961 96.6358 64.7946 99.807 64.7946Z"
        fill="#00B7D8"
      />
      <path
        d="M97.1005 65.8048C100.272 65.8048 102.843 62.6064 102.843 58.6608C102.843 54.7153 100.272 51.5168 97.1005 51.5168C93.9292 51.5168 91.3584 54.7153 91.3584 58.6608C91.3584 62.6064 93.9292 65.8048 97.1005 65.8048Z"
        fill="#04A2BC"
      />
      <path
        d="M86.7047 73.3787C86.1606 76.4312 85.7167 79.8993 87.6069 82.3499C88.0221 82.8873 88.5591 83.4104 88.5949 84.0911C88.6307 84.9796 87.7859 85.6388 86.984 86.0329C83.9053 87.5735 80.1822 87.767 76.9603 86.5488C76.6954 86.4485 76.4162 86.3267 76.2372 86.1046C75.8291 85.603 76.094 84.865 76.3374 84.2702C78.6214 78.8101 78.557 72.4042 76.1584 66.9871"
        fill="#E8A17E"
      />
      <path
        d="M86.9771 72.483C86.4473 74.6255 86.1036 76.6892 86.3327 78.889C86.3399 78.9606 86.347 79.0466 86.2969 79.1039C86.2253 79.1756 86.1036 79.1469 86.0105 79.1111C85.3089 78.8603 84.5929 78.5952 83.9771 78.1796C82.939 77.4702 81.7147 76.1661 81.1777 75.0196C80.4402 73.4432 80.0751 71.9026 80.2111 70.1614"
        fill="#D88D6C"
      />
      <path
        d="M94.029 45.0964C96.2055 47.5255 96.7139 50.9865 97.0002 54.2396C97.2508 57.1058 97.4084 60.0007 97.0002 62.8454C96.5921 65.6901 95.5969 68.5061 93.7569 70.7131C92.9836 71.6446 92.0672 72.4543 91.0505 73.1135C90.3489 73.5649 89.5899 73.9519 88.7737 74.1238C87.2201 74.4534 85.6234 74.0092 84.1127 73.5219C80.6761 72.4041 77.1392 70.8994 75.0127 67.9687C72.2491 64.1566 70.4233 54.1178 69.7002 49.4602"
        fill="#E8A17E"
      />
      <path
        d="M98.3606 48.0558C95.1459 50.836 91.1579 52.7062 86.9623 53.4013C84.5351 53.8025 82.0579 53.8097 79.5949 53.7596C78.6713 53.7381 77.7406 53.7094 76.8456 53.4944C75.5998 53.1935 74.4185 52.4841 73.7454 51.4021C73.0724 50.3201 72.9722 48.8297 73.6953 47.7764"
        fill="#D88D6C"
      />
      <path
        d="M92.082 65.0381C91.6381 66.421 91.1226 67.6965 89.8982 68.4775C88.6882 69.2514 87.063 69.1654 85.7599 68.5492C84.4568 67.9401 83.2182 66.4067 82.3018 65.2889C85.4735 65.597 88.9532 65.5898 92.082 65.0381Z"
        fill="white"
      />
      <path d="M88.4015 57.8225L90.0625 62.9028L87.4492 63.1106" fill="#D88D6C" />
      <path
        d="M83.225 53.774C83.1176 53.7096 83.0102 53.6522 82.8957 53.6021C82.8312 53.5734 82.7596 53.5376 82.6952 53.5089C82.7167 53.5161 82.7453 53.5304 82.7668 53.5376C82.5949 53.4659 82.4231 53.4014 82.2441 53.3441C82.0293 53.2725 81.8074 53.2151 81.5783 53.1721C81.4351 53.1435 81.2919 53.1148 81.1415 53.1005C81.1702 53.1077 81.1988 53.1077 81.2274 53.1148C80.8122 53.0575 80.3826 53.0432 79.9602 53.0718C79.8313 53.079 79.7024 53.0933 79.5735 53.1148C79.6022 53.1077 79.6308 53.1077 79.6594 53.1005C79.2299 53.1578 78.8146 53.2581 78.4065 53.4014C78.2848 53.4444 78.1559 53.4946 78.0342 53.5447C78.0557 53.5376 78.0843 53.5233 78.1058 53.5161C77.6476 53.7096 77.218 53.9532 76.8099 54.2326C76.7454 54.2756 76.638 54.2828 76.5664 54.2613C76.4948 54.2398 76.4161 54.1825 76.3803 54.118C76.3445 54.0463 76.323 53.9532 76.3517 53.8744C76.3803 53.7955 76.4232 53.7382 76.4948 53.6881C77.1321 53.251 77.8409 52.8999 78.5927 52.6849C79.2012 52.5057 79.8384 52.4198 80.4757 52.4269C80.9983 52.4341 81.521 52.5057 82.0222 52.6276C82.3945 52.7207 82.7596 52.8497 83.1104 53.0073C83.2536 53.0718 83.3968 53.1363 83.5329 53.2223H83.54C83.6116 53.2653 83.6617 53.3298 83.6832 53.4086C83.7047 53.4803 83.6975 53.5806 83.6546 53.6522C83.6116 53.7167 83.5472 53.7812 83.4684 53.7955C83.3897 53.8242 83.2966 53.8242 83.225 53.774Z"
        fill="#3E3244"
      />
      <path
        d="M91.6735 53.817C91.7666 53.731 91.8668 53.6593 91.967 53.5877C92.0243 53.5447 92.0888 53.5017 92.1532 53.4587C92.1317 53.473 92.1102 53.4874 92.0888 53.5017C92.2463 53.4014 92.4038 53.3082 92.5685 53.2151C92.7689 53.1076 92.9766 53.0073 93.1914 52.9213C93.3274 52.864 93.4634 52.8138 93.6066 52.7708C93.578 52.778 93.5565 52.7852 93.5279 52.7995C93.9288 52.6705 94.3441 52.5773 94.7665 52.5272C94.8954 52.5129 95.0243 52.4985 95.1603 52.4985C95.1317 52.4985 95.103 52.4985 95.0744 52.5057C95.504 52.4842 95.9335 52.5057 96.3631 52.5774C96.492 52.5989 96.6209 52.6203 96.7569 52.649C96.7283 52.6418 96.7068 52.6347 96.6782 52.6347C97.165 52.7422 96.5206 53.0718 97.4013 52.8353C97.4872 52.8138 96.8213 51.9826 96.0481 51.9038C95.418 51.8393 94.7737 51.868 94.1508 51.9969C93.6424 52.0973 93.1412 52.2621 92.6615 52.477C92.3107 52.6347 91.9814 52.8281 91.6592 53.0503C91.5303 53.1363 91.4014 53.2366 91.2797 53.3369C91.2797 53.3369 91.2797 53.3369 91.2725 53.3441C91.2153 53.4014 91.1723 53.473 91.1652 53.5519C91.158 53.6307 91.1866 53.7238 91.2367 53.7811C91.294 53.8385 91.3656 53.8886 91.4444 53.8886C91.5231 53.8958 91.609 53.8743 91.6735 53.817Z"
        fill="#3E3244"
      />
      <path
        opacity="0.45"
        d="M79.0725 64.1567C80.3102 64.1567 81.3135 63.1526 81.3135 61.9139C81.3135 60.6753 80.3102 59.6711 79.0725 59.6711C77.8349 59.6711 76.8315 60.6753 76.8315 61.9139C76.8315 63.1526 77.8349 64.1567 79.0725 64.1567Z"
        fill="#F27B53"
      />
      <path
        opacity="0.45"
        d="M95.2105 62.9316C96.2544 62.9316 97.1006 62.0846 97.1006 61.0399C97.1006 59.9951 96.2544 59.1482 95.2105 59.1482C94.1666 59.1482 93.3203 59.9951 93.3203 61.0399C93.3203 62.0846 94.1666 62.9316 95.2105 62.9316Z"
        fill="#F27B53"
      />
      <path
        d="M88.6397 71.4766C88.7111 70.736 88.0001 70.0614 87.0515 69.9697C86.1029 69.8781 85.276 70.4041 85.2045 71.1447C85.1331 71.8852 85.8442 72.5599 86.7928 72.6515C87.7413 72.7432 88.5682 72.2172 88.6397 71.4766Z"
        fill="#161119"
      />
      <path
        d="M83.7908 71.573C84.5498 71.6446 85.3302 71.6733 86.1249 71.6375L86.0963 70.8923C77.1108 71.2505 70.1945 63.9847 70.1301 63.9131L69.5859 64.4218C69.6504 64.4935 75.6288 70.7848 83.7908 71.573Z"
        fill="#161119"
      />
      <path
        d="M99.3489 51.0009C98.633 45.9564 95.991 40.4103 91.6666 37.5584C87.7931 35.0075 82.6095 34.8427 78.3423 36.4836C74.6622 37.8952 72.2279 41.0409 71.0322 44.7096C70.0084 47.8337 69.8723 51.0439 69.6361 54.2899C69.5573 55.3575 67.8819 55.3718 67.9607 54.2899C68.1898 51.2015 68.3115 48.1204 69.1564 45.118C70.259 41.2057 72.5071 37.6158 76.1586 35.6524C80.5045 33.3165 86.261 33.0657 90.743 35.1293C94.3944 36.8061 97.0578 40.0664 98.7547 43.6348C99.7785 45.7987 100.631 48.1705 100.967 50.5566C101.117 51.61 99.4993 52.0614 99.3489 51.0009Z"
        fill="#00B7D8"
      />
      <path
        d="M69.7006 66.808C72.8719 66.808 75.4427 63.6095 75.4427 59.664C75.4427 55.7185 72.8719 52.52 69.7006 52.52C66.5293 52.52 63.9585 55.7185 63.9585 59.664C63.9585 63.6095 66.5293 66.808 69.7006 66.808Z"
        fill="#00B7D8"
      />
      <path
        d="M68.7985 66.4496C71.8116 66.4496 74.2542 63.4115 74.2542 59.6639C74.2542 55.9162 71.8116 52.8782 68.7985 52.8782C65.7854 52.8782 63.3428 55.9162 63.3428 59.6639C63.3428 63.4115 65.7854 66.4496 68.7985 66.4496Z"
        fill="#04A2BC"
      />
      <path
        d="M76.9318 82.6868C69.414 83.0092 61.2734 85.2735 55.7748 90.4112C55.3094 90.8483 54.8512 91.3212 54.6507 91.9231C54.3571 92.8045 54.6578 93.7646 54.9371 94.6532C58.1876 104.907 58.3666 116.114 55.4311 126.461C54.665 129.169 53.6841 131.849 53.4192 134.651C53.1543 137.453 53.7128 140.491 55.6459 142.533C56.4764 143.414 57.5217 144.066 58.6029 144.611C64.6242 147.656 71.6908 147.542 78.4281 147.262C82.5808 147.09 86.7764 146.875 90.7787 145.736C94.781 144.597 98.6186 142.447 101.053 139.072C103.559 135.59 104.346 131.161 104.661 126.884C105.385 117.16 104.067 107.408 103.881 97.6555C103.831 94.818 103.888 91.8944 104.991 89.2862C99.8572 85.7321 93.7213 83.5753 87.5997 82.3572C86.2824 84.1127 84.4423 84.9081 82.2514 85.0156C80.0749 85.1231 78.421 84.2919 76.9318 82.6868Z"
        fill="#005B7A"
      />
      <path
        d="M56.6052 133.426C56.8486 130.624 54.8582 127.378 55.567 124.669C57.3713 117.783 60.765 111.091 60.0347 103.983C61.5812 104.757 62.9773 105.781 63.7721 107.307C64.4236 108.561 64.5954 110.023 64.6098 111.442C64.6384 114.287 64.1157 117.103 64.0012 119.94C63.8866 122.778 64.2446 125.773 65.8412 128.095C67.6884 130.774 70.8674 132.129 73.9675 132.96C76.051 133.519 78.1846 133.906 80.311 134.243C82.7597 134.629 85.2513 134.952 87.7142 134.637C90.1772 134.321 92.6258 133.297 94.2439 131.369C96.8071 128.324 96.7283 123.896 97.1937 119.897C97.702 115.512 99.0194 111.22 101.067 107.336C101.683 106.168 102.399 104.993 103.509 104.312C103.509 104.312 103.516 104.312 103.516 104.305C103.895 111.428 108.012 118.901 107.525 126.002C107.232 130.287 102.907 134.35 100.587 137.832C98.3464 141.207 94.7952 143.357 91.1008 144.496C87.4063 145.636 83.5329 145.851 79.6953 146.023C73.4735 146.302 66.9438 146.417 61.3879 143.371C60.3927 142.827 59.4261 142.175 58.66 141.293C56.8773 139.258 56.3618 136.227 56.6052 133.426Z"
        fill="#04506B"
      />
      <path
        d="M101.869 87.3442C99.5492 97.6769 101.547 109.873 104.124 120.141C104.89 123.193 104.726 126.539 103.637 128.087C102.506 129.692 100.552 130.652 98.7688 131.477C94.6233 133.39 91.7451 135.647 88.5805 138.936C87.3132 140.254 86.1462 141.745 85.602 143.486C85.0507 145.234 85.2154 147.284 86.3824 148.695C87.4993 150.042 89.3394 150.601 91.0864 150.601C92.8333 150.601 94.5445 150.114 96.2414 149.713C99.0909 149.046 101.998 148.609 104.919 148.409C107.446 148.237 110.017 148.237 112.451 147.527C115.909 146.51 118.88 144.045 120.706 140.935C122.525 137.818 123.226 134.099 122.861 130.509C122.668 128.582 122.181 126.704 121.68 124.834C119.604 117.095 116.74 110.324 114.119 102.75C113.045 99.6546 111.076 96.1578 109.179 93.4779C107.282 90.798 105.098 87.896 101.869 87.3442Z"
        fill="#005B7A"
      />
      <path
        d="M101.024 107.021C99.6208 100.636 100.122 93.5713 101.618 87.2728C101.697 86.9432 102.198 87.0865 102.12 87.4089C101.053 91.9017 100.638 96.5234 100.867 101.138C100.96 103.051 101.117 105.007 101.525 106.878C101.597 107.214 101.096 107.35 101.024 107.021Z"
        fill="white"
      />
      <path
        d="M81.9938 143.565C81.9938 143.565 88.9029 141.845 91.4947 143.328C94.0794 144.812 98.0101 146.976 97.8884 149.727V150.859C97.8884 150.859 79.359 151.404 77.8555 149.727"
        fill="#E8A17E"
      />
      <path
        d="M61.345 86.5706C57.9513 86.9647 54.2426 90.92 51.9014 94.338C49.553 97.7559 47.9564 101.625 46.3812 105.459C43.4243 112.639 40.4673 119.818 37.5175 126.998C36.601 129.227 35.6774 131.47 35.2335 133.834C34.8612 135.797 34.8326 137.825 35.1333 139.796C35.3839 141.415 35.8779 143.035 36.8874 144.324C38.3194 146.144 40.5747 147.069 42.7369 147.893C47.2977 149.627 51.9801 151.253 56.8416 151.59C58.7604 151.719 60.6863 151.655 62.6052 151.583C67.9105 151.39 73.2445 151.196 78.464 150.222C78.8363 150.15 79.23 150.071 79.5308 149.835C79.8458 149.591 80.0176 149.218 80.1823 148.86C80.705 147.714 81.2348 146.56 81.7574 145.414C82.051 144.769 82.3517 144.095 82.2944 143.393C82.2157 142.447 81.5283 141.68 80.8338 141.043C78.9938 139.337 76.8745 137.933 74.5834 136.908C69.8794 134.794 64.5025 134.264 60.0778 131.606C59.5408 131.283 59.0181 130.925 58.5886 130.474C56.9561 128.739 57.0564 125.952 57.9728 123.759C58.8893 121.567 59.5265 119.467 60.4214 117.268C62.419 112.366 63.4142 109.171 63.5216 104.986C63.6934 99.0099 63.2495 92.2241 61.345 86.5706Z"
        fill="#005B7A"
      />
      <path
        d="M79.2803 139.846C78.6574 140.684 78.1848 141.659 77.7553 142.612C76.6598 145.077 76.0369 147.742 75.8436 150.437C75.8221 150.773 75.2995 150.773 75.321 150.437C75.5143 147.728 76.13 145.062 77.2111 142.562C77.655 141.537 78.1634 140.484 78.8292 139.581C79.0225 139.316 79.4808 139.574 79.2803 139.846Z"
        fill="#FFA748"
      />
      <path
        d="M63.5143 107.307C63.9511 100.479 63.4213 93.0982 61.0943 86.6421C60.9798 86.3269 61.4881 86.1907 61.5955 86.506C63.9368 93.0051 64.4666 100.443 64.0298 107.315C64.0155 107.637 63.4928 107.644 63.5143 107.307Z"
        fill="white"
      />
      <path
        d="M55.1449 140.871C54.2857 138.427 51.7512 137.488 49.4815 137.145C44.5198 144.912 38.3195 146.46 34.9258 146.66L35.9138 152.479H47.0973L47.6987 147.835C50.534 147.785 53.0399 146.811 54.3859 145.156C55.3955 143.902 55.6675 142.375 55.1449 140.871ZM52.2309 143.414C51.565 144.238 50.0829 144.955 48.0496 145.062L48.7226 139.832C50.3836 140.032 52.102 140.562 52.5316 141.781C52.7392 142.375 52.639 142.913 52.2309 143.414Z"
        fill="#00AED6"
      />
      <path
        d="M49.4814 137.145C49.3453 137.123 49.2093 137.102 49.0804 137.08L49.7535 131.835C49.7964 131.527 49.553 131.247 49.2379 131.247H32.9925C32.6345 131.247 32.3625 131.57 32.4197 131.921L34.9185 146.66C38.3194 146.46 44.5197 144.912 49.4814 137.145Z"
        fill="#00B7D8"
      />
      <path
        d="M42.028 129.069C40.4099 128.209 38.8705 125.114 38.2333 123.681C37.5961 122.247 37.4099 120.535 38.1187 119.131C38.713 117.948 39.8586 117.131 40.5674 116.021C41.2977 114.867 41.5339 113.405 41.1903 112.087C40.7034 110.224 39.1784 108.741 38.8276 106.849C38.4195 104.628 39.7082 102.492 40.2738 100.307C40.7965 98.2933 40.6891 96.1293 39.9803 94.1731C41.5554 95.1118 42.5005 96.8028 43.3668 98.4151C44.1616 99.8912 44.9778 101.468 44.8632 103.137C44.7916 104.19 44.3549 105.172 43.9826 106.161C43.3167 107.945 42.8585 109.901 43.3382 111.75C44.0542 114.537 46.8035 116.723 46.6961 119.603C46.6317 121.309 45.5291 122.821 44.1974 123.888C43.331 124.583 42.3287 125.164 41.7201 126.095C41.1043 127.027 41.0829 128.481 42.028 129.069Z"
        fill="white"
      />
      <path
        d="M141.327 44.6809C140.031 47.6832 139.043 50.5853 138.563 53.5661C138.083 56.547 137.919 59.5923 137.174 62.5158C136.523 65.1026 135.427 67.5603 134.253 69.9536C134.145 70.1757 134.038 70.3978 134.038 70.6415C134.038 71.1144 134.446 71.4798 134.833 71.7593C140.661 76.0228 148.737 76.9829 155.403 74.2099"
        fill="#4B3C51"
      />
      <path
        d="M139.773 64.7946C142.944 64.7946 145.515 61.5961 145.515 57.6506C145.515 53.7051 142.944 50.5066 139.773 50.5066C136.602 50.5066 134.031 53.7051 134.031 57.6506C134.031 61.5961 136.602 64.7946 139.773 64.7946Z"
        fill="#00B7D8"
      />
      <path
        d="M142.472 65.8048C145.643 65.8048 148.214 62.6064 148.214 58.6608C148.214 54.7153 145.643 51.5168 142.472 51.5168C139.301 51.5168 136.73 54.7153 136.73 58.6608C136.73 62.6064 139.301 65.8048 142.472 65.8048Z"
        fill="#04A2BC"
      />
      <path
        d="M155.395 74.2386L143.417 49.0806C142.093 53.5017 140.768 57.9228 139.515 62.3582C139.1 63.8343 138.685 65.3247 138.548 66.8581C138.427 68.1981 138.52 69.5524 138.821 70.8637C139.114 72.1391 139.622 73.4002 140.51 74.3533C140.668 74.5252 140.854 74.6685 141.04 74.819C145.715 76.2449 150.87 76.1231 155.395 74.2386Z"
        fill="#3E3244"
      />
      <path
        d="M152.875 73.3787C153.419 76.4312 153.863 79.8993 151.973 82.3499C151.558 82.8873 151.021 83.4104 150.985 84.0911C150.949 84.9796 151.794 85.6388 152.596 86.0329C155.675 87.5735 159.398 87.767 162.62 86.5488C162.884 86.4485 163.164 86.3267 163.343 86.1046C163.751 85.603 163.486 84.865 163.242 84.2702C160.958 78.8101 161.023 72.4042 163.421 66.9871"
        fill="#E8A17E"
      />
      <path
        d="M152.603 72.483C153.133 74.6255 153.477 76.6891 153.247 78.889C153.24 78.9606 153.233 79.0466 153.283 79.1039C153.355 79.1756 153.477 79.1469 153.57 79.1111C154.271 78.8603 154.987 78.5952 155.603 78.1796C156.641 77.4702 157.865 76.1661 158.402 75.0196C159.14 73.4432 159.505 71.9026 159.369 70.1614"
        fill="#D88D6C"
      />
      <path
        d="M145.551 45.0964C143.374 47.5255 142.866 50.9865 142.58 54.2396C142.329 57.1058 142.171 60.0007 142.58 62.8454C142.988 65.6901 143.983 68.5061 145.823 70.7131C146.596 71.6446 147.513 72.4543 148.529 73.1135C149.231 73.5649 149.99 73.9519 150.806 74.1238C152.36 74.4534 153.956 74.0092 155.467 73.5219C158.904 72.4041 162.441 70.8994 164.567 67.9687C167.331 64.1566 169.156 54.1178 169.88 49.4602"
        fill="#E8A17E"
      />
      <path
        d="M141.219 48.0558C144.434 50.836 148.422 52.7062 152.618 53.4013C155.045 53.8025 157.522 53.8097 159.985 53.7596C160.908 53.7381 161.839 53.7094 162.734 53.4944C163.98 53.1935 165.161 52.4841 165.834 51.4021C166.507 50.3201 166.608 48.8297 165.884 47.7764"
        fill="#D88D6C"
      />
      <path
        d="M168.956 35.3372C162.827 32.3706 153.956 32.9869 149.496 35.5378C145.035 38.0887 143.188 40.3745 140.861 45.6984C140.725 46.0137 139.916 47.9771 140.038 48.2995C140.16 48.622 140.317 48.8011 140.632 48.9229C146.138 51.0296 150.985 51.4093 156.87 51.696C158.832 51.7963 160.823 51.7963 162.727 51.3305C165.426 60.3805 164.023 66.8725 159.527 75.1773C162.455 76.4097 173.832 77.1263 179.252 72.9273C180.648 71.8453 181.922 70.5627 182.774 69.0149C184.929 65.0811 184.006 60.2301 182.832 55.9021C181.958 52.6776 180.97 49.4603 179.388 46.5225C176.968 42.0082 173.66 37.365 168.956 35.3372Z"
        fill="#4B3C51"
      />
      <path
        d="M147.491 65.0381C147.935 66.421 148.451 67.6965 149.675 68.4775C150.885 69.2514 152.51 69.1654 153.813 68.5492C155.116 67.9401 156.355 66.4067 157.271 65.2889C154.107 65.597 150.62 65.5898 147.491 65.0381Z"
        fill="white"
      />
      <path
        d="M172.901 72.3111C172.529 72.0173 172.192 71.659 172.013 71.2147C171.67 70.3621 171.97 69.3947 172.235 68.5062C172.686 67.0086 173.03 65.4537 172.98 63.8916C172.923 62.3295 172.436 60.7388 171.39 59.578C169.873 57.8941 167.495 57.3639 165.276 56.9196C164.811 56.8264 164.345 56.7333 163.88 56.6401C164.71 63.1321 163.128 68.5922 159.562 75.1844C162.491 76.4169 173.868 77.1334 179.288 72.9345C179.488 72.784 179.674 72.612 179.867 72.4544C179.538 72.6407 179.202 72.8341 178.844 72.9703C176.896 73.7227 174.534 73.608 172.901 72.3111Z"
        fill="#3E3244"
      />
      <path d="M152.94 56.8767C151.415 56.067 150.24 56.8408 150.226 56.8552L149.933 56.4181C149.99 56.3823 151.379 55.4579 153.183 56.4109L152.94 56.8767Z" fill="#161119" />
      <path d="M163.901 56.7262L162.534 56.7047V56.1816H163.83L163.901 56.7262Z" fill="#161119" />
      <path d="M151.171 57.8225L149.517 62.9028L152.123 63.1106" fill="#D88D6C" />
      <path
        d="M156.355 53.774C156.462 53.7095 156.57 53.6522 156.684 53.6021C156.748 53.5734 156.82 53.5376 156.885 53.5089C156.863 53.5161 156.834 53.5304 156.813 53.5376C156.985 53.4659 157.157 53.4014 157.336 53.3441C157.55 53.2725 157.772 53.2151 158.001 53.1721C158.145 53.1435 158.288 53.1148 158.438 53.1005C158.41 53.1076 158.381 53.1077 158.352 53.1148C158.768 53.0575 159.197 53.0432 159.62 53.0718C159.748 53.079 159.877 53.0933 160.006 53.1148C159.978 53.1077 159.949 53.1076 159.92 53.1005C160.35 53.1578 160.765 53.2581 161.173 53.4014C161.295 53.4444 161.424 53.4946 161.546 53.5447C161.524 53.5376 161.495 53.5232 161.474 53.5161C161.932 53.7095 162.362 53.9532 162.77 54.2326C162.834 54.2756 162.942 54.2828 163.013 54.2613C163.085 54.2398 163.164 54.1825 163.199 54.118C163.235 54.0463 163.257 53.9532 163.228 53.8744C163.199 53.7955 163.156 53.7382 163.085 53.688C162.448 53.251 161.739 52.8998 160.987 52.6849C160.378 52.5057 159.741 52.4198 159.104 52.4269C158.581 52.4341 158.059 52.5057 157.558 52.6276C157.185 52.7207 156.82 52.8497 156.469 53.0073C156.326 53.0718 156.183 53.1363 156.047 53.2223H156.04C155.968 53.2653 155.918 53.3298 155.896 53.4086C155.875 53.4802 155.882 53.5806 155.925 53.6522C155.968 53.7167 156.033 53.7812 156.111 53.7955C156.19 53.8242 156.276 53.8242 156.355 53.774Z"
        fill="#3E3244"
      />
      <path
        d="M147.906 53.817C147.813 53.731 147.713 53.6593 147.613 53.5877C147.555 53.5447 147.491 53.5017 147.426 53.4587C147.448 53.473 147.469 53.4874 147.491 53.5017C147.333 53.4014 147.176 53.3082 147.011 53.2151C146.811 53.1076 146.603 53.0073 146.388 52.9213C146.252 52.864 146.116 52.8138 145.973 52.7708C146.002 52.778 146.023 52.7852 146.052 52.7995C145.651 52.6705 145.236 52.5773 144.813 52.5272C144.684 52.5129 144.555 52.4985 144.419 52.4985C144.448 52.4985 144.477 52.4985 144.505 52.5057C144.076 52.4842 143.646 52.5057 143.217 52.5774C143.088 52.5989 142.959 52.6203 142.823 52.649C142.851 52.6418 142.873 52.6347 142.902 52.6347C142.415 52.7422 143.059 53.0718 142.178 52.8353C142.092 52.8138 142.758 51.9826 143.532 51.9038C144.162 51.8393 144.806 51.868 145.429 51.9969C145.937 52.0973 146.438 52.2621 146.918 52.477C147.269 52.6347 147.598 52.8281 147.92 53.0503C148.049 53.1363 148.178 53.2366 148.3 53.3369C148.3 53.3369 148.3 53.3369 148.307 53.3441C148.364 53.4014 148.407 53.473 148.414 53.5519C148.422 53.6307 148.393 53.7238 148.343 53.7811C148.286 53.8385 148.214 53.8886 148.135 53.8886C148.056 53.8958 147.971 53.8743 147.906 53.817Z"
        fill="#3E3244"
      />
      <path
        d="M156.548 57.2779C156.555 57.2636 156.57 57.2493 156.577 57.2349C156.57 57.2493 156.562 57.2564 156.548 57.2779C156.591 57.2206 156.634 57.1704 156.677 57.1203C156.749 57.0415 156.82 56.9698 156.899 56.8981C156.949 56.848 157.006 56.805 157.064 56.762C157.049 56.7692 157.042 56.7835 157.028 56.7907C157.164 56.6832 157.307 56.59 157.465 56.5184C157.515 56.4969 157.558 56.4754 157.608 56.4539C157.593 56.461 157.579 56.4682 157.565 56.4754C157.701 56.4181 157.844 56.3751 157.987 56.3464C158.03 56.3392 158.073 56.3321 158.116 56.3249C158.102 56.3249 158.088 56.3321 158.066 56.3321C158.209 56.3106 158.345 56.3106 158.488 56.3177C158.531 56.3177 158.574 56.3249 158.617 56.3321C158.603 56.3321 158.589 56.3249 158.567 56.3249C158.725 56.3464 158.882 56.3894 159.033 56.4396C159.083 56.4539 159.126 56.4754 159.176 56.4969C159.161 56.4897 159.147 56.4825 159.133 56.4754C159.34 56.5614 159.534 56.676 159.72 56.805C159.777 56.848 159.834 56.891 159.892 56.934C159.877 56.9268 159.87 56.9125 159.856 56.9053C159.906 56.9411 159.949 56.9841 159.999 57.02C160.035 57.0486 160.078 57.0701 160.128 57.0701C160.171 57.0701 160.221 57.0486 160.257 57.02C160.286 56.9841 160.314 56.9411 160.307 56.891C160.307 56.848 160.293 56.7978 160.257 56.762C160.049 56.59 159.827 56.4252 159.591 56.2962C159.391 56.1888 159.183 56.0956 158.968 56.0383C158.782 55.9881 158.582 55.9595 158.388 55.9523C158.209 55.9451 158.03 55.9666 157.858 56.0025C157.701 56.0383 157.543 56.0956 157.393 56.1601C157.264 56.2174 157.142 56.2819 157.028 56.3607C156.913 56.4396 156.806 56.5184 156.699 56.6115C156.62 56.676 156.548 56.7477 156.477 56.8193C156.426 56.8695 156.376 56.9268 156.333 56.9841C156.305 57.02 156.269 57.063 156.24 57.0988C156.24 57.0988 156.24 57.0988 156.24 57.1059C156.212 57.1418 156.212 57.2063 156.219 57.2421C156.233 57.2851 156.262 57.3281 156.298 57.3496C156.341 57.3711 156.391 57.3854 156.434 57.3711C156.491 57.3424 156.52 57.3137 156.548 57.2779Z"
        fill="#3E3244"
      />
      <path
        d="M143.546 56.805C143.553 56.7907 143.568 56.7764 143.575 56.762C143.568 56.7764 143.553 56.7907 143.546 56.805C143.589 56.7477 143.632 56.6975 143.675 56.6474C143.747 56.5686 143.818 56.4969 143.897 56.4252C143.947 56.3751 144.004 56.3321 144.062 56.2891C144.047 56.2963 144.04 56.3106 144.026 56.3178C144.162 56.2103 144.305 56.1171 144.463 56.0455C144.513 56.024 144.556 56.0025 144.606 55.981C144.592 55.9882 144.577 55.9953 144.563 56.0025C144.699 55.9452 144.842 55.9022 144.985 55.8735C145.028 55.8663 145.071 55.8592 145.114 55.852C145.1 55.852 145.086 55.8592 145.064 55.8592C145.207 55.8377 145.343 55.8377 145.487 55.8448C145.529 55.8448 145.572 55.852 145.615 55.8592C145.601 55.8592 145.587 55.852 145.565 55.852C145.723 55.8735 145.88 55.9165 146.031 55.9667C146.081 55.981 146.124 56.0025 146.174 56.024C146.16 56.0168 146.145 56.0097 146.131 56.0025C146.339 56.0885 146.532 56.2031 146.718 56.3321C146.775 56.3751 146.833 56.4181 146.89 56.4611C146.875 56.4539 146.868 56.4396 146.854 56.4324C146.904 56.4682 146.947 56.5112 146.997 56.5471C147.033 56.5757 147.076 56.5972 147.126 56.5972C147.169 56.5972 147.219 56.5757 147.255 56.5471C147.284 56.5112 147.312 56.4682 147.305 56.4181C147.305 56.3751 147.291 56.3249 147.255 56.2891C147.047 56.1171 146.825 55.9523 146.589 55.8234C146.389 55.7159 146.181 55.6227 145.966 55.5654C145.78 55.5152 145.58 55.4866 145.386 55.4794C145.207 55.4722 145.028 55.4937 144.856 55.5296C144.699 55.5654 144.541 55.6227 144.391 55.6872C144.262 55.7445 144.14 55.809 144.026 55.8878C143.911 55.9667 143.804 56.0455 143.697 56.1386C143.618 56.2031 143.546 56.2748 143.475 56.3464C143.425 56.3966 143.374 56.4539 143.331 56.5112C143.303 56.5471 143.267 56.5901 143.238 56.6259C143.238 56.6259 143.238 56.6259 143.238 56.633C143.21 56.6689 143.21 56.7334 143.217 56.7692C143.231 56.8122 143.26 56.8552 143.296 56.8767C143.339 56.8982 143.389 56.9125 143.432 56.8982C143.482 56.8695 143.518 56.848 143.546 56.805Z"
        fill="#3E3244"
      />
      <path
        opacity="0.45"
        d="M160.508 64.1567C161.745 64.1567 162.749 63.1526 162.749 61.9139C162.749 60.6753 161.745 59.6711 160.508 59.6711C159.27 59.6711 158.267 60.6753 158.267 61.9139C158.267 63.1526 159.27 64.1567 160.508 64.1567Z"
        fill="#F27B53"
      />
      <path
        opacity="0.45"
        d="M144.362 62.9316C145.406 62.9316 146.253 62.0846 146.253 61.0399C146.253 59.9951 145.406 59.1482 144.362 59.1482C143.318 59.1482 142.472 59.9951 142.472 61.0399C142.472 62.0846 143.318 62.9316 144.362 62.9316Z"
        fill="#F27B53"
      />
      <path
        d="M145.308 61.792C142.573 61.792 140.353 59.5636 140.353 56.8335C140.353 54.0963 142.58 51.875 145.308 51.875C148.043 51.875 150.262 54.1035 150.262 56.8335C150.262 59.5707 148.043 61.792 145.308 61.792ZM145.308 52.4411C142.888 52.4411 140.919 54.4116 140.919 56.8335C140.919 59.2555 142.888 61.226 145.308 61.226C147.728 61.226 149.696 59.2555 149.696 56.8335C149.689 54.4116 147.728 52.4411 145.308 52.4411Z"
        fill="#161119"
      />
      <path
        d="M157.78 61.792C155.045 61.792 152.825 59.5636 152.825 56.8335C152.825 54.0963 155.052 51.875 157.78 51.875C160.515 51.875 162.734 54.1035 162.734 56.8335C162.734 59.5707 160.515 61.792 157.78 61.792ZM157.78 52.4411C155.36 52.4411 153.391 54.4116 153.391 56.8335C153.391 59.2555 155.36 61.226 157.78 61.226C160.2 61.226 162.169 59.2555 162.169 56.8335C162.169 54.4116 160.2 52.4411 157.78 52.4411Z"
        fill="#161119"
      />
      <path
        d="M152.798 72.647C153.746 72.5553 154.457 71.8807 154.386 71.1401C154.314 70.3995 153.487 69.8735 152.539 69.9651C151.59 70.0568 150.879 70.7315 150.951 71.472C151.022 72.2126 151.849 72.7386 152.798 72.647Z"
        fill="#161119"
      />
      <path
        d="M155.782 71.573C155.023 71.6446 154.243 71.6733 153.448 71.6375L153.477 70.8923C162.462 71.2505 169.379 63.9847 169.443 63.9131L169.987 64.4218C169.93 64.4935 163.944 70.7848 155.782 71.573Z"
        fill="#161119"
      />
      <path
        d="M140.231 51.0009C140.947 45.9564 143.589 40.4103 147.913 37.5584C151.787 35.0075 156.97 34.8427 161.238 36.4836C164.918 37.8952 167.352 41.0409 168.548 44.7096C169.572 47.8337 169.708 51.0439 169.944 54.2899C170.023 55.3575 171.698 55.3718 171.619 54.2899C171.39 51.2015 171.268 48.1204 170.424 45.118C169.321 41.2057 167.073 37.6158 163.421 35.6524C159.075 33.3165 153.319 33.0657 148.837 35.1293C145.186 36.8061 142.522 40.0664 140.825 43.6348C139.801 45.7987 138.949 48.1705 138.613 50.5566C138.463 51.61 140.074 52.0614 140.231 51.0009Z"
        fill="#00B7D8"
      />
      <path
        d="M169.872 66.808C173.044 66.808 175.615 63.6095 175.615 59.664C175.615 55.7185 173.044 52.52 169.872 52.52C166.701 52.52 164.13 55.7185 164.13 59.664C164.13 63.6095 166.701 66.808 169.872 66.808Z"
        fill="#00B7D8"
      />
      <path
        d="M170.775 66.4496C173.788 66.4496 176.23 63.4116 176.23 59.6639C176.23 55.9162 173.788 52.8782 170.775 52.8782C167.761 52.8782 165.319 55.9162 165.319 59.6639C165.319 63.4116 167.761 66.4496 170.775 66.4496Z"
        fill="#04A2BC"
      />
      <path
        d="M162.641 82.6868C170.159 83.0092 178.299 85.2735 183.798 90.4112C184.263 90.8483 184.722 91.3212 184.922 91.9231C185.216 92.8045 184.915 93.7646 184.636 94.6532C181.385 104.907 181.206 116.114 184.142 126.461C184.908 129.169 185.889 131.849 186.154 134.651C186.418 137.453 185.86 140.491 183.927 142.533C183.096 143.414 182.051 144.066 180.97 144.611C174.949 147.656 167.882 147.542 161.145 147.262C156.992 147.09 152.796 146.875 148.794 145.736C144.792 144.597 140.954 142.447 138.52 139.072C136.014 135.59 135.226 131.161 134.911 126.884C134.188 117.16 135.506 107.408 135.692 97.6555C135.742 94.818 135.685 91.8944 134.582 89.2862C139.716 85.7321 145.851 83.5753 151.973 82.3572C153.29 84.1127 155.13 84.9081 157.321 85.0156C159.505 85.1231 161.159 84.2919 162.641 82.6868Z"
        fill="#FFCE45"
      />
      <path
        d="M182.975 133.426C182.731 130.624 184.722 127.378 184.013 124.669C182.208 117.783 178.815 111.091 179.545 103.983C177.999 104.757 176.602 105.781 175.808 107.307C175.156 108.561 174.984 110.023 174.97 111.442C174.941 114.287 175.464 117.103 175.579 119.94C175.693 122.778 175.335 125.773 173.739 128.095C171.891 130.774 168.712 132.129 165.612 132.96C163.529 133.519 161.395 133.906 159.269 134.243C156.82 134.629 154.328 134.952 151.866 134.637C149.403 134.321 146.954 133.297 145.336 131.369C142.773 128.324 142.851 123.896 142.386 119.897C141.878 115.512 140.56 111.22 138.513 107.336C137.897 106.168 137.181 104.993 136.071 104.312C136.071 104.312 136.064 104.312 136.064 104.305C135.685 111.428 131.568 118.901 132.055 126.002C132.348 130.287 136.673 134.35 138.992 137.832C141.233 141.207 144.785 143.357 148.479 144.496C152.173 145.636 156.047 145.851 159.884 146.023C166.106 146.302 172.636 146.417 178.192 143.371C179.187 142.827 180.154 142.175 180.92 141.293C182.702 139.258 183.218 136.227 182.975 133.426Z"
        fill="#FFA748"
      />
      <path
        d="M137.711 87.3442C140.031 97.6769 138.033 109.873 135.455 120.141C134.689 123.193 134.854 126.539 135.942 128.087C137.074 129.692 139.028 130.652 140.811 131.477C144.956 133.39 147.835 135.647 150.999 138.936C152.267 140.254 153.434 141.745 153.978 143.486C154.529 145.234 154.364 147.284 153.197 148.695C152.08 150.042 150.24 150.601 148.493 150.601C146.746 150.601 145.035 150.114 143.338 149.713C140.489 149.046 137.582 148.609 134.661 148.409C132.133 148.237 129.563 148.237 127.129 147.527C123.671 146.51 120.699 144.045 118.874 140.935C117.055 137.818 116.353 134.099 116.718 130.509C116.912 128.582 117.399 126.704 117.9 124.834C119.976 117.095 122.84 110.324 125.461 102.75C126.534 99.6546 128.503 96.1578 130.401 93.4779C132.298 90.798 134.482 87.896 137.711 87.3442Z"
        fill="#FFCE45"
      />
      <path
        d="M138.555 107.021C139.959 100.636 139.458 93.5713 137.961 87.2728C137.882 86.9432 137.381 87.0865 137.46 87.4089C138.527 91.9017 138.942 96.5234 138.713 101.138C138.62 103.051 138.462 105.007 138.054 106.878C137.976 107.214 138.484 107.35 138.555 107.021Z"
        fill="white"
      />
      <path
        d="M157.579 143.565C157.579 143.565 150.67 141.845 148.078 143.328C145.493 144.812 141.563 146.976 141.684 149.727V150.859C141.684 150.859 160.214 151.404 161.717 149.727"
        fill="#E8A17E"
      />
      <path
        d="M178.228 86.5706C181.622 86.9647 185.33 90.92 187.672 94.338C190.02 97.7559 191.617 101.625 193.192 105.459C196.149 112.639 199.106 119.818 202.056 126.998C202.972 129.227 203.896 131.47 204.339 133.834C204.712 135.797 204.74 137.825 204.44 139.796C204.189 141.415 203.695 143.035 202.686 144.324C201.254 146.144 198.998 147.069 196.836 147.893C192.275 149.627 187.593 151.253 182.731 151.59C180.813 151.719 178.887 151.655 176.968 151.583C171.662 151.39 166.328 151.196 161.109 150.222C160.737 150.15 160.343 150.071 160.042 149.835C159.727 149.591 159.555 149.218 159.391 148.86C158.868 147.714 158.338 146.56 157.816 145.414C157.522 144.769 157.221 144.095 157.279 143.393C157.357 142.447 158.045 141.68 158.739 141.043C160.579 139.337 162.698 137.933 164.99 136.908C169.694 134.794 175.07 134.264 179.495 131.606C180.032 131.283 180.555 130.925 180.984 130.474C182.617 128.739 182.517 125.952 181.6 123.759C180.684 121.567 180.046 119.467 179.152 117.268C177.154 112.366 176.159 109.171 176.051 104.986C175.88 99.0099 176.331 92.2241 178.228 86.5706Z"
        fill="#FFCE45"
      />
      <path
        d="M160.3 139.846C160.923 140.684 161.395 141.659 161.825 142.612C162.92 145.077 163.543 147.742 163.736 150.437C163.758 150.773 164.281 150.773 164.259 150.437C164.066 147.728 163.45 145.062 162.369 142.562C161.925 141.537 161.417 140.484 160.751 139.581C160.55 139.316 160.099 139.574 160.3 139.846Z"
        fill="#FFA748"
      />
      <path
        d="M176.066 107.307C175.629 100.479 176.159 93.0982 178.486 86.6421C178.6 86.3269 178.092 86.1907 177.984 86.506C175.643 93.0051 175.113 100.443 175.55 107.315C175.564 107.637 176.087 107.644 176.066 107.307Z"
        fill="white"
      />
      <path
        d="M252.023 157H9.27975C8.08408 157 7.11035 156.025 7.11035 154.829V154.65C7.11035 153.453 8.08408 152.479 9.27975 152.479H252.023C253.219 152.479 254.193 153.453 254.193 154.65V154.829C254.193 156.025 253.219 157 252.023 157Z"
        fill="#345D95"
      />
      <path
        d="M189.354 152.486H105.793C105.592 152.486 105.435 152.328 105.435 152.127V150.745C105.435 150.544 105.592 150.386 105.793 150.386H189.354C189.554 150.386 189.712 150.544 189.712 150.745V152.127C189.712 152.321 189.554 152.486 189.354 152.486Z"
        fill="#26476D"
      />
      <path d="M142.415 152.486H70.5096L67.0371 102.521H137.503L142.415 152.486Z" fill="#26476D" />
      <path d="M139.973 152.486H68.068L64.5884 102.521H135.054L139.973 152.486Z" fill="#C5E1F9" />
      <path
        opacity="0.27"
        d="M102.285 131.799C104.657 131.799 106.58 129.874 106.58 127.5C106.58 125.125 104.657 123.2 102.285 123.2C99.9121 123.2 97.9888 125.125 97.9888 127.5C97.9888 129.874 99.9121 131.799 102.285 131.799Z"
        fill="#26476D"
      />
      <path opacity="0.07" d="M65.1968 111.155L68.0678 152.443H139.973L139.014 142.683C101.167 137.789 75.0987 119.309 65.1968 111.155Z" fill="#26476D" />
      <path
        d="M80.175 57.5645C80.8037 57.5645 81.3134 57.0544 81.3134 56.4252C81.3134 55.796 80.8037 55.2859 80.175 55.2859C79.5463 55.2859 79.0366 55.796 79.0366 56.4252C79.0366 57.0544 79.5463 57.5645 80.175 57.5645Z"
        fill="#3E3244"
      />
      <path
        d="M93.7214 57.5645C94.3501 57.5645 94.8598 57.0544 94.8598 56.4252C94.8598 55.796 94.3501 55.2859 93.7214 55.2859C93.0927 55.2859 92.583 55.796 92.583 56.4252C92.583 57.0544 93.0927 57.5645 93.7214 57.5645Z"
        fill="#3E3244"
      />
      <path
        d="M69.7005 52.5201C69.7005 52.5201 87.385 53.5806 96.6783 51.5169L99.2702 50.5066C99.2702 50.5066 96.4564 31.3532 80.1752 35.8961C68.7984 38.533 69.7005 52.5201 69.7005 52.5201Z"
        fill="#161119"
      />
    </svg>
  );
}

export default DoubleListeningIllustration;
