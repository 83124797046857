import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import ownerContext from '../contexts/ownerContext';
import useMeetingsByOwnerQuery from '../hooks/queries/useMeetingsByOwnerQuery';
import Meeting from '../ui/organisms/Meeting';
import CenteredCircularProgress from '../ui/atoms/CenteredCircularProgress';

function NextMeetings({ sx }) {
  const owner = useContext(ownerContext);
  const { data: meetings, isLoading } = useMeetingsByOwnerQuery(owner.userId, {
    select: (items) => items.filter((meeting) => null === meeting.date || new Date(meeting.date) >= new Date()),
  });

  if (!isLoading && 0 === meetings.length) {
    return null;
  }

  return (
    <Box sx={sx}>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Mes coachings à venir
      </Typography>
      <Box sx={{ display: 'flex', position: 'relative', minHeight: 242 }}>
        {isLoading ? <CenteredCircularProgress /> : meetings?.map((meeting) => <Meeting key={meeting.id} meeting={meeting} sx={{ mr: 3 }} />)}
      </Box>
    </Box>
  );
}

NextMeetings.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

NextMeetings.defaultProps = {
  sx: undefined,
};

export default NextMeetings;
