import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

/**
 * Component used to translate validation errors coming from formik and yup
 *
 * @param {string|{key: string, values: object}|null|boolean} error
 */
function FormatErrorMessage({ error }) {
  if (!error) {
    return null;
  }
  if ('string' === typeof error) {
    return error;
  }

  return <FormattedMessage id={error.key} values={error.values} />;
}

FormatErrorMessage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
};

FormatErrorMessage.defaultProps = {
  error: null,
};

export default FormatErrorMessage;
