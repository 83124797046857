import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-hot-toast';
import AdminLayout from '../ui/templates/AdminLayout';
import TrashIcon from '../ui/atoms/icons/TrashIcon';
import SkillDialog from '../features/SkillDialog';
import EditIcon from '../ui/atoms/icons/EditIcon';
import ConfirmDialog from '../ui/organisms/ConfirmDialog';
import useTranslate from '../hooks/useTranslate';
import CenteredCircularProgress from '../ui/atoms/CenteredCircularProgress';
import useDeleteSkillMutation from '../hooks/mutations/useDeleteSkillMutation';
import useSkillsQuery from '../hooks/queries/useSkillsQuery';
import ADMIN_PAGE from '../constants/AdminPage';

function Skills() {
  const { t } = useTranslate();

  // Can contain a skill ID
  const [open, setOpen] = useState(false);

  const [deleteId, setDeleteId] = useState(null);

  const { data, isLoading } = useSkillsQuery();
  const { mutate, isLoading: deleting } = useDeleteSkillMutation({
    onSuccess: () => toast.success(t('pages.Skills.deleteSuccess')),
    onError: () => toast.error(t('errors.api.default')),
  });

  const deleteItem = data?.find((item) => item.id === deleteId);

  return (
    <AdminLayout selected={ADMIN_PAGE.skills}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
        <Typography variant="h2">
          <FormattedMessage id="pages.Skills.title" />
        </Typography>
        <Button variant="contained" onClick={() => setOpen(true)}>
          <FormattedMessage id="common.verb.add" />
        </Button>
      </Box>
      {isLoading ? (
        <Box sx={{ height: '500px', width: '100%', position: 'relative' }}>
          <CenteredCircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="pages.Skills.columns.name" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="pages.Skills.columns.type" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="common.actions" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell align="left">
                    <FormattedMessage id="constants.SkillType" values={{ value: item.type }} />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton color="primary" onClick={() => setOpen(item.id)}>
                      <EditIcon size={20} />
                    </IconButton>
                    <IconButton color="error" onClick={() => setDeleteId(item.id)}>
                      {deleting ? <CircularProgress color="inherit" size={20} /> : <TrashIcon size={20} />}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {open && <SkillDialog id={true === open ? undefined : open} onClose={() => setOpen(false)} />}
      {deleteId && (
        <ConfirmDialog
          title={t('pages.Skills.confirmDelete.title', { name: deleteItem?.name })}
          content={t('pages.Skills.confirmDelete.content')}
          confirmLabel={t('common.verb.delete')}
          onConfirm={() => mutate(deleteId)}
          onExited={() => setDeleteId(null)}
        />
      )}
    </AdminLayout>
  );
}

export default Skills;
