const QUERY_CACHE_KEY = {
  skills: () => 'skills',
  skill: (id) => ['skill', id],
  userSkills: (owner) => ['userSkills', owner],
  userImprovementAxes: (owner) => ['userImprovementAxes', owner],
  improvementAxis: (id) => ['improvementAxes', id],
  users: () => 'users',
  user: (id) => ['users', id],
  coaches: () => 'coaches',
  coach: (id) => ['coaches', id],
  meetings: () => 'meetings',
  meeting: (id) => ['meetings', id],
  userMeetings: (owner) => ['userMeetings', owner],
  userActionPlans: (owner) => ['userActionPlans', owner],
};

export default QUERY_CACHE_KEY;
