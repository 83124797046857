import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { toast } from 'react-hot-toast';
import { object, ref, string } from '../utils/yup';
import TextField from '../ui/atoms/form/TextField';
import useTranslate from '../hooks/useTranslate';

const validationSchema = object().shape({
  password: string().min(8).required(),
  passwordConfirmation: string()
    .oneOf([ref('password'), null], { key: 'errors.validation.notSamePassword' })
    .min(8)
    .required(),
});

function ChangePasswordDialog({ user }) {
  const { t } = useTranslate();

  const [loading, setLoading] = useState(false);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await Auth.completeNewPassword(user, values.password);
      } catch {
        toast.error(t('errors.api.default'));
      }
    },
  });

  return (
    <Dialog maxWidth="sm" open fullWidth>
      <form onSubmit={form.handleSubmit}>
        <DialogTitle>
          <FormattedMessage id="features.ChangePasswordDialog.title" />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ position: 'relative', minHeight: '300px' }}>
            <TextField
              error={form.touched.password && form.errors.password}
              id="password"
              name="password"
              type="password"
              label={t('features.ChangePasswordDialog.fields.password')}
              fullWidth
              variant="outlined"
              value={form.values.password}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              sx={{ mb: 3, mt: 1 }}
            />
            <TextField
              error={form.touched.passwordConfirmation && form.errors.passwordConfirmation}
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              label={t('features.ChangePasswordDialog.fields.passwordConfirmation')}
              fullWidth
              variant="outlined"
              value={form.values.passwordConfirmation}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              sx={{ mt: 1 }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={loading}>
            <FormattedMessage id="common.verb.save" />
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

ChangePasswordDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
};

export default ChangePasswordDialog;
