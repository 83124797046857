import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';

function useUserSkillsByOwnerQuery(owner, options = {}) {
  return useQuery(
    QUERY_CACHE_KEY.userSkills(owner),
    async () => {
      const res = await API.graphql({
        // TODO Find better solutions
        query: gql`
          query ListUserSkillsByOwner($owner: String!, $sortDirection: ModelSortDirection, $filter: ModelUserSkillFilterInput, $limit: Int, $nextToken: String) {
            listUserSkillsByOwner(owner: $owner, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                id
                skill {
                  id
                  name
                  type
                  icon
                }
                owner
                level
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        `,
        variables: {
          owner,
        },
      });
      return res.data.listUserSkillsByOwner.items.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
    },
    { enabled: !!owner, ...options }
  );
}

export default useUserSkillsByOwnerQuery;
