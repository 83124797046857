/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { object, mixed } from '../utils/yup';
import CenteredCircularProgress from '../ui/atoms/CenteredCircularProgress';
import MEETING_STATUS from '../constants/MeetingStatus';
import useUpdateMeetingMutation from '../hooks/mutations/useUpdateMeetingMutation';
import useMeetingQuery from '../hooks/queries/useMeetingQuery';

const validationSchema = object().shape({
  date: mixed().required(),
});

function PlanMeetingDialog({ id, onClose }) {
  // Store a local open state to play exit animation
  const [open, setOpen] = useState(true);

  const { data: meeting, isLoading } = useMeetingQuery(id);
  const { mutateAsync: update, isLoading: updating } = useUpdateMeetingMutation();

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: meeting?.date || '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (0 < values.date.trim().length) {
        await update({
          id,
          date: values.date,
          status: MEETING_STATUS.planned,
        });
        setOpen(false);
      }
    },
  });

  const addPad = (value) => {
    const added = `0${value}`;
    return added.slice(-2);
  };

  const getDefaultLocalDate = (value) => {
    if (value) {
      const m = new Date(value);
      const year = m.getFullYear();
      const month = addPad(m.getMonth() + 1);
      const day = addPad(m.getDate());
      const hours = addPad(m.getHours());
      const minutes = addPad(m.getMinutes());
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    }
    return '';
  };

  const handleChange = (value) => {
    if (value.target.value) {
      const localDate = new Date(value.target.value);
      form.setFieldValue('date', localDate.toISOString());
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} TransitionProps={{ onExited: onClose }} maxWidth="sm" fullWidth>
      <form onSubmit={form.handleSubmit}>
        <DialogTitle>
          <FormattedMessage id="features.PlanMeetingDialog.title" values={{ edit: !!id }} />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ position: 'relative', minHeight: '300px' }}>
            {isLoading ? (
              <CenteredCircularProgress />
            ) : (
              <Box sx={{ mt: 2 }}>
                <TextField
                  id="datetime-local"
                  label="Date du coaching"
                  type="datetime-local"
                  value={getDefaultLocalDate(form.values.date)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="grey" onClick={handleClose}>
            <FormattedMessage id="common.verb.cancel" />
          </Button>
          <LoadingButton type="submit" variant="contained" loading={updating}>
            <FormattedMessage id="common.verb.validate" />
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

PlanMeetingDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PlanMeetingDialog;
