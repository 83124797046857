export default {
  SkillType: `{value, select,
    SKILL {Compétence}
    other {Point fort}
  }`,
  SkillLevel: `{value, select, 
    1 {Débutant}
    2 {Intermediaire}
    3 {Avancé}
    other {Expert}
  }`,
  MeetingMode: `{value, select,
    DOUBLE_LISTENING {La double écoute}
    RECORD {L’enregistrement}
    SCENARIO {La mise en situation}
    other {Le training}
  }`,
  MeetingStatus: `{value, select,
    PENDING {En attente de programmation}
    PLANNED {Programmées}
    other {Annulées}
  }`,
  Gender: `{value, select,
    MEN {Homme}
    other {Femme}
  }`,
};
