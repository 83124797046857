import { Box, IconButton, Slider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import TrashIcon from '../../ui/atoms/icons/TrashIcon';
import SKILL_LEVEL from '../../constants/SkillLevel';
import useTranslate from '../../hooks/useTranslate';

function UserSkillItem({ item, onDelete, onChange }) {
  const { t } = useTranslate();
  const marks = Object.values(SKILL_LEVEL).map((level) => ({
    value: level,
    label: t('constants.SkillLevel', { value: level }),
  }));

  return (
    <Box sx={{ py: 2, borderBottom: '1px solid', borderColor: 'divider', '&:last-child': { borderBottom: 'none' } }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle2">{item.skill.name}</Typography>
        <IconButton onClick={onDelete} size="small" sx={{ ml: 1 }}>
          <TrashIcon size={16} />
        </IconButton>
      </Box>
      <Box sx={{ px: 4, py: 1 }}>
        <Slider onChange={onChange} name={item.id} value={item.level} step={1} marks={marks} min={1} max={4} valueLabelDisplay="off" />
      </Box>
    </Box>
  );
}

UserSkillItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    level: PropTypes.number,
    skill: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default UserSkillItem;
