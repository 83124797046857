/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

function UsersIcon({ size, color }) {
  return (
    <SvgIcon size={size} color={color} viewBox="0 0 24 24">
      <path
        strokeLinecap="round"
        stroke="currentColor"
        fill="none"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
      />
    </SvgIcon>
  );
}

UsersIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

UsersIcon.defaultProps = {
  size: 24,
  color: undefined,
};

export default UsersIcon;
