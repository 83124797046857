/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listUsers = /* GraphQL */ `
  query ListUsers($limit: Int, $nextToken: String) {
    listUsers(limit: $limit, nextToken: $nextToken) {
      items {
        username
        name
        job
        gender
        email
        enabled
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: String!) {
    getUser(id: $id) {
      username
      name
      job
      gender
      email
      enabled
    }
  }
`;
export const getSkill = /* GraphQL */ `
  query GetSkill($id: ID!) {
    getSkill(id: $id) {
      id
      name
      type
      icon
      userSkills {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSkills = /* GraphQL */ `
  query ListSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        icon
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserSkill = /* GraphQL */ `
  query GetUserSkill($id: ID!) {
    getUserSkill(id: $id) {
      id
      skillId
      skill {
        id
        name
        type
        icon
        createdAt
        updatedAt
      }
      owner
      level
      createdAt
      updatedAt
    }
  }
`;
export const listUserSkills = /* GraphQL */ `
  query ListUserSkills(
    $filter: ModelUserSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        skillId
        owner
        level
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCoach = /* GraphQL */ `
  query GetCoach($id: ID!) {
    getCoach(id: $id) {
      id
      name
      meetings {
        nextToken
      }
      disabled
      createdAt
      updatedAt
    }
  }
`;
export const listCoaches = /* GraphQL */ `
  query ListCoaches(
    $filter: ModelCoachFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoaches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        disabled
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeeting = /* GraphQL */ `
  query GetMeeting($id: ID!) {
    getMeeting(id: $id) {
      id
      type
      mode
      status
      date
      subject
      message
      coachId
      coach {
        id
        name
        disabled
        createdAt
        updatedAt
      }
      improvementAxisId
      improvementAxis {
        id
        title
        userWhoSuggestId
        owner
        archivedAt
        createdAt
        updatedAt
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listMeetings = /* GraphQL */ `
  query ListMeetings(
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        mode
        status
        date
        subject
        message
        coachId
        improvementAxisId
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getActionPlan = /* GraphQL */ `
  query GetActionPlan($id: ID!) {
    getActionPlan(id: $id) {
      id
      title
      active
      improvementAxisId
      improvementAxis {
        id
        title
        userWhoSuggestId
        owner
        archivedAt
        createdAt
        updatedAt
      }
      owner
      finishedAt
      createdAt
      updatedAt
    }
  }
`;
export const listActionPlans = /* GraphQL */ `
  query ListActionPlans(
    $filter: ModelActionPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActionPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        active
        improvementAxisId
        owner
        finishedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getImprovementAxis = /* GraphQL */ `
  query GetImprovementAxis($id: ID!) {
    getImprovementAxis(id: $id) {
      id
      title
      meetings {
        nextToken
      }
      userWhoSuggestId
      userWhoSuggest {
        username
        name
        job
        gender
        email
        enabled
      }
      owner
      archivedAt
      createdAt
      updatedAt
    }
  }
`;
export const listImprovementAxes = /* GraphQL */ `
  query ListImprovementAxes(
    $filter: ModelImprovementAxisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImprovementAxes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        userWhoSuggestId
        owner
        archivedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUserSkillsByOwner = /* GraphQL */ `
  query ListUserSkillsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSkillsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        skillId
        owner
        level
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMeetingsByOwner = /* GraphQL */ `
  query ListMeetingsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        mode
        status
        date
        subject
        message
        coachId
        improvementAxisId
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listActionPlansByOwner = /* GraphQL */ `
  query ListActionPlansByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelActionPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActionPlansByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        active
        improvementAxisId
        owner
        finishedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listImprovementAxesByOwner = /* GraphQL */ `
  query ListImprovementAxesByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelImprovementAxisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImprovementAxesByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        userWhoSuggestId
        owner
        archivedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
