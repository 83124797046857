import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';

function useImprovementAxesQueryByOwner(owner, options = {}) {
  return useQuery(
    QUERY_CACHE_KEY.userImprovementAxes(owner),
    async () => {
      const res = await API.graphql({
        // TODO Find better solutions
        query: gql`
          query ListImprovementAxesByOwner($owner: String!, $sortDirection: ModelSortDirection, $filter: ModelImprovementAxisFilterInput, $limit: Int, $nextToken: String) {
            listImprovementAxesByOwner(owner: $owner, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                id
                title
                userWhoSuggest {
                  username
                  name
                }
                owner
                createdAt
                updatedAt
                archivedAt
              }
              nextToken
            }
          }
        `,
        variables: { owner },
      });
      return res.data.listImprovementAxesByOwner.items.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
    },
    {
      enabled: !!owner,
      ...options,
    }
  );
}

export default useImprovementAxesQueryByOwner;
