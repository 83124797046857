import MuiSvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';

function SvgIcon({ viewBox, size, color, children, className }) {
  return (
    <MuiSvgIcon viewBox={viewBox} sx={{ color, height: size, width: size }} className={className}>
      {children}
    </MuiSvgIcon>
  );
}

SvgIcon.propTypes = {
  viewBox: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

SvgIcon.defaultProps = {
  color: 'inherit',
  children: '',
  size: 20,
  viewBox: '0 0 24 24',
  className: '',
};

export default SvgIcon;
