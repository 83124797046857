import flatten from 'flat';
import common from './common.json';
import errors from './errors.json';
import constants from './constants';
import pages from './pages';
import features from './features';
import ui from './ui';

export default flatten({
  common,
  errors,
  constants,
  features,
  pages,
  ui,
});
