/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

function ClockIcon({ size, color }) {
  return (
    <SvgIcon size={size} color={color} viewBox="0 0 24 24">
      <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
      />
    </SvgIcon>
  );
}

ClockIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

ClockIcon.defaultProps = {
  size: 24,
  color: undefined,
};

export default ClockIcon;
