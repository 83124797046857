import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';
import { getSkill } from '../../graphql/queries';

function useSkillQuery(id, options = {}) {
  return useQuery(
    QUERY_CACHE_KEY.skill(id),
    async () => {
      const res = await API.graphql({ query: getSkill, variables: { id } });
      return res.data.getSkill;
    },
    {
      enabled: !!id,
      ...options,
    }
  );
}

export default useSkillQuery;
