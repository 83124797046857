/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

function ClockIcon({ size, color }) {
  return (
    <SvgIcon size={size} color={color} viewBox="0 0 24 24">
      <path strokeLinecap="round" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
    </SvgIcon>
  );
}

ClockIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

ClockIcon.defaultProps = {
  size: 24,
  color: undefined,
};

export default ClockIcon;
