import { Box, Paper, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { FormattedMessage } from 'react-intl';
import EditIcon from '../../ui/atoms/icons/EditIcon';
import TextField from '../../ui/atoms/form/TextField';
import useCreateImprovementAxisMutation from '../../hooks/mutations/useCreateImprovementAxisMutation';
import ownerContext from '../../contexts/ownerContext';
import useTranslate from '../../hooks/useTranslate';
import useCurrentUser from '../../hooks/useCurrentUser';

function AddImprovementAxis() {
  const { t } = useTranslate();
  const user = useCurrentUser();
  const owner = useContext(ownerContext);
  const { mutateAsync, isLoading } = useCreateImprovementAxisMutation(owner.userId);

  const [isEditMode, setEditMode] = useState(false);
  const [title, setTitle] = useState('');

  const handleSubmit = async () => {
    if (0 >= title.trim().length) {
      return;
    }

    await mutateAsync({
      title,
      userWhoSuggestId: owner.isMe ? undefined : user.username,
    });

    setTitle('');
    setEditMode(false);
  };

  return (
    <Paper sx={{ minHeight: 77, display: 'flex', px: 4, alignItems: 'center', color: 'primary.main' }}>
      {isEditMode ? (
        <Box sx={{ display: 'inline-flex', height: '100%', width: '100%', alignItems: 'center', cursor: 'pointer' }} onClick={() => setEditMode(true)}>
          <TextField
            value={title}
            id="title"
            name="title"
            label={t('features.ImprovementAxes.AddImprovementAxis.label')}
            fullWidth
            size="small"
            variant="outlined"
            onChange={(e) => setTitle(e.target.value)}
            multiline
            sx={{ mr: 2 }}
          />
          <LoadingButton loading={isLoading} variant="contained" sx={{ height: '40px' }} onClick={handleSubmit}>
            <FormattedMessage id="common.verb.add" />
          </LoadingButton>
        </Box>
      ) : (
        <Box sx={{ display: 'inline-flex', py: 2, alignItems: 'center', cursor: 'pointer' }} onClick={() => setEditMode(true)}>
          <EditIcon />
          <Typography variant="subtitle2" sx={{ ml: 2 }}>
            <FormattedMessage id="features.ImprovementAxes.AddImprovementAxis.add" />
          </Typography>
        </Box>
      )}
    </Paper>
  );
}

export default AddImprovementAxis;
