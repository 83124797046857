import Login from './Login.json';
import Skills from './Skills.json';
import Meetings from './Meetings.json';
import Coaches from './Coaches.json';
import Users from './Users.json';

export default {
  Login,
  Skills,
  Meetings,
  Coaches,
  Users,
};
