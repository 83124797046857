/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

function SwitchIcon({ size, color }) {
  return (
    <SvgIcon size={size} color={color} viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" fill="none" stroke="currentColor" strokeWidth="2" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
    </SvgIcon>
  );
}

SwitchIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

SwitchIcon.defaultProps = {
  size: 24,
  color: undefined,
};

export default SwitchIcon;
