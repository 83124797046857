import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { IntlProvider } from 'react-intl';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import { useEffect, useState } from 'react';
import theme from './theme';
import translations from './translations';
import awsconfig from './aws-exports';
import userContext from './contexts/userContext';
import CenteredCircularProgress from './ui/atoms/CenteredCircularProgress';
import Routes from './Routes';

Amplify.configure(awsconfig);

const queryClient = new QueryClient();

function transformUser(data) {
  return {
    username: data.username,
    name: data.attributes.name,
    email: data.attributes.email,
    job: data.attributes['custom:job'],
    groups: data.signInUserSession?.accessToken?.payload['cognito:groups'] ?? [],
  };
}

function App() {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((data) => setUser(transformUser(data)))
      .catch((err) => {
        if ('string' === typeof err && err.includes('authenticated')) {
          setUser(null);
        }
      });
  }, []);

  useEffect(() => {
    const listener = (data) => {
      // eslint-disable-next-line default-case
      switch (data.payload.event) {
        case 'signIn':
          if (!data.payload?.data?.attributes) {
            Auth.currentAuthenticatedUser().then((session) => setUser(transformUser(session)));
          } else {
            setUser(transformUser(data.payload.data));
          }
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
          setUser(null);
          break;
        case 'tokenRefresh':
          setUser(transformUser(data.payload.data));
          break;
        case 'tokenRefresh_failure':
          setUser(null);
          break;
      }
    };

    Hub.listen('auth', listener);
    return () => Hub.remove('auth', listener);
  }, []);

  if (user === undefined) {
    return <CenteredCircularProgress />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider messages={translations.fr} locale="fr" defaultLocale="fr">
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <userContext.Provider value={user}>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </userContext.Provider>
          </LocalizationProvider>
        </ThemeProvider>
      </IntlProvider>
      <Toaster position="top-right" />
    </QueryClientProvider>
  );
}

export default App;
