import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import PropTypes from 'prop-types';
import SKILL_TYPE from '../constants/SkillType';
import { object, string } from '../utils/yup';
import TextField from '../ui/atoms/form/TextField';
import useTranslate from '../hooks/useTranslate';
import useSkillQuery from '../hooks/queries/useSkillQuery';
import useUpdateSkillMutation from '../hooks/mutations/useUpdateSkillMutation';
import useCreateSkillMutation from '../hooks/mutations/useCreateSkillMutation';
import CenteredCircularProgress from '../ui/atoms/CenteredCircularProgress';

const validationSchema = object().shape({
  name: string().required(),
  type: string().oneOf(Object.values(SKILL_TYPE)).required(),
  icon: string().nullable(),
});

function SkillDialog({ id, onClose }) {
  const { t } = useTranslate();

  // Store a local open state to play exit animation
  const [open, setOpen] = useState(true);

  const { data: skill, isLoading } = useSkillQuery(id);
  const { mutateAsync: create, isLoading: creating } = useCreateSkillMutation();
  const { mutateAsync: update, isLoading: updating } = useUpdateSkillMutation(id);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: skill?.name || '',
      icon: skill?.icon || '',
      type: skill?.type || SKILL_TYPE.skill,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (id) {
        await update(values);
      } else {
        await create(values);
      }

      setOpen(false);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} TransitionProps={{ onExited: onClose }} maxWidth="sm" fullWidth>
      <form onSubmit={form.handleSubmit}>
        <DialogTitle>
          <FormattedMessage id="features.SkillDialog.title" values={{ edit: !!id }} />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ position: 'relative', minHeight: '300px' }}>
            {isLoading ? (
              <CenteredCircularProgress />
            ) : (
              <>
                <TextField
                  error={form.touched.name && form.errors.name}
                  id="name"
                  name="name"
                  label={t('features.SkillDialog.fields.name')}
                  fullWidth
                  variant="outlined"
                  value={form.values.name}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  sx={{ mb: 3, mt: 1 }}
                />
                <FormControl component="fieldset" sx={{ mb: 2 }}>
                  <FormLabel component="legend">
                    <FormattedMessage id="features.SkillDialog.fields.type" />
                  </FormLabel>
                  <RadioGroup onChange={form.handleChange} value={form.values.type} name="type">
                    <FormControlLabel value={SKILL_TYPE.skill} control={<Radio />} label={t('constants.SkillType', { value: SKILL_TYPE.skill })} />
                    <FormControlLabel value={SKILL_TYPE.strongPoint} control={<Radio />} label={t('constants.SkillType', { value: SKILL_TYPE.strongPoint })} />
                  </RadioGroup>
                </FormControl>
                <TextField
                  value={form.values.icon}
                  margin="dense"
                  id="icon"
                  name="icon"
                  label={t('features.SkillDialog.fields.icon')}
                  fullWidth
                  variant="outlined"
                  onChange={form.handleChange}
                  multiline
                  minRows={3}
                />
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="grey" onClick={handleClose}>
            <FormattedMessage id="common.verb.cancel" />
          </Button>
          <LoadingButton type="submit" variant="contained" loading={creating || updating}>
            <FormattedMessage id="common.verb.validate" />
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

SkillDialog.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

SkillDialog.defaultProps = {
  id: undefined,
};

export default SkillDialog;
