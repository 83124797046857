import { alpha, createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: ['Inter', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: 46,
      fontWeight: 700,
    },
    h2: {
      fontSize: 28,
      fontWeight: 600,
    },
    h3: {
      fontSize: 22,
      fontWeight: 600,
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 600,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
    },
    button: {
      fontSize: 14,
      textTransform: 'none',
    },
    overline: {
      fontSize: 14,
    },
  },
  palette: {
    primary: {
      light: '#0CC3E7',
      main: '#00B7D8',
      dark: '#082845',
    },
    grey: {
      main: '#83919E',
    },
    danger: {
      main: '#dc3545',
    },
    purple: {
      main: '#5E81F4',
    },
    white: {
      main: '#ffffff',
    },
    success: {
      main: '#33CB5E',
    },
    text: {
      primary: '#011F3B',
      secondary: '#83919E',
    },
    background: {
      default: '#F5F5FB',
    },
  },
  shape: {
    borderRadius: 6,
  },
  components: {
    MuiChip: {
      styleOverrides: {
        label: {
          fontWeight: 500,
        },
        colorPrimary: {
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#00B7D8',
          },
        },
        outlinedPrimary: {
          color: '#00B7D8',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 8,
          borderRadius: 4,
        },
        bar: {
          borderRadius: '0 4px 4px 0',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        containedPrimary: {
          color: '#ffffff',
        },
        outlinedPrimary: {
          borderColor: '#0CC3E7',
          background: alpha('#0CC3E7', 0.1),
        },
      },
    },
  },
});

export default theme;
