import { useMutation, useQueryClient } from 'react-query';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import { createActionPlan, updateActionPlan } from '../../graphql/mutations';
import QUERY_CACHE_KEY from '../../constants/QueryCacheKey';

function useCreateActionPlanMutation(owner, options = {}) {
  const client = useQueryClient();

  return useMutation(
    async (input) => {
      const res = await API.graphql({
        // TODO Find better solution
        query: gql`
          query ListActionPlansByOwner($owner: String!, $sortDirection: ModelSortDirection, $filter: ModelActionPlanFilterInput, $limit: Int, $nextToken: String) {
            listActionPlansByOwner(owner: $owner, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                id
                title
                active
                improvementAxisId
                owner
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        `,
        variables: { owner },
      });

      const activePlans = res.data.listActionPlansByOwner.items.filter((item) => item.active);
      await Promise.all(
        activePlans.map((actionPlan) =>
          API.graphql({
            query: updateActionPlan,
            variables: {
              input: {
                id: actionPlan.id,
                active: false,
                finishedAt: new Date().toISOString(),
              },
            },
          })
        )
      );

      return API.graphql({ query: createActionPlan, variables: { input } });
    },
    {
      ...options,
      onSuccess: (res) => {
        client.invalidateQueries(QUERY_CACHE_KEY.userActionPlans(owner));
        if ('function' === typeof options.onSuccess) {
          options.onSuccess(res);
        }
      },
    }
  );
}

export default useCreateActionPlanMutation;
